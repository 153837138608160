import ApiService from "@/services/api.service";
import {AuthService} from "@/services/auth.service";

/* eslint-disable @typescript-eslint/camelcase */
export const GoogleAuthService =  {
    authRequest: function() {
        AuthService.setAuthProvider('google');
        let href = 'https://accounts.google.com/o/oauth2/v2/auth'
        href += '?client_id=' + process.env.VUE_APP_GOOGLE_ID
        href += '&redirect_uri=' + process.env.VUE_APP_GOOGLE_REDIRECT
        href += '&scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email'
        href += '&response_type=code'
        href += '&state=same_state'
        window.open(href, '_system', 'location=yes');
    },
    getToken: function (code: any) {
        return ApiService.post('googleAuth',
            {
                client_id: process.env.VUE_APP_GOOGLE_ID,
                client_secret: process.env.VUE_APP_GOOGLE_SECRET,
                redirect_uri: process.env.VUE_APP_GOOGLE_REDIRECT,
                code: code
            })
    }
}

export default { GoogleAuthService }