import moment from 'moment'

export default {
	striptags(value: string) {
		return value.replace(/(<([^>]+)>)/gi, "")
	},
	dateFormat(value: string, format: any = null) {
		return moment(String(value)).format(format ? format : 'DD.MM.YYYY HH:mm')
	}
};
