// @ts-nocheck
export default {
    data() {
        return {
            token: null,
            cardNumber: null,
            cardExpiry: null,
            cardCvc: null,
            $stripe: null
        }
    },
    methods: {
      initCard() {
          const style = {
              base: {
                  fontSmoothing: 'antialiased',
                  fontSize: '20px',
                  '::placeholder': {
                      color: '#aab7c4',
                      fontSize: '18px'
                  },
              },
              invalid: {
                  color: '#fa755a',
                  iconColor: '#fa755a',
              },
          };

          if(this.cardNumber) {
              this.cardNumber.destroy();
          }
          if(this.cardExpiry) {
              this.cardExpiry.destroy();
          }
          if(this.cardCvc) {
              this.cardCvc.destroy();
          }
          this.$stripe = window.Stripe(process.env.VUE_APP_STRIPE_PKEY);
          this.cardNumber = this.stripeElements.create('cardNumber', { style });
          this.cardNumber.mount('#card-number');
          this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
          this.cardExpiry.mount('#card-expiry');
          this.cardCvc = this.stripeElements.create('cardCvc', { style });
          this.cardCvc.mount('#card-cvc');
      }
    },
    computed: {
        stripeElements () {
            return this.$stripe ? this.$stripe.elements() : null;
        },
    },
    beforeUnmount () {
        this.cardNumber.destroy();
        this.cardExpiry.destroy();
        this.cardCvc.destroy();
    }
}