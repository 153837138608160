import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import {store} from '@/store';
import { IonicVue } from '@ionic/vue';
import mitt from 'mitt';
const emitter = mitt();

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme styles */
import './theme/main.scss';
import ApiService from "@/services/api.service";
import {TokenService} from "@/services/token.service";
import {LanguageService} from "@/services/language.service";
import {AuthService} from "@/services/auth.service";


const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(store)
;

app.config.globalProperties.$emitter = emitter;
app.config.globalProperties.$getBg = (mainImg: any) => {
  if(mainImg && mainImg !== '') {
    return `url(${process.env.VUE_APP_ROOT_STORAGE + mainImg})`;
  }
  //FIXME: Поменять при релизе!
  return 'url(/assets/default.jpg)';
  //return null;
}
app.config.globalProperties.$getSrc = (mainImg: any) => {
  if(mainImg && mainImg !== '') {
    return process.env.VUE_APP_ROOT_STORAGE + mainImg;
  }
  //FIXME: Поменять при релизе!
  return '/assets/default.jpg';
  //return null;
}

app.config.globalProperties.$isAuth = AuthService.isAuth()

app.config.globalProperties.$tr = function (obj: any, path: string) {
  const lang: any = LanguageService.getLang()
  const paths = path.split('.')
  let translateObj = obj[lang];

  for (let i = 0; i < paths.length; ++i) {
    if (translateObj[paths[i]] == undefined) {
      return undefined;
    } else {
      translateObj = translateObj[paths[i]];
    }
  }
  return translateObj;
}

import trCommon from "@/i18n/translates";
app.config.globalProperties.$td = function (path: string) {
  const obj: any = trCommon
  const lang: any = LanguageService.getLang()
  const paths = path.split('.')
  let translateObj: any = obj[lang];

  for (let i = 0; i < paths.length; ++i) {
    if (translateObj[paths[i]] == undefined) {
      return undefined;
    } else {
      translateObj = translateObj[paths[i]];
    }
  }
  return translateObj;
}

// Функция для делея
app.config.globalProperties.$wait = function wait(ms: number) {
  return new Promise( (resolve) => {setTimeout(resolve, ms)});
}

ApiService.init(process.env.VUE_APP_ROOT_API);

if (TokenService.getToken()) {
  ApiService.setHeader();
  ApiService.mountRequestInterceptor();
  ApiService.mount401Interceptor();
}

router.isReady().then(() => {
  app.mount('#app');
});
