const FCM_TOKEN_CHECK = "fcm_token_check";
// Сервис для смены токена fcm
const FcmUpdateService = {
	setWasChange(token: any) {
		return localStorage.setItem(FCM_TOKEN_CHECK, token)
	},
	getWasChange() {
		return localStorage.getItem(FCM_TOKEN_CHECK)
	},
	removeWasChange() {
		return localStorage.removeItem(FCM_TOKEN_CHECK)
	}
};

export { FcmUpdateService };
