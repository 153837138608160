<template>
  <ion-page>
      <toolbar title="Пополнение Summus Кошелька" :backbutton="true"/>
      <ion-content fullpage id="container">
        <template v-if="countries && profile">
          <form id="order" ref="order" @submit.prevent="saveOrder">
            <input type="submit" id="submit" value="Submit" style="display:none"/>
            <ion-list ref="listRef">
              <ion-item lines="none">
                <h1 class="title">Пополнение Summus кошелька <template v-if="isAnother">другого клиента</template></h1>
              </ion-item>
              <template v-if="isAnother">
                <ion-item lines="full">
                  <p slot="start">Email получателя</p>
                  <p slot="end"><b>{{ wallet.email }}</b></p>
                </ion-item>
                <ion-item lines="full">
                  <p slot="start">Имя получателя</p>
                  <p slot="end"><b>{{ wallet.fullName }}</b></p>
                </ion-item>
              </template>
              <ion-item lines="full">
                <p slot="start">Сумма пополнения</p>
                <p slot="end"><b>¥{{total}}</b></p>
              </ion-item>
            </ion-list>
            <ion-list id="address">
              <ion-radio-group v-model="wallet.methodId" v-if="hasPayMethods" @ionChange="changePayMethod">
                <ion-list-header>
                  <ion-text color="shiawasedo">
                    <h4 class="order-header">{{$tr(trCard, 'payMethod')}}</h4>
                  </ion-text>
                </ion-list-header>
                <template v-for="(payMethod, key) in payMethods" :key="key">
                    <ion-card :class="wallet.methodId === payMethod.id ? 'active' : '' ">
                      <ion-card-content>
                        <ion-item lines="none">
                          <ion-icon v-if="payMethod.cardBrand === 'MasterCard'" slot="start" src="/assets/icon/mastercard.svg"></ion-icon>
                          <ion-icon v-else-if="payMethod.cardBrand === 'Visa'" slot="start" src="/assets/icon/visa.svg"></ion-icon>
                          <ion-icon v-else slot="start" :icon="card"></ion-icon>
                          <ion-label class="ion-text-wrap"><b>**** **** **** {{payMethod.cardLastFour}}, {{ payMethod.cardMonth + '/' + payMethod.cardYear.slice(-2) }}</b></ion-label>
                          <ion-radio mode="md" :value="payMethod.id" slot="end" />
                        </ion-item>
                        <ion-item lines="none">
                          <ion-label @click="wallet.methodId = payMethod.id" class="ion-text-wrap">{{payMethod.contactName + ', ' + payMethod.phone + ', ' + payMethod.postalCode + ', ' + payMethod.city + ', ' + payMethod.address}}</ion-label>
                        </ion-item>
                      </ion-card-content>
                    </ion-card>
                  </template>
                <ion-item :lines="hasPayMethods ? 'none' : ''">
                  <ion-label>
                    <ion-text style="white-space: normal">
                      {{ hasPayMethods ? $tr(trCard, 'otherPayAndAddress') : $tr(trCard, 'otherPay') }}
                    </ion-text>
                  </ion-label>
                  <ion-radio mode="md" :value="false" slot="end" />
                </ion-item>
                <ion-item v-if="hasPayMethods" lines="full">
                  <ion-text style="white-space: normal; margin-bottom: 10px; font-size: 16px" v-html="$tr(trCard, 'otherPayText')"></ion-text>
                </ion-item>
              </ion-radio-group>
              <div id="UserAddressesWallet" v-if="!wallet.methodId || !isAuth">
                <user-addresses-wallet title="Адрес плательщика" :isAuth="isAuth" :countries="countries"/>
              </div>
            </ion-list>
          </form>
        </template>
        <div class="spin" v-else>
          <ion-spinner name="circles" />
        </div>
      </ion-content>
      <ion-footer>
        <ion-toolbar>
          <ion-item>
            <ion-label>
              <ion-text v-html="$tr(trCartForm, 'confirmWalletReplenishment')" />
            </ion-label>
            <ion-checkbox
                slot="end"
                v-model="confirmPay">
            </ion-checkbox>
          </ion-item>
        </ion-toolbar>
        <ion-toolbar>
          <ion-button type="submit" :disabled="!confirmPay" expand="block" color="shiawasedo">
            <label for="submit">
              <ion-icon slot="start"></ion-icon> {{$tr(trCartForm, 'process')}}
            </label>
          </ion-button>
        </ion-toolbar>
      </ion-footer>
  </ion-page>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  IonPage,
  IonContent,
  IonSpinner,
  IonRow,
  IonCol,
  IonGrid,
  IonIcon,
  IonText,
  IonLabel,
  IonList,
  IonButton,
  IonItem,
  IonCheckbox,
  alertController,
  IonFooter,
  IonToolbar,
  IonRadio,
  IonRadioGroup,
  IonCard,
  IonCardContent,
  IonInput,
  IonListHeader,
  loadingController
} from "@ionic/vue"
import {trCart, trCartForm, trProfile, trCard} from "@/i18n/messages";
import Toolbar from "@/components/Toolbar";
import {mapActions} from "vuex";
import { logoApple, card, logoYen } from 'ionicons/icons';
import {useRouter} from "vue-router";
import ApiService from "@/services/api.service";
import { defineComponent } from 'vue'
import {AuthService} from "@/services/auth.service";
import UserAddressesWallet from "@/components/UserAddressesWallet";
import {WalletService} from "@/services/wallet.service";
export default defineComponent({
  name: "WalletBuy",
  components: {
    UserAddressesWallet,
    IonPage, IonContent, IonSpinner, Toolbar, IonRow, IonCol, IonGrid, IonIcon, IonText, IonLabel, IonList, IonButton, IonItem, IonCheckbox, IonFooter, IonToolbar, IonRadio, IonRadioGroup, IonCard, IonCardContent, IonInput, IonListHeader
  },
  methods: {
    ionViewDidEnter() {
      this.$emitter.emit('update:wallet')
      this.$emitter.on('update:cart', () => {
        this.total =  WalletService.getAmount();
        this.wallet = WalletService.get();
      });
    },
    ionViewWillEnter() {
      if(AuthService.isAuth()) {
        this.loadProfile();
        ApiService.get('payMethods').then(res => {
          if(res.data && res.data.data && res.data.data.length) {
            this.payMethods = res.data.data
          } else {
            this.wallet.methodId = null
          }
        });
      }
      ApiService.get('countries').then(res => {
        this.countries = res.data.data
      })
    },
    async changePayMethod() {
      if(this.wallet.methodId === false) {
        const loading = await loadingController.create({});
        await loading.present();
        const offsetTop = document.getElementById("address").offsetTop + document.getElementById("address").offsetHeight
        setTimeout(async () => {
          await loading.dismiss();
          this.$el.querySelector("#container").scrollToPoint(0, offsetTop, 1000);
        }, 1000)
      }
    },
    getPayMethod() {
      if(this.hasPayMethods) {
        return this.payMethods.filter(method => {
          return this.wallet.methodId === method.id
        })[0]
      }
      return null;
    },
    setCountry() {
      if(!this.countries) {
        return;
      }
      return this.wallet.country = this.countries.find((country) => {
        return this.wallet.countryCode === country.code
      })
    },
    saveOrder() {
      const payMethod = this.getPayMethod()
      if(this.wallet.methodId) {
        this.wallet.countryCode = payMethod.deliveryCountryCode
        const names = payMethod.contactName.split(' ')
        this.wallet.lastName = names.length ? names[0] : ''
        this.wallet.firstName = names.length > 1 ? names[1] : ''
        this.wallet.middleName = names.length > 2 ? names[2] : ''
        this.wallet.postalCode = payMethod.postalCode
        this.wallet.address = payMethod.address
        this.wallet.house = payMethod.house
        this.wallet.housing = payMethod.housing
        this.wallet.phone = payMethod.phone
        this.wallet.city = payMethod.city
        this.wallet.apartment = payMethod.apartment
        this.wallet.building = payMethod.building
        this.wallet.street = payMethod.street
        this.wallet.state = payMethod.state
        this.setCountry()
      }
      this.wallet.countryCode = this.wallet.country.code
      WalletService.set(this.wallet)
      if(!this.wallet.methodId) {
        this.$emitter.emit('address:save')
      }
      this.router.push('/wallet/pay')
    },
    ...mapActions("profile", ["get", "post"]),
    async loadProfile() {
      await this.get().then((res) => {
        this.profile = res.data.data
      });
    },
    async errorAlert() {
      const alert = await this.alertController.create({
        header: 'Failed!',
        message: this.$tr(trCartForm, 'chooseCountry'),
        buttons: ['OK'],
      });
      await alert.present();
    }
  },
  mounted() {
    this.loadProfile()
    this.$emitter.on('address:upd', (address) => {
      if(address) {
        this.wallet = {...this.wallet, ...address}
        this.setCountry()
      }
    })
    this.$emitter.on('address:scroll', async () => {
      const loading = await loadingController.create({});
      await loading.present();
      let offsetTop = 0
      if(!this.hasPayMethods) {
        offsetTop = document.getElementById("address").offsetTop
      } else {
        offsetTop = document.getElementById("UserAddressesWallet").offsetTop
      }
      setTimeout(async () => {
        await loading.dismiss();
        // eslint-disable-next-line no-undef
        this.$el.querySelector("#container").scrollToPoint(0, offsetTop, 1000);
      }, 1000)
    })
  },
  data() {
    return {
      profile: null,
      total:  WalletService.getAmount(),
      wallet: WalletService.get(),
      canMakePayments: false,
      countries: null,
      payMethods: null,
      confirmPay: false,
      isAuth: AuthService.isAuth(),
      addComment: false,
      addPostcard: false
    }
  },
  setup() {
    const router = useRouter()
    return {
      trCart, trCartForm, logoApple, trProfile, router, alertController, trCard, card, logoYen
    }
  },
  computed: {
    hasPayMethods() {
      return this.payMethods && this.payMethods.length;
    },
    isAnother() {
      return this.wallet.email !== this.profile.email
    }
  }
})
</script>