<template>
  <ion-page>
    <toolbar :title="$tr(trMenu, 'account')" :backbutton="true"/>
    <ion-content fullscreen class="timeline-inside-content">
      <hr class="ion-margin-top not-lines"/>
      <hr class="ion-margin-top not-lines"/>
      <ion-button @click="handleSignOut" :disabled="loading" expand="block" type="button" color="shiawasedo">
        <ion-icon slot="icon-only" :icon="exit"></ion-icon>&nbsp;
        {{ $tr(trMenu, 'exit') }}
      </ion-button>
      <hr class="ion-margin-top not-lines"/>
      <hr class="ion-margin-top"/>
      <ion-item lines="none">
        <h1>{{ $tr(trProfile, 'delete') }}</h1>
      </ion-item>
      <ion-item lines="none">
        <p v-html="deleteText"></p>
      </ion-item>
      <ion-item lines="none">
        <p v-html="inputEmail"></p>
      </ion-item>
      <ion-item lines="none">
        <ion-input class="grey-input" v-model="checkEmail" />
      </ion-item>
      <hr class="ion-margin-top not-lines"/>
      <ion-text lines="none">
        <ion-button @click="deleteConfirm" :disabled="loading" expand="block" type="button" color="danger">
          <ion-icon slot="icon-only" :icon="trash"></ion-icon>&nbsp;
          {{ $tr(trProfile, 'delete') }}
        </ion-button>
      </ion-text>
      <hr class="ion-margin-top not-lines"/>
      <hr class="ion-margin-top not-lines"/>
    </ion-content>
  </ion-page>
</template>

<script>
import {trCalendar, trCommon, trMenu, trProfile, trSignIn} from '@/i18n/messages'
import Toolbar from "@/components/Toolbar.vue";
import {
  IonContent,
  IonPage,
  IonItem,
  IonButton,
  IonIcon, alertController, IonText, IonInput
} from '@ionic/vue';
import {mapActions} from "vuex";
import {exit, trash} from "ionicons/icons";
import ApiService from "@/services/api.service";
export default {
  name: "Account",
  components: {Toolbar, IonContent, IonPage, IonItem, IonButton, IonIcon, IonText, IonInput},
  setup() {
    return { trMenu, trProfile, trSignIn, exit, trash }
  },
  data() {
    return {
      profile: {},
      loading: false,
      checkEmail: '',
      deleteText: this.$tr(trProfile, 'deleteText'),
      inputEmail: this.$tr(trProfile, 'inputEmail')
    }
  },
  methods: {
    ...mapActions("auth", ["signOut"]),
    async alertError(text) {
      const alert = await alertController.create({
        header: this.$tr(trCommon, 'error'),
        message: text,
        buttons: ['OK'],
      });
      await alert.present();
    },
    async deleteConfirm() {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: this.$tr(trProfile, 'confirmHeader'),
            message: this.$tr(trProfile, 'confirmMessage'),
            buttons: [
              {
                text: this.$tr(trSignIn, 'cancelCode'),
                role: 'cancel',
                cssClass: 'secondary'
              },
              {
                text: this.$tr(trCalendar, 'confirmYes'),
                handler: () => {
                  this.deleteProfile()
                },
              },
            ],
          });
      return alert.present();
    },
    async deleteProfile() {
      this.loading = true
      ApiService.post('activity', {
        checkEmail: this.checkEmail
      }).then(() => {
        this.signOut().then(() => {
          window.location.href = '/timeline'
        });
        this.loading = false
      }).catch(error => {
        const response = error.response;
        this.alertError(response.data.meta.error_message)
        this.loading = false
      })
    },
    async handleSignOut() {
      await this.signOut().then(() => {
        window.location.reload()
      });
    },
  }
}
</script>