import { alertController, isPlatform } from "@ionic/vue";
import { NativeMarket } from "@capacitor-community/native-market";
import { AppVersion } from "@ionic-native/app-version";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import ApiService from "@/services/api.service";

interface AppUpdate {
  current: string;
  majorMsg: {
    title: string;
    msg: string;
    btn: string;
  };
  minorMsg: {
    title: string;
    msg: string;
    btn: string;
  };
}

const UpdateService = {
  async checkForUpdate() {
    ApiService.get("checkVersion").then(async (res) => {
      console.log('checkVersion')
      const info: AppUpdate = res.data.data;
      // Проверяем мажорную/минорную версию и показываем инфу
      const versionNumber = await AppVersion.getVersionNumber();
      const splittedVersion = versionNumber.split(".");
      const serverVersion = info.current.split(".");

      // Check Major code, then minor code
      if (serverVersion[0] > splittedVersion[0]) {
        await this.presentAlert(
          info.majorMsg.title,
          info.majorMsg.msg,
          info.majorMsg.btn
        );
      } else if (serverVersion[1] > splittedVersion[1]) {
        await this.presentAlert(
          info.minorMsg.title,
          info.minorMsg.msg,
          info.minorMsg.btn,
          true
        );
      }
    });
  },
  openAppstoreEntry() {
    // Use your apps bundle ID and iOS ID!
    if (isPlatform("android")) {
      NativeMarket.openStoreListing({
        appId: "jp.co.shiawasedo.app",
      });
    } else {
      InAppBrowser.create(
        "itms-apps://itunes.apple.com/app/id1560234970",
        "_blank"
      );
    }
  },
  async presentAlert(
    header: string,
    message: string,
    buttonText = "",
    allowClose = false
  ) {
    const buttons: any = [];

    if (buttonText != "") {
      buttons.push({
        text: buttonText,
        handler: () => {
          this.openAppstoreEntry();
        },
      });
    }

    if (allowClose) {
      buttons.push({
        text: "Close",
        role: "cancel",
      });
    }

    const alert = await alertController.create({
      header,
      message,
      buttons,
      backdropDismiss: allowClose,
    });

    await alert.present();
  },
};

export { UpdateService };
