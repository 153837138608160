const POST_TYPES = {
    BLOG: 10,
    ANNOUNCE: 20,
    ACTION: 30,
    PRODUCT: 40,
    SUBSCRIBE: 50,
    POLL: 60,
    CATALOG: 70,
}
export default POST_TYPES
