
import {trProfile, trSignIn} from '@/i18n/messages'
import Toolbar from "@/components/Toolbar.vue";
import {
  IonContent,
  IonItem,
  IonIcon,
  IonInput,
  IonCheckbox,
  IonButton,
  IonLabel,
  IonPage,
  IonDatetime,
  IonText,
  IonSpinner
} from '@ionic/vue';
import {save, trash} from "ionicons/icons";
import {mapActions} from "vuex";
export default {
name: "Profile.vue",
  components: {Toolbar, IonContent, IonItem, IonInput, IonCheckbox, IonButton, IonLabel, IonPage, IonDatetime, IonText, IonSpinner, IonIcon},
  data() {
    return {
      profile: null,
      loading: false
    }
  },
  setup() {
    return { trProfile, trSignIn, save, trash }
  },
  methods: {
    ...mapActions("profile", ["get", "post"]),
    async loadProfile() {
      await this.get().then((res: any) => {
        this.profile = res.data.data;
      });
    },
    async handleSave() {
      this.loading = true
      await this.post(this.profile).then((res: any) => {
        this.profile = res.data.data;
        this.loading = false
      });
    },
    ionViewWillEnter() {
      this.loadProfile();
    }
  }
}
