<template>
  <div class="banner-body ion-padding" :style="{height: bannerHeight ? bannerHeight + 'px' : ''}" v-if="bannerPosts">
    <ion-buttons>
      <ion-button @click="hideBanner">
        <ion-icon slot="icon-only" color="dark" :icon="closeCircle" />
      </ion-button>
    </ion-buttons>
    <carousel :items-to-show="1">
      <slide v-for="(post, key) in bannerPosts" :key="key" @click="toPost(post)">
        <h4 v-html="filters.striptags(post.title)"></h4>
        <div class="img-block" v-if="maxHeight" :style="{
            backgroundImage: 'url(' + $getSrc(post.bannerImage) + ')',
            backgroundPosition: scrollPoint > 0 ? 'center right' : 'center center',
          }"></div>
      </slide>
      <template #addons>
        <pagination />
      </template>
    </carousel>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import filters from '@/filters/filters';
import {closeCircle} from "ionicons/icons";
import {IonIcon, IonButton, IonButtons} from "@ionic/vue";
import {TokenService} from "@/services/token.service";
export default {
  name: "Banner",
  components: { Carousel, Slide, Pagination, IonIcon, IonButton, IonButtons },
  props: {
    scrollPoint: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      bannerHeight: null,
      bannerWidth: 0,
      bannerPosts: null,
      maxHeight: null,
      minHeight: null
    }
  },
  methods: {
    hideBanner() {
      this.$emitter.emit('banner:close');
    },
    isShared(post) {
      return post && post.data && post.data.url;
    },
    toPost(post) {
      if(this.isShared(post)) {
        return window.open(post.data.url + '?access_token=' + TokenService.getToken(), '_system', 'location=yes');
      }
      this.$router.push(`/timeline/${post.id}`)
    }
  },
  mounted() {
    ApiService.get('banner').then(res => {
      if(res.data.data.length) {
        this.bannerPosts = res.data.data
        setTimeout(() => {
          this.bannerWidth = this.$el["offsetWidth"]
          this.minHeight = this.bannerWidth*0.4;
          this.maxHeight = this.bannerWidth*0.8;
          this.bannerHeight = this.maxHeight;
        })
      }
    })
  },
  setup() {
    return { filters, closeCircle }
  },
  watch: {
    bannerHeight(val) {
      if(this.bannerPosts) {
        this.$emitter.emit('banner:scroll', val);
      }
    },
    scrollPoint(newVal, oldVal) {
      let bannerHeight = this.bannerHeight;

      if(newVal <= 0) {
        this.bannerHeight = this.maxHeight;
        return;
      }

      // Скорость прокрутки замедляем в 10 раз от скролла
      const velocity = Math.ceil(newVal/10);

      // Тянем вниз
      if(newVal > oldVal) {
        // 2. Проверяем докрутили ли до минимальной высоты
        if((bannerHeight - velocity) < this.minHeight) {
          bannerHeight = this.minHeight;
        } else {
          // 3. Уменьшаем высоту
          bannerHeight = bannerHeight - velocity;
        }
      }

      // Тянем вверх
      if(newVal < oldVal && newVal < 2 * this.maxHeight) {
        // 2. Проверяем докрутили ли до максимальной высоты
        if((bannerHeight + velocity) > this.maxHeight) {
          bannerHeight = this.maxHeight;
        } else {
          // 3. Увеличиваем высоту
          bannerHeight = bannerHeight + velocity;
        }
      }

      this.bannerHeight = bannerHeight;
    }
  },
}
</script>

<style lang="scss">
@import "~vue3-carousel/dist/carousel.css";
.carousel__pagination-button {
  margin: 5px;
  width: 70px!important;
  height: 2px;
  border: 0;
  cursor: pointer;
  background-color: #bbbbbb;
}
.carousel__pagination-button--active {
  background-color: var(--ion-color-shiawasedo-shade);
}
.banner-body {
  color: #323232;
  background: #fff;
  width: 100%;
  transition: height 0.25s ease-in;
  z-index: 1000;
  overflow: hidden;
  border-bottom: 1px solid #eee;
  .carousel {
    height: 100%;
    .carousel__viewport {
      height: 100%;
      .carousel__track {
        height: 100%;
      }
    }
    .carousel__slide {
      text-align: left;
      display: block;
    }
    .carousel__pagination {
      position: absolute;
      bottom:0;
      margin: 0;
      width: 100%;
      padding-left: 0;
    }
  }
  ion-buttons {
    position: absolute;
    right: 6px;
    font-size: 30px;
    margin-top: -5px;
  }
  h4 {
    padding: 0;
    margin: 0 0 7px;
    line-height: 20px;
  }
  .img-block {
    text-align: center;
    height: calc(100% - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-size: contain;
    transition: background-position 0.15s ease-in;
    background-repeat: no-repeat;
  }
}
</style>