<template>
  <div v-show="!load" id="paypal-button-container"></div>
  <div class="text-center" v-if="load">
    <ion-spinner name="circles" />
  </div>
</template>

<script>
import { loadScript } from "@paypal/paypal-js";
import ApiService from "@/services/api.service";
import {CartService} from "@/services/cart.service";
import {OrderService} from "@/services/order.service";
import {IonSpinner} from "@ionic/vue";
import {WalletService} from "@/services/wallet.service";
const PAYPAL_PAY_METHOD = 1;
export default {
  name: "PayPalButton",
  props: ['order', 'wallet'],
  components: { IonSpinner },
  data() {
    return {
      invoiceId: null,
      orderId: null,
      load: true
    }
  },
  mounted: function () {
    loadScript({
      "client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID,
      "disable-funding": "card",
      "currency": 'JPY'
    }).then((paypal) => {
      this.load = false
      paypal.Buttons({
        createOrder: async (data, actions) => {
          const res = await this.getInvoiceId()
          const order = res.data.data
          const preset = this.order ? this.order : this.wallet
          this.invoiceId = res.data.data.number
          this.orderId = res.data.data.id
          return actions.order.create({
            application_context: {
              shipping_preference: 'SET_PROVIDED_ADDRESS'
            },
            purchase_units: [
              {
                invoice_id: this.invoiceId + this.$root.environment ? Date.now() : '',
                amount: {
                  value: this.order ? CartService.total() : parseFloat(this.wallet.amount) + parseFloat(this.wallet.fee.amount)
                },
                shipping: {
                  address: {
                    address_line_1: order.addressLine1,
                    address_line_2: order.addressLine2,
                    admin_area_1: order.adminArea1,
                    admin_area_2: order.adminArea2,
                    country_code: preset.countryCode,
                    postal_code: preset.postalCode
                  },
                  name: {
                    full_name: preset.contact
                  },
                  type: 'SHIPPING'
                }
              }
            ],
          })
        },
        onError: (data) => {
          console.log(data)
        },
        onApprove: (data, actions) => {
          if (this.order) {
            return actions.order.capture().then((details) => {
             ApiService.get('paypal', {
               params: {
                 token: details.id,
                 payerId: details.payer.payer_id,
                 id: this.orderId
               }
             }).then((res) => {
               CartService.clearCart()
               OrderService.clear()
               if(res.data.data && res.data.data.number) {
                 window.location.href = '/order-success/' + res.data.data.number
               }
             })
           })
          }

          if (this.wallet) {
            return actions.order.capture()
                .then((details) => {
                    ApiService.get('walletReplenishment', {
                      params: {
                        token: details.id,
                        payerId: details.payer.payer_id,
                        id: this.orderId,
                        payMethod: PAYPAL_PAY_METHOD,
                        wallet: this.wallet
                      }
                    }).then((res) => {
                      if(res.data.data) {
                        WalletService.clear()
                        window.location.href = '/wallet/success/' + res.data.data.id
                      }
                    })
            })
          }
        }
      }).render('#paypal-button-container');
    })
  },
  methods: {
    getInvoiceId() {
      if(this.order) {
        return ApiService.post('paypal', {
          order: this.order,
          cart: CartService.getCart(),
          total: CartService.total(),
          promoCode: CartService.getPromoCode()
        })
      }
      if(this.wallet) {
        return ApiService.post('walletReplenishment', {
          wallet: this.wallet,
          payMethod: PAYPAL_PAY_METHOD
        })
      }
    }
  }
}
</script>

<style>
 #paypal-button-container {
   min-height: 58px;
   margin-top: 20px;
   z-index: 10000!important;
 }
 .paypal-checkout-sandbox {
   top: 35px!important;
 }
</style>