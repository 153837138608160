import { ProductsService, ResponseError } from "@/services/products.service";

const state = {
	responseData: "",
	responseErrorCode: 0,
	responseError: "",
};

const getters = {
	responseErrorCode: (state: { responseErrorCode: any }) => {
		return state.responseErrorCode;
	},
	responseError: (state: { responseError: any }) => {
		return state.responseError;
	}
};

const actions = {
	async getList(context: any, filters = {}) {
		context.commit("dataRequest");
		try {
			const resp = await ProductsService.getList(filters);
			context.commit("dataSuccess", resp);
			return resp;
		} catch (e) {
			if (e instanceof ResponseError) {
				context.commit("dataError", {
					errorMessage: e.errorMessage,
					responseErrorCode: e.errorCode
				});
			}
			return e.message;
		}
	}
};

const mutations = {
	dataRequest(state: {
		responseError: string;
		responseErrorCode: number;
	}) {
		state.responseError = "";
		state.responseErrorCode = 0;
	},
	dataSuccess(state: { responseData: string }, payload: any) {
		state.responseData = payload;
	},
	dataError(state: {
		responseError: any;
		responseErrorCode: any;
	}, {errorCode, errorMessage}: any) {
		state.responseError = errorMessage;
		state.responseErrorCode = errorCode;
	}
}

export const products = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
