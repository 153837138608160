<template>
  <svg x="0px" y="0px" viewBox="0 0 566.93 130.39" class="summus-logo" width="300px">
    <g class="shiawasedo-logo-circle">
      <path d="M64.52,0A64.53,64.53,0,1,0,129,64.52,64.52,64.52,0,0,0,64.52,0ZM8.72,64.59A55.87,55.87,0,1,1,107,101H69.19V93.15H95.4V85.7H69.19V81.6h29.5V74.21H82.07l1.61-6.31h15V60.52H69.19v-3.7H91.47V49.3H69.19V43.41H60.66V49.3H38.52v7.52H60.66v3.7H31.56V67.9H46.1l1.81,6.31H31.56V81.6h29.1v4.1h-26v7.45h26V101H22.21A55.59,55.59,0,0,1,8.72,64.59Zm47.72,9.62L54.83,67.9H75l-1.48,6.31ZM30,108.41H99.2a55.78,55.78,0,0,1-69.23,0Z"></path>
      <path d="M32.27,54.67V41.79H97.9V54.67h8.56V34.17h-17A81.79,81.79,0,0,0,96,23.84l-8.12-3.39A78.28,78.28,0,0,1,79,34.17H69.3V20.45H60.92V34.17h-9.7A79.17,79.17,0,0,0,42.5,20.45l-8.13,3.39c.59.85,4.91,6.77,6.6,10.33H23.63v20.5Z"></path>
    </g>
    <g class="shiawasedo-brand-en">
      <path d="M176.9,55.58c0,3.38-1.39,6.24-4.17,8.57c-2.63,2.22-5.71,3.33-9.24,3.33c-1.73,0-3.78-0.41-6.14-1.24
c-2.37-0.79-3.78-1.18-4.23-1.18c-0.98,0-2.14,0.58-3.5,1.75l-3.55-10.54l1.35-0.45c1.65,3.04,3.91,5.47,6.76,7.27
c2.82,1.77,5.92,2.65,9.3,2.65c2.85,0,5.28-0.75,7.27-2.25c2.18-1.65,3.27-3.85,3.27-6.6c0-2.06-0.6-3.68-1.8-4.85
c-0.79-0.75-2.29-1.6-4.51-2.54c-2.78-1.01-6.91-2.57-12.4-4.68c-2.56-1.01-4.32-1.94-5.3-2.76c-1.62-1.35-2.42-3.19-2.42-5.52
c0-3.08,1.2-5.58,3.61-7.5c2.29-1.84,5.13-2.76,8.51-2.76c1.62,0,3.46,0.3,5.52,0.9c2.07,0.56,3.31,0.85,3.72,0.85
c1.09,0,2.12-0.21,3.1-0.62l2.65,9.47l-1.13,0.23c-1.99-3.08-3.89-5.28-5.69-6.59c-2.25-1.65-4.98-2.48-8.17-2.48
c-2.63,0-4.87,0.7-6.71,2.08c-1.84,1.39-2.76,3.12-2.76,5.19c0,1.58,0.88,2.97,2.65,4.17c0.79,0.53,2.42,1.31,4.9,2.37
c2.97,1.09,5.96,2.16,8.96,3.21c3.87,1.46,6.46,2.87,7.78,4.23C176.11,50.88,176.9,52.98,176.9,55.58z"></path>
      <path d="M228.41,66.57h-15.39v-1.75c2.33,0,3.83-0.19,4.51-0.56c0.94-0.53,1.41-1.8,1.41-3.83V47.18h-24.69v13.24
c0,2.03,0.47,3.31,1.41,3.83c0.68,0.38,2.18,0.56,4.51,0.56v1.75h-15.39v-1.75c2.22,0,3.66-0.19,4.34-0.56
c1.05-0.56,1.58-1.84,1.58-3.83V33.31c0-2.03-0.49-3.31-1.47-3.83c-0.64-0.38-2.12-0.56-4.45-0.56v-1.75h15.39v1.75
c-2.33,0-3.83,0.19-4.51,0.56c-0.94,0.53-1.41,1.8-1.41,3.83v12.12h24.69V33.31c0-2.03-0.47-3.31-1.41-3.83
c-0.68-0.38-2.18-0.56-4.51-0.56v-1.75h15.39v1.75c-2.33,0-3.82,0.19-4.45,0.56c-0.98,0.53-1.47,1.8-1.47,3.83v27.11
c0,1.99,0.53,3.27,1.58,3.83c0.68,0.38,2.12,0.56,4.34,0.56V66.57z"></path>
      <path d="M251.69,66.57H236.3v-1.75c2.22,0,3.66-0.19,4.34-0.56c1.05-0.56,1.58-1.84,1.58-3.83V33.31c0-2.03-0.49-3.31-1.47-3.83
c-0.64-0.38-2.12-0.56-4.45-0.56v-1.75h15.39v1.75c-2.33,0-3.83,0.19-4.51,0.56c-0.94,0.53-1.41,1.8-1.41,3.83v27.11
c0,2.03,0.47,3.31,1.41,3.83c0.68,0.38,2.18,0.56,4.51,0.56V66.57z"></path>
      <path d="M299.94,66.57h-15.39v-1.75c3.19,0,4.79-0.73,4.79-2.2c0-0.26-0.21-0.9-0.62-1.92l-3.32-8.23h-18.21l-2.37,6.14
c-0.83,2.14-1.24,3.48-1.24,4c0,1.46,1.69,2.2,5.07,2.2v1.75h-12.74v-1.75c3.04-0.86,5.11-2.59,6.2-5.19l13.13-31.79l3.55-1.8
c3.3,8.15,7.01,17.04,11.1,26.66l3.33,7.72c1.13,2.67,3.36,4.13,6.71,4.4V66.57z M284.72,50.73l-8.34-20.69l-8.46,20.69H284.72z"></path>
      <path d="M349.54,28.92c-3.08,0-5.26,2.25-6.54,6.76l-8.62,30.89h-1.35l-9.75-32.13l-9.19,32.13h-0.85l-10.37-33.25
c-0.83-2.71-2.74-4.17-5.75-4.4v-1.75h14.77v1.75h-1.69c-2.1,0-3.15,0.73-3.15,2.2c0,0.53,0.45,2.41,1.35,5.64l6.14,22.09
l7.55-27.34c-1.05-1.39-2.61-2.26-4.68-2.59v-1.75h13.64v1.75l-1.64,0.06c-1.84,0.08-2.76,0.56-2.76,1.47
c0,0.38,0.51,2.25,1.52,5.64l6.65,22.15l6.54-23.17c0.45-1.62,0.68-2.74,0.68-3.38c0-1.13-0.47-1.88-1.41-2.25
c-0.56-0.26-1.56-0.43-2.99-0.51v-1.75h11.89V28.92z"></path>
      <path d="M390.74,66.57h-15.39v-1.75c3.19,0,4.79-0.73,4.79-2.2c0-0.26-0.21-0.9-0.62-1.92l-3.32-8.23h-18.21l-2.37,6.14
c-0.83,2.14-1.24,3.48-1.24,4c0,1.46,1.69,2.2,5.07,2.2v1.75h-12.74v-1.75c3.04-0.86,5.11-2.59,6.2-5.19l13.13-31.79l3.55-1.8
c3.3,8.15,7.01,17.04,11.1,26.66l3.33,7.72c1.13,2.67,3.36,4.13,6.71,4.4V66.57z M375.52,50.73l-8.34-20.69l-8.46,20.69H375.52z"></path>
      <path d="M425.23,55.58c0,3.38-1.39,6.24-4.17,8.57c-2.63,2.22-5.71,3.33-9.24,3.33c-1.73,0-3.78-0.41-6.14-1.24
c-2.37-0.79-3.78-1.18-4.23-1.18c-0.98,0-2.14,0.58-3.49,1.75l-3.55-10.54l1.35-0.45c1.65,3.04,3.91,5.47,6.76,7.27
c2.82,1.77,5.92,2.65,9.3,2.65c2.85,0,5.28-0.75,7.27-2.25c2.18-1.65,3.27-3.85,3.27-6.6c0-2.06-0.6-3.68-1.8-4.85
c-0.79-0.75-2.29-1.6-4.51-2.54c-2.78-1.01-6.91-2.57-12.4-4.68c-2.56-1.01-4.32-1.94-5.3-2.76c-1.62-1.35-2.42-3.19-2.42-5.52
c0-3.08,1.2-5.58,3.61-7.5c2.29-1.84,5.13-2.76,8.51-2.76c1.62,0,3.46,0.3,5.52,0.9c2.07,0.56,3.31,0.85,3.72,0.85
c1.09,0,2.12-0.21,3.1-0.62l2.65,9.47l-1.13,0.23c-1.99-3.08-3.89-5.28-5.69-6.59c-2.25-1.65-4.98-2.48-8.17-2.48
c-2.63,0-4.87,0.7-6.71,2.08c-1.84,1.39-2.76,3.12-2.76,5.19c0,1.58,0.88,2.97,2.65,4.17c0.79,0.53,2.42,1.31,4.9,2.37
c2.97,1.09,5.96,2.16,8.96,3.21c3.87,1.46,6.46,2.87,7.78,4.23C424.44,50.88,425.23,52.98,425.23,55.58z"></path>
      <path d="M469.7,57.33l-4.23,9.24h-32.35v-1.75c3.94,0,5.92-1.24,5.92-3.72V32.24c0-1.5-0.66-2.48-1.97-2.93
c-0.68-0.26-1.99-0.39-3.94-0.39v-1.75h30.6l3.78,8.34l-1.35,0.45c-2.63-3.23-5.6-5.3-8.91-6.2c-2.07-0.56-5.77-0.85-11.1-0.85
c-1.39,0-2.33,0.21-2.82,0.62c-0.49,0.41-0.73,1.32-0.73,2.71v12.51h4.68c3.64,0,6.03-0.23,7.16-0.68
c1.95-0.79,2.93-2.61,2.93-5.47h1.75v13.41h-1.75c-0.38-2.63-1.56-4.28-3.55-4.96c-1.05-0.34-3.59-0.51-7.61-0.51h-3.61v15.16
c0,1.28,0.38,2.14,1.13,2.59c0.56,0.34,1.58,0.51,3.04,0.51c7.33,0,12.08-0.53,14.26-1.58c2.03-0.98,4.47-3.25,7.33-6.82
L469.7,57.33z"></path>
      <path d="M517.44,48.53c0,5.83-2.27,10.39-6.82,13.7c-4.02,2.89-9.02,4.34-14.99,4.34h-17.87v-1.75c2.1,0,3.29-0.02,3.55-0.06
c1.47-0.26,2.2-0.98,2.2-2.14V31.57c0-1.24-0.73-2.05-2.2-2.42c-0.64-0.15-1.88-0.23-3.72-0.23v-1.75h13.64
C508.71,27.17,517.44,34.29,517.44,48.53z M513.44,46.95c0-6.05-2.1-10.65-6.31-13.81c-3.76-2.82-8.7-4.23-14.82-4.23
c-1.8,0-2.99,0.09-3.55,0.28c-1.13,0.34-1.69,1.13-1.69,2.37v31.06c0,1.13,0.43,1.82,1.3,2.09c0.26,0.08,1.22,0.11,2.87,0.11
c6.99,0,12.23-1.16,15.73-3.49C511.28,58.47,513.44,53.68,513.44,46.95z"></path>
      <path d="M566.93,46.9c0,6.05-2.07,11.05-6.2,14.99c-3.94,3.76-8.72,5.64-14.32,5.64s-10.37-1.88-14.32-5.64
c-4.13-3.95-6.2-8.94-6.2-14.99s2.07-11.05,6.2-14.99c3.94-3.76,8.72-5.64,14.32-5.64s10.37,1.88,14.32,5.64
C564.86,35.85,566.93,40.85,566.93,46.9z M562.98,46.9c0-5.45-1.37-9.88-4.12-13.3c-2.97-3.72-7.12-5.58-12.46-5.58
s-9.49,1.86-12.46,5.58c-2.74,3.42-4.12,7.85-4.12,13.3c0,5.45,1.37,9.88,4.12,13.3c2.97,3.72,7.12,5.58,12.46,5.58
s9.49-1.86,12.46-5.58C561.61,56.78,562.98,52.35,562.98,46.9z"></path>
    </g>
    <g class="shiawasedo-brand-ja">
      <path d="M171.96,83.87c0,0.25-0.43,1.21-0.48,1.42c-0.36,1.29-1.06,6.89-1.06,9.01c0,2.56,0.48,4.84,3.87,4.84
c1.24,0,3.06-0.25,5.32-1.32c0.38-0.18,2.31-1.24,2.41-1.24c0.1,0,0.13,0.08,0.13,0.13c0,0.28-3.75,4.35-8.51,4.35
c-3.29,0-4.86-1.9-4.86-6.99c0-1.57,0.05-2.08,0.58-7.04c0.1-0.84,0.33-2.91,0.33-3.62c0-0.46-0.08-0.89-0.23-1.24
c-0.02-0.08-0.28-0.66-0.28-0.68c0-0.18,0.2-0.25,0.36-0.25C170.84,81.24,171.96,83.29,171.96,83.87z"></path>
      <path d="M205.48,97.57c-1.29,1.01-2.58,1.85-3.8,1.85c-1.14,0-1.24-1.29-1.24-1.75c0-2.73,2.43-5.65,5.52-7.77
c0.78-0.56,1.04-0.78,1.04-2.58c0-0.41-0.08-0.53-0.56-0.53c-0.05,0-0.96,0.05-1.14,0.05c-2.25,0-3.24-1.62-3.24-1.85
c0-0.15,0.1-0.25,0.3-0.25c0.15,0,0.91,0.23,1.06,0.25c0.63,0.08,1.24,0.1,1.72,0.1c0.46,0,1.92,0,2.08-0.38
c0.15-0.35,0.23-1.77,0.23-1.87c0-0.25-0.25-1.55-0.25-1.6c0-0.13,0.13-0.15,0.23-0.15c0.05,0,2.23,0.46,2.23,1.01
c0,0.03-0.38,0.84-0.4,0.89c-0.25,0.53-0.36,1.01-0.36,1.24c0,0.2,0.03,0.25,0.33,0.25c0.53,0,2.86-0.83,3.34-0.83
c0.61,0,1.06,0.4,1.06,0.83c0,0.81-0.91,1.04-3.06,1.57c-1.49,0.35-1.87,0.46-2.03,0.66c-0.13,0.18-0.28,0.89-0.28,1.24
c0,0.58,0.13,0.63,0.43,0.63c0.2,0,0.58-0.08,0.74-0.13c1.21-0.3,2.3-0.48,3.16-0.48c3.7,0,5.75,2.56,5.75,5.8
c0,5.49-5.04,6.91-7.39,7.55c-0.15,0.05-1.16,0.3-1.75,0.3c-0.15,0-0.25-0.05-0.25-0.18c0-0.1,0.2-0.18,0.51-0.28
c5.09-1.72,6.96-4.38,6.96-7.22c0-3.19-2.13-4.89-4.18-4.89c-1.22,0-1.27,0.15-1.72,1.65c-0.43,1.37-0.83,2-1.74,3.44
c-0.25,0.4-0.28,0.53-0.28,0.91c0,0.2,0.08,1.11,0.08,1.29c0,0.68-0.25,1.27-0.81,1.27c-0.43,0-0.61-0.3-0.78-0.58
c-0.18-0.3-0.2-0.35-0.28-0.35C206.64,96.66,205.91,97.24,205.48,97.57z M206.62,94.56c0.1-0.25,0.15-2.91,0.15-3.19
c0-0.15,0-0.58-0.18-0.58c-0.94,0-4.51,4.25-4.51,6.15c0,0.35,0.13,0.61,0.53,0.56C203.48,97.39,206.26,95.47,206.62,94.56z
 M209.48,89.49c-1.32,0-1.32,0.86-1.32,1.75c0,0.23,0,1.21,0.3,1.21c0.38,0,1.39-2,1.39-2.66c0-0.02-0.02-0.2-0.08-0.25
C209.73,89.49,209.55,89.49,209.48,89.49z"></path>
      <path d="M242.53,86.2c0.66,0.41,0.66,0.81,0.66,0.99c0,0.43-0.15,0.78-0.36,1.21c-0.08,0.15-0.18,0.35-0.18,0.48
c0,0.08,0.05,0.13,0.1,0.13c0.13,0,0.61-0.33,0.74-0.38c2.51-1.27,3.87-1.47,5.11-1.47c3.95,0,5.75,2.63,5.75,5.32
c0,6.08-8.1,8.05-8.68,8.05c-0.05,0-0.1,0-0.1-0.05c0-0.08,1.59-0.96,1.87-1.16c1.26-0.86,4.94-3.65,4.94-7.42
c0-2.89-2.1-3.92-3.82-3.92c-2.79,0-5.67,1.52-6.64,2.61c-0.33,0.38-0.48,0.81-0.48,2.53c0,0.56,0.02,3.77,0.13,5.11
c0.02,0.2,0.13,1.04,0.13,1.19c0,0.81-0.73,1.47-1.11,1.47c-0.71,0-0.78-0.91-0.89-2.46c-0.05-0.53-0.2-2.94-0.81-2.94
c-0.13,0-0.46,0.63-0.66,1.04c-0.38,0.73-0.91,1.77-1.82,1.77c-0.3,0-0.61-0.2-0.61-0.71c0-0.43,0.13-0.66,0.76-1.72
c0.25-0.46,1.26-2.2,1.26-2.46c0-0.46-0.53-1.39-0.99-1.95c-0.81-1.01-0.89-1.11-0.89-1.57c0-0.86,0.68-0.96,1.72-1.11
c0.43-0.08,0.58-0.1,2.28-1.67c0.13-0.13,0.63-0.53,0.66-0.61c0.13-0.25,0.23-1.37,0.23-2.15c0-2.25-0.58-3.09-1.01-3.57
c-0.43-0.51-0.48-0.56-0.48-0.66c0-0.2,0.61-0.33,0.86-0.33c0.68,0,2.41,1.04,2.41,1.87c0,0.38-0.33,1.98-0.38,2.3
c-0.05,0.4-0.13,1.19-0.13,1.47C242.09,85.92,242.12,85.95,242.53,86.2z M239.38,89.21c-0.23,0.35-0.5,0.84-1.09,0.89
c-0.81,0.05-1.06,0.08-1.06,0.4c0,0.35,0.91,1.97,1.19,1.97c0.15,0,0.23-0.13,0.51-0.53c1.57-2.3,1.57-2.35,1.57-3.27
c0-0.2,0-0.33-0.1-0.35C240.25,88.3,239.82,88.48,239.38,89.21z M239.31,94.48c0,0.33,0.51,0.99,0.73,0.99c0.3,0,0.3-1.37,0.3-2.53
c0-0.1-0.05-0.15-0.13-0.15C240.02,92.78,239.31,94.18,239.31,94.48z"></path>
      <path d="M286.14,85.29c-0.18,1.7-0.2,1.87-0.2,2.03c0,0.13,0.2,0.13,0.28,0.13c0.1,0,0.99-0.1,1.19-0.1
c0.56-0.05,0.78-0.08,0.78-0.35c0-0.15-0.05-0.2-0.51-0.63c-0.05-0.03-0.1-0.1-0.1-0.15c0-0.1,0.15-0.1,0.2-0.1
c0.25,0,1.42,0.33,1.82,0.46c1.59,0.46,1.97,0.86,1.97,1.37c0,0.66-0.73,0.83-1.24,0.83c-0.51,0-2.71-0.28-3.17-0.28
c-1.47,0-1.49,0.05-1.64,1.37c-0.48,3.75-1.85,5.04-2.76,5.04c-0.86,0-0.96-0.28-1.19-1.01c-0.13-0.38-0.33-0.81-0.48-1.11
c-0.05-0.08-0.33-0.46-0.33-0.53c0-0.05,0.08-0.05,0.13-0.05c0.28,0,1.42,0.46,1.65,0.46c0.43,0,0.68-0.25,0.86-0.66
c0.43-0.91,0.73-2.63,0.73-2.89c0-0.2,0-0.33-0.38-0.33c-0.53,0-5.11,1.01-5.44,1.57c-0.18,0.3-0.2,0.56-0.23,1.04
c-0.02,0.84-0.08,3.14-0.08,3.22c0,2.38,0.46,3.57,4,3.57c0.51,0,1.37-0.03,2.23-0.2c0.1-0.03,0.86-0.18,1.22-0.18
c0.71,0,1.37,0.53,1.37,1.09c0,1.19-2.31,1.19-2.89,1.19c-7.24,0-7.24-2.03-7.24-6.84c0-2.13,0-2.38-0.46-2.38
c-0.43,0-2.18,0.79-2.56,0.79c-0.56,0-2.61-0.48-2.61-1.87c0-0.33,0.1-0.35,0.33-0.35c0.25,0,1.32,0.1,1.54,0.1
c1.57,0,2.79-0.2,3.21-0.35c0.4-0.13,0.43-0.35,0.43-1.29c0-1.37-0.13-2-0.4-2.66c-0.08-0.2-0.58-1.09-0.58-1.19
c0-0.23,0.43-0.35,0.73-0.35c0.61,0,2.18,0.71,2.18,1.59c0,0.15-0.05,0.4-0.13,0.78c0,0.03-0.13,0.78-0.13,1.95
c0,0.53,0,0.73,0.33,0.73c0.18,0,3.37-0.71,4.03-0.81c1.57-0.25,1.75-0.28,1.85-0.81c0.08-0.51,0.13-1.72,0.13-2.05
c0-0.76-0.08-1.32-0.3-1.75c-0.03-0.03-0.48-0.89-0.48-0.94c0-0.25,0.43-0.28,0.56-0.28c1.54,0,2.15,1.24,2.15,1.77
C286.52,83.9,286.16,85.06,286.14,85.29z"></path>
      <path d="M324.76,84.86c0.33,0,0.53-0.05,0.78-0.43c0.18-0.28,0.3-0.41,0.43-0.41c0.1,0,2.48,1.57,2.48,2.23
c0,0.35-0.25,0.51-0.76,0.53c-1.06,0.03-1.22,0.03-1.7,0.18c-0.28,0.08-1.7,0.81-1.7,0.76c0.05-0.08,0.78-1.54,0.78-1.85
c0-0.35-0.25-0.35-0.48-0.35h-14.56c-0.43,0-0.63,0-0.68,0.58c-0.25,2.68-1.77,3.62-2.51,3.62c-0.38,0-0.89-0.3-0.89-0.94
c0-0.63,0.33-0.91,0.79-1.34c0.68-0.63,1.19-1.37,1.52-2.68c0.05-0.2,0.25-1.34,0.33-1.34c0.1,0,0.13,0.1,0.51,0.94
c0.18,0.41,0.25,0.51,0.89,0.51h5.49c0.78,0,0.78-0.38,0.78-1.54c0-0.56-0.02-2.15-0.1-2.63c-0.02-0.13-0.15-0.76-0.15-0.89
c0-0.08,0.02-0.18,0.25-0.18c0.3,0,2.61,0.35,2.61,1.01c0,0.15-0.13,0.28-0.23,0.35c-0.02,0.02-0.38,0.33-0.4,0.38
c-0.13,0.2-0.13,1.65-0.13,2.51c0,0.58,0,0.99,0.78,0.99H324.76z M320.81,96.73c0.71,0,1.04,0,1.24-0.08
c0.25-0.13,1.06-1.32,1.32-1.32c0.3,0,1.92,1.29,1.92,1.7c0,0.38-0.46,0.38-1.37,0.38h-5.09c-0.76,0-0.81,0.38-0.81,1.04
c0,2.03,0,2.23,0.76,2.23h4.84c1.09,0,1.29-0.02,1.44-0.18c0.23-0.25,1.09-1.54,1.39-1.54c0.28,0,2.13,1.65,2.13,2.05
c0,0.35-0.43,0.35-1.32,0.35h-16.68c-2.23,0-2.96,0.05-3.19,0.05c-0.1,0.03-0.48,0.08-0.56,0.08c-0.08,0-0.13-0.08-0.2-0.18
l-0.51-0.58c-0.1-0.1-0.1-0.13-0.1-0.15c0-0.05,0.03-0.08,0.08-0.08c0.18,0,0.96,0.1,1.14,0.1c1.24,0.08,2.28,0.08,3.47,0.08h4.76
c0.76,0,0.76-0.4,0.76-1.01c0-2.13,0-2.25-0.74-2.25h-1.87c-2.23,0-2.96,0.05-3.21,0.05c-0.08,0.03-0.46,0.08-0.53,0.08
c-0.08,0-0.13-0.08-0.23-0.18l-0.48-0.58c-0.1-0.1-0.1-0.13-0.1-0.15c0-0.05,0.03-0.08,0.08-0.08c0.18,0,0.96,0.1,1.12,0.1
c1.24,0.08,2.28,0.08,3.47,0.08h1.77c0.74,0,0.74-0.46,0.74-0.94c0-2.79-0.05-2.99-0.99-2.99h-0.88c-0.71,0-0.76,0.41-0.78,0.68
c-0.05,0.33-0.13,1.04-1.14,1.04c-0.48,0-0.78-0.13-0.78-0.68c0-0.28,0.13-1.47,0.13-1.7c0.02-0.84,0.05-1.67,0.05-2.08
c0-0.53-0.03-1.39-0.08-1.9c-0.03-0.13-0.18-0.71-0.18-0.83c0-0.18,0.13-0.23,0.23-0.23c0.36,0,0.89,0.23,1.29,0.4
c0.66,0.28,1.09,0.28,1.32,0.28h5.92c0.25,0,0.61,0,0.94-0.43c0.25-0.33,0.3-0.38,0.48-0.38c0.3,0,1.77,1.14,1.77,1.49
c0,0.2-0.08,0.28-0.53,0.68c-0.2,0.2-0.2,0.58-0.2,1.32c0,0.33,0.05,1.87,0.05,2.2c0,1.22-1.09,1.22-1.19,1.22
c-0.56,0-0.63-0.25-0.71-0.63c-0.1-0.46-0.43-0.46-0.71-0.46h-2.79c-0.08,0-0.23,0-0.23,0.13c0,0.15,0.05,0.18,0.73,0.46
c0.76,0.3,0.81,0.46,0.81,0.58c0,0.15-0.56,0.58-0.61,0.71c-0.05,0.13-0.13,0.3-0.13,1.11c0,0.61,0.1,0.94,0.81,0.94H320.81z
 M313.54,83.19c0,0.84-0.61,0.99-0.84,0.99c-0.4,0-0.48-0.15-1.24-1.57c-0.08-0.15-0.68-1.27-1.57-1.9
c-0.08-0.05-0.3-0.23-0.3-0.25c0-0.05,0.18-0.08,0.2-0.08c0.41,0,1.12,0.15,1.92,0.51C312.3,81.16,313.54,81.85,313.54,83.19z
 M314.33,88.45c-0.66,0-0.81,0.2-0.81,0.91c0,2.23,0,2.28,0.1,2.48c0.13,0.3,0.48,0.3,0.71,0.3h5.93c0.73,0,0.86-0.03,0.86-2.53
c0-0.86-0.02-1.16-0.81-1.16H314.33z M324.86,81.92c0,0.33-0.33,0.35-0.68,0.35c-0.4,0.03-0.4,0.03-1.87,0.86
c-0.86,0.48-1.8,0.86-2.02,0.86c-0.02,0-0.08,0-0.08-0.02c0-0.03,0.99-1.32,1.14-1.6c0.81-1.34,0.91-1.47,1.06-1.95
c0.13-0.35,0.15-0.43,0.3-0.43c0.3,0,0.86,0.48,1.11,0.68C324.3,81.11,324.86,81.59,324.86,81.92z"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "MainLogo"
}
</script>

<style scoped>

</style>