
/* eslint-disable @typescript-eslint/camelcase */
// @ts-nocheck
import {IonContent, IonPage, IonLabel, IonItem, alertController, IonButton, IonCheckbox, IonFooter, IonToolbar, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonListHeader, IonText } from "@ionic/vue";
import {trCard, trCommon, trSignIn, trCartForm} from '@/i18n/messages';
import Toolbar from "@/components/Toolbar.vue";
import { defineComponent } from 'vue'
import Card from "@/models/Card";
import { Stripe } from '@capacitor-community/stripe';
import ApiService from "@/services/api.service";
import {CartService} from "@/services/cart.service";
import {OrderService} from "@/services/order.service";
import {AuthService} from '@/services/auth.service';
import cardMixin from "@/mixins/cardMixin";
import StripeCardToken from "@/components/StripeCardToken.vue";

Stripe.initialize({
  publishableKey: process.env.VUE_APP_STRIPE_PKEY
})
export default defineComponent({
  name: "AddCard",
  mixins: [cardMixin],
  components: {
    StripeCardToken,
    Toolbar, IonContent, IonPage, IonLabel, IonItem, IonButton, IonCheckbox, IonFooter, IonToolbar, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonListHeader, IonText },
  data() {
    return {
      loading: false,
      card: new Card(),
      total:  CartService.total(),
      cartData: CartService.getCart(),
      order: OrderService.get(),
      saveCard: AuthService.isAuth(),
      errorText: this.$tr(trCommon, 'error'),
      currencies: null,
      $stripe: null,
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null
    }
  },
  async mounted() {
    await ApiService.get('converter?total=' + this.total).then((res) => {
      this.currencies = res.data.data
    });

    const interval = setInterval(() => {
      if (window.Stripe) {
        this.initCard()
        clearInterval(interval);
      }
    }, 50)
  },
  methods: {
    async appendCard() {
      this.loading = true
      // @ts-ignore
      const { token, error } = await this.$stripe.createToken(this.cardNumber);
      if (error) {
        this.loading = false
        return this.errorAlert('Invalid card number');
      }
      ApiService
          .post('stripe', {
            order: this.order,
            cart: CartService.getCart(),
            total: CartService.total(),
            token: token.id,
            saveCard: this.saveCard,
            promoCode: CartService.getPromoCode()
          })
          .then((res) => {
            CartService.clearCart()
            OrderService.clear()
            if(res.data.data && res.data.data.number) {
              window.location.href = '/order-success/' + res.data.data.number
            }
            this.loading = false
          })
          .catch(() => {
            this.loading = false
            this.errorAlert('Card pay error')
          })
    },
    async errorAlert(message: any) {
      const alert = await alertController.create({
        header: this.errorText,
        message: message,
        buttons: ['OK'],
      });
      await alert.present();
    }
  },
  setup() {
    return { Stripe, alertController, trCard, trSignIn, AuthService, trCartForm }
  },
  computed: {
    stripeElements () {
      return this.$stripe ? this.$stripe.elements() : null;
    },
  },
  beforeUnmount () {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
})
