
/* eslint-disable @typescript-eslint/camelcase */
// @ts-nocheck
import {IonContent, IonPage, IonLabel, IonItem, alertController, IonButton, IonCheckbox, IonFooter, IonToolbar, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonListHeader, IonText} from "@ionic/vue";
import {trCard, trCommon, trSignIn, trCartForm} from '@/i18n/messages';
import Toolbar from "@/components/Toolbar.vue";
import { defineComponent } from 'vue'
import Card from "@/models/Card";
import { Stripe } from '@capacitor-community/stripe';
import ApiService from "@/services/api.service";
import {AuthService} from '@/services/auth.service';
import {WalletService} from "@/services/wallet.service";
import StripeCardToken from "@/components/StripeCardToken.vue";
import cardMixin from "@/mixins/cardMixin";

Stripe.initialize({
  publishableKey: process.env.VUE_APP_STRIPE_PKEY
})

const STRIPE_PAY_METHOD = 30

export default defineComponent({
  name: "AddCardWallet",
  mixins: [cardMixin],
  components: {
    StripeCardToken,
    Toolbar, IonContent, IonPage, IonLabel, IonItem, IonButton, IonCheckbox, IonFooter, IonToolbar, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonListHeader, IonText},
  data() {
    return {
      loading: false,
      card: new Card(),
      wallet: WalletService.get(),
      saveCard: !!AuthService.isAuth(),
      errorText: this.$tr(trCommon, 'error'),
      currencies: null
    }
  },
  mounted() {
    const interval = setInterval(() => {
      if (window.Stripe) {
        this.initCard()
        clearInterval(interval);
      }
    }, 50)
  },
  methods: {
    async appendCard() {
      this.loading = true
      const { token, error } = await this.$stripe.createToken(this.cardNumber);
      if (error) {
        this.loading = false
        return this.errorAlert('Invalid card number');
      }
      ApiService
          .post('walletReplenishment', {
            wallet: this.wallet,
            token: token.id,
            saveCard: this.saveCard ? true : null,
            payMethod: STRIPE_PAY_METHOD
          })
          .then((res) => {
            if(res.data.data && res.data.data.amount) {
              WalletService.clear()
              window.location.href = '/wallet/success/' + res.data.data.id
            }
            this.loading = false
          })
          .catch(() => {
            this.loading = false
            this.errorAlert('Card pay error')
          })
    },
    async errorAlert(message: any) {
      const alert = await alertController.create({
        header: this.errorText,
        message: message,
        buttons: ['OK'],
      });
      await alert.present();
    }
  },
  setup() {
    return { Stripe, alertController, trCard, trSignIn, AuthService, trCartForm }
  }
})
