export default "<h2 class=\"title\">What is Summus Wallet for?</h2>\n" +
"<div class=\"item-block\">\n" +
"    <ion-text>\n" +
"        <p><b>This is the fastest method to pay for purchases made from our website.</b> You will no longer need to take out your bank card or try to remember your PayPal account password during a special shopping campaign.</p>\n" +
"        <p><b>You can top up the wallet using a bank transfer</b>, even if your bank card is not intended for online payments or is not accepted by the website payment processing system.</p>\n" +
"        <p><b>A special loyalty program</b> will be created for the Summus Wallet owners, offering a system of discounts and cosmetic products subscriptions, as well as special gifts with purchases.</p>\n" +
"        <p>If you wish, <b>you can add funds to another customer’s wallet.</b> To do so, you only need to know the email address used by that customer for registration on the website. You will be able to add a congratulations message to your gift. Also, you can add funds to another person’s wallet anonymously.</p>\n" +
"    </ion-text>\n" +
"</div>\n" +
"<h2 class=\"title\">How much money will be added to the wallet?</h2>\n" +
"<div class=\"item-block\">\n" +
"    <ion-text>\n" +
"        <p><b>The Summus Wallet currency is the Japanese yen.</b></p>\n" +
"        <p>The minimum wallet top-up amount is 1000 yen, the maximum top-up amount is 100000 yen.</p>\n" +
"        <p>When you top up your wallet using a bank card or your PayPal account, the amount you have entered in the “Amount” field will be loaded. Your wallet will be topped up almost immediately.</p>\n" +
"        <p>If you use a bank card or your PayPal account to add funds to the wallet, <b>a fee of 4% will be charged.</b> The fee will be retained by payment processing systems.</p>\n" +
"        <p><b>When you top up your wallet using a bank transfer, an amount in the Japanese yen calculated based on the exchange rate for the day the funds were credited to the company’s settlement account will be added to the wallet.</b> If you have chosen US dollars or euros as your bank transfer currency, the amount in the Japanese yen may be larger or smaller, than the amount requested, depending on the exchange rate.</p>\n" +
"        <p><b>All fees</b> (charged by the sender’s bank and by correspondent banks) are paid by the client. You can get the information on the fees in your bank.</p>\n" +
"        <p><b>The time your funds are credited to the company’s account</b> depends on banks involved in a funds transfer transaction. We have no influence on it.</p>\n" +
"    </ion-text>\n" +
"</div>\n" +
"<h2 class=\"title\">Can the funds in the wallet be sent back?</h2>\n" +
"<div class=\"item-block\">\n" +
"    <ion-text>\n" +
"        <p>Certain top-up transactions can be reimbursed, provided that the funds have not been spent.</p>\n" +
"        <p>If the funds were added to your wallet as a gift from another customer, they can be sent back only to the gifter’s account.</p>\n" +
"        <p>If you have used some part of your wallet funds, <b>the remainder can be returned to you upon your request made via the store customer support service.</b> The client pays the transaction fee, if the funds are reimbursed via a bank transfer. The company pays the transaction fee, if the funds are sent back to a bank card or a PayPal account.</p>\n" +
"    </ion-text>\n" +
"</div>";