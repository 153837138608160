<template>
  <span class="feed-footer">
    <template v-if="data.type.id !== POST_TYPES.POLL">
      <ion-icon v-if="data.type.id !== POST_TYPES.PRODUCT" @click="toPost" class="ion-float-right" tappable :icon="chevronForwardCircle"></ion-icon>
      <div tappable v-else @click="toPost" class="ion-float-right price-button text-center" :class="data.data.isDiscount ? 'discount' : (data.darkTheme ? 'white-theme' : null)" >
        <div v-if="data.data.isDiscount" class="real-price">
          ¥{{ data.data.realPrice }}
        </div>
        <div v-html="data.data.price"></div>
        <small>&nbsp;<span v-html="data.data.currenciesHTMLShort"></span></small>
      </div>
    </template>
    <ion-icon class="shared" :class="data.type.id === POST_TYPES.PRODUCT ? 'product-btn' : ''" @click="basicShare(data)" tappable :icon="shareOutline"></ion-icon>
    <ion-icon v-if="$isAuth" :class="data.type.id === POST_TYPES.PRODUCT ? 'product-btn' : ''" class="liked" @click="toggleLikeState(data)" tappable :icon="data.isLike ? heart : heartOutline"></ion-icon>
    <ion-icon v-if="isShared && (data.type.id === POST_TYPES.ACTION || data.type.id === POST_TYPES.ANNOUNCE)"  class="liked" @click="addEvent()" tappable :icon="calendarOutline"></ion-icon>
  </span>
</template>

<script>
import POST_TYPES from '@/components/timeline/PosTypes';
import {IonIcon} from '@ionic/vue';
import {heart, heartOutline, chevronForwardCircle, shareOutline, calendarOutline} from 'ionicons/icons';
import {Share} from '@capacitor/share'
import ApiService from "@/services/api.service";
import {TokenService} from "@/services/token.service";
import {useRouter} from "vue-router";
import { Calendar } from '@ionic-native/calendar';

export default {
  name: "TimelineLikeShare",
  props: {
    data: Object
  },
  components: {IonIcon},
  setup() {
    const router = useRouter()
    return {heartOutline, heart, shareOutline, chevronForwardCircle, POST_TYPES, router, calendar: Calendar, calendarOutline}
  },
  computed: {
    isShared() {
      return this.data && this.data.data && this.data.data.url;
    }
  },
  methods: {
    addEvent() {
      this.calendar.createCalendar('Shiawasedo').then( () => {
        const options = { calendarName: 'Shiawasedo', url: this.data.data.url, firstReminderMinutes: 15 };
        this.calendar.createEventInteractivelyWithOptions(this.data.title, null, this.data.lead, new Date(this.data.beginTime), new Date(this.data.endTime), options)
      })
    },
    toggleLikeState(data) {
      data.isLike = !data.isLike;
      ApiService.post('like', {
        id: data.id
      })
    },
    async basicShare(data) {
      if(data.data && data.data.url) {
        await Share.share({
          url: process.env.VUE_APP_WEBCLIENT_URL + data.id
        })
      } else {
        await Share.share({
          url: process.env.VUE_APP_WEBCLIENT_URL + data.id
        })
      }
    },
    toPost() {
      if(this.isShared) {
        return window.open(this.data.data.url + '?access_token=' + TokenService.getToken(), '_system', 'location=yes');
      }
      this.router.push(`/timeline/${this.data.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.feed-footer {
  ion-icon  {
    font-size: 22px;
    line-height: 1;
    padding: 8px 0;
    color: inherit;
  }
  .ion-float-right {
    font-size: 38px;
    padding: 0;
    &.price-button {
      border-radius: 24px;
      font-size: 14px;
      padding: 8px 10px;
      margin-top: 2px;
      background-color: #333;
      color: #fff;
      &.discount {
        background: #ba0c3f;
        color: #fff;
      }
      &.white-theme {
        background-color: #fff;
        color: #333;
      }
    }
  }
  .shared {
    margin: 0 8px -5px 0;
  }
  .liked {
    margin: 0 8px -7px 0;
  }
  .real-price {
    font-size: 80%;
    text-decoration: line-through;
  }
  .product-btn {
    position: absolute;
    bottom: 0;
    &.liked {
      left: 30px;
    }
  }
}
</style>
