import ApiService from "@/services/api.service";

const DEVICE_KEY = "device_info";

const DeviceService = {
	get(): string {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return <string>localStorage.getItem(DEVICE_KEY)
	},
	set(device: string) {
		localStorage.setItem(DEVICE_KEY, device)
		ApiService.setHeader();
	}
};

export { DeviceService };
