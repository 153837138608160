const MetaTags = {
    setTag(type: string, content: null|string = null) {
        switch (type) {
            case 'url':
                content = document.location + ''
                break;
            case 'title':
                content = document.title
                break;
        }

        let link: any = document.getElementById('og-' + type);
        if(!link) {
            link = document.createElement('meta');
        }
        link.setAttribute('property', 'og:' + type);
        link.setAttribute('id', 'og-' + type);
        link.content = content;
        document.getElementsByTagName('head')[0].appendChild(link);
    }
}

export { MetaTags};