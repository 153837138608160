import Cart from "@/models/Cart";
import PromoCode from "@/models/PromoCode";
const CART_KEY = 'cart';
const PROMO_CODE_KEY = 'promo_code';

const CartService = {
	addTo(cart: Cart) {
		const cartItems = this.getCart();
		let isExist = false
		cartItems.forEach((existCart: Cart) => {
			if(existCart.product.id == cart.product.id) {
				existCart.count++
				isExist = true
			}
		})
		if(!isExist) {
			cartItems.push(cart)
		}
		return this.setCart(cartItems)
	},
	setPromoCode(code: any) {
		return localStorage.setItem(PROMO_CODE_KEY, code);
	},
	getPromoCode() {
		return localStorage.getItem(PROMO_CODE_KEY);
	},
	totalDiff() {
		console.log(this.totalReal())
		console.log(this.total())
		return this.totalReal() - this.total()
	},
	totalReal() {
		const cartItems = this.getCart();
		let price = 0
		cartItems.forEach((item: any) => {
			console.log(item.product.realPrice)
			price += item.product.realPrice * item.count
		})
		return price
	},
	total() {
		const cartItems = this.getCart();
		let price = 0
		cartItems.forEach((item: any) => {
			console.log(item.product.priceNumber)
			price += item.product.priceNumber * item.count
		})
		return price
	},
	deleteFrom(productId: any) {
		const cartItems = this.getCart();
		const newCart = cartItems.map((existCart: Cart) => {
			if(existCart.product.id == productId) {
				existCart.count--
			}
			return existCart;
		})
		return this.setCart(newCart.filter((cartItem: any) => cartItem.count > 0))
	},
	getCount(){
		const cartItems = this.getCart();
		let count = 0;
		cartItems.forEach((item: Cart) => {
			count += item.count
		})
		return count
	},
	clearCart() {
		localStorage.removeItem(PROMO_CODE_KEY);
		return localStorage.removeItem(CART_KEY)
	},
	getCart() {
		const cartItems = localStorage.getItem(CART_KEY);
		if(cartItems) {
			return JSON.parse(cartItems)
		}
		return [];
	},
	applyPromoCode(promo: PromoCode) {
		console.log(promo)
	},
	setCart(items: any) {
		return localStorage.setItem(CART_KEY, JSON.stringify(items.filter((item: Cart) => {return item.count > 0})))
	},
	isExist(productId: number) {
		const cartItems = this.getCart();
		return cartItems.find((item: Cart) => item.product.id === productId)
	},
	inCartCount(productId: number) {
		const cartItems = this.getCart();
		return !this.isExist(productId) ? cartItems.find((item: Cart) => item.product.id == productId).count : 0
	}
};

export { CartService };
