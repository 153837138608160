
const trSignIn = {
	ru: {
		username: 'Email',
		password: 'Пароль',
		social: 'Войти с Facebook',
		socialVk: 'Войти с ВКонтакте',
		socialMr: 'Войти с Mail.ru',
		socialGoogle: 'Войти с Google',
		socialOk: 'Войти с Одноклассники',
		apple: 'Войти с Apple',
		enter: 'Вход',
		continue: 'Пожалуйста, войдите, чтобы продолжить',
		loading: 'Загрузка...',
		newUser: 'Ещё не зарегистрированы?',
		signUp: 'Создать аккаунт',
		createAccount: 'Создать аккаунт',
		conditions: 'Создавая аккаунт, вы принимаете <a href="https://www.shiawasedo.co.jp/terms-of-purchase">положения и условия покупок</a> в магазине Shiawasedo.',
		confirmCode: 'Введите код подтверждения, отправленный на email:',
		sendCode: 'Отправить',
		enterCode: 'Введите код',
		cancelCode: 'Отмена',
		resendCode: 'Запросить код ещё раз',
		codeExpired: 'Код просрочен, запросите его ещё раз:',
		forgot: 'Создать новый пароль',
		forgotText: 'Введите email, на который зарегистрирован ваш аккаунт Shiawasedo, и нажмите кнопку «Продолжить».<br/>На этот email будет отправлена ссылка для создания нового пароля.',
		forgotSendText: 'Для восстановления пароля введите код из письма, которое мы отправили на <b>%email%</b> и новый пароль в форму ниже.',
		errorHeader: 'Ошибка!',
		errorSubHeader: 'Некорректный email или пароль. Пожалуйста, попробуйте ещё раз.',
	},
	en: {
		username: 'Email',
		password: 'Password',
		social: 'Sign in with Facebook',
		socialVk: 'Sign in with Vkontakte',
		socialMr: 'Sign in with Mail.ru',
		socialGoogle: 'Sign in with Google',
		socialOk: 'Sign in with Odnoklassniki',
		apple: 'Sign in with Apple',
		enter: 'Sign in',
		continue: 'Please sign in to continue',
		loading: 'Loading...',
		newUser: "Don't Have an Account?",
		signUp: 'Create a new account',
		createAccount: 'Create a new account',
		conditions: 'By creating an account, you agree to our <a href="https://www.shiawasedo.co.jp/terms-of-purchase">Terms and Conditions of Purchase</a>.',
		confirmCode: 'Enter the verification code that has just been sent to your email address:',
		sendCode: 'Send',
		enterCode: 'Enter the code',
		cancelCode: 'Cancel',
		resendCode: 'Request code again',
		codeExpired: 'Code is expired, request code again:',
		forgot: 'Create your new password',
		forgotText: 'Enter an email address associated with your Shiawasedo account, then click the Continue button.<br/>We will send a link to create your new password to this email address.',
		forgotSendText: 'To recover your password, enter the code from the email, that we have just sent to <b>%email%</b>, and a new password in following form:',
		errorHeader: 'Error',
		errorSubHeader: 'The email address or password is incorrect. Please try again.'
	},
	ja: {
		username: 'メールアドレス',
		password: 'パスワード',
		social: 'Facebookアカウントを使ってログイン',
		socialVk: 'VKアカウントを使ってログイン',
		socialMr: 'Mail.ruアカウントを使ってログイン',
		socialGoogle: 'Googleアカウントを使ってログイン',
		socialOk: 'OKアカウントを使ってログイン',
		apple: 'Appleアカウントを使ってログイン',
		enter: 'ログイン',
		continue: 'ご利用を続けるにはログインしてください',
		loading: 'ロード中',
		newUser: 'Shiawasedoアカウントはまだお持ちでないですか?',
		signUp: 'アカウントの新規作成',
		createAccount: 'アカウントの新規作成',
		conditions: 'お客様は、当店の <a href=“https://www.shiawasedo.co.jp/terms-of-purchase”>購入規約を承諾した上でアカウントを登録されたものとみなされます</a>',
		confirmCode: 'お客様のメールアドレス宛に送信された認証コードを入力してください：',
		sendCode: '送信する',
		enterCode: '認証コードを入力してください',
		cancelCode: 'キャンセル',
		resendCode: '認証コードを再度リクエストする',
		codeExpired: '認証コードの有効期限が切れています。認証コードを再度リクエストしてください：',
		forgot: '新しいパスワードを作成してください',
		forgotText: 'しあわせ堂のアカウントに登録されているメールアドレスを入力して、「続ける」ボタンをクリックしてください。<br/>入力されたメールアドレス宛に新しいパスワードを作成するためのリンクが送信されます。',
		forgotSendText: 'パスワードを回復するには、下記フォームに<b>%email%</b>宛に送信されたメールに記載された認証コードと新しいパスワードを入力してください:',
		errorHeader: 'エラー',
		errorSubHeader: 'メールアドレスまたはパスワードが正しくありません。再度お試しください。'
	}
}
const trTabs = {
	ru: {
		timeline: 'Лента',
		shop: 'Магазин',
		calendar: 'Календарь'
	},
	en: {
		timeline: 'Timeline',
		shop: 'Shop',
		calendar: 'Calendar'
	},
	ja: {
		timeline: 'タイムライン',
		shop: 'オンラインストア',
		calendar: 'カレンダ'
	}
}
const trToolbar = {
	ru: {
		all: 'Все',
		favorites: 'Избранное',
		empty: 'Избранного нет',
		beautyCal: 'Календарь ухода за кожей',
		scheduleCal: 'Расписание ухода за кожей',
	},
	en: {
		all: 'All',
		favorites: 'Favorites',
		empty: 'No favorites',
		beautyCal: 'Skincare calendar',
		scheduleCal: 'Skincare schedules',
	},
	ja: {
		all: '全て',
		favorites: 'お気に入り',
		empty: 'お気に入りに登録された商品はありません',
		beautyCal: 'スキンケアカレンダ',
		scheduleCal: 'Skincare schedules',
	}
}
const trOffer = {
	ru: {
		privateOffers: 'Индивидуальные предложения',
		privateOffersEmpty: 'Индивидуальных предложений нет',
		validUntil: 'Действует до'
	},
	en: {
		privateOffers: 'Private offers',
		privateOffersEmpty: 'Private offers is empty',
		validUntil: 'Valid until'
	},
	ja: {
		privateOffers: 'Private offers',
		privateOffersEmpty: 'Private offers is empty',
		validUntil: 'Valid until'
	}
}
const trMenu = {
	ru: {
		profile: 'Профиль',
		chooseLang: 'Выберите язык',
		exit: 'Выйти',
		backButton: ' Назад',
		scan: 'Сканировать штрихкод',
		payMethods: 'Способы оплаты',
		orders: 'Мои покупки',
		bottom: 'Разделы «<b>Мои подписки</b>», «<b>Мои кнопки красоты</b>» и «<b>Персональные предложения</b>» временно доступны только в личном кабинете на сайте',
		settings: 'Настройки',
		version: 'Версия',
		account: 'Аккаунт и безопасность',
	},
	en: {
		profile: 'Profile',
		chooseLang: 'Choose language',
		exit: 'Log out',
		backButton: 'Back',
		scan: 'Scan barcode',
		payMethods: 'Payment method',
		orders: 'My orders',
		bottom: '“<b>My Subscriptions</b>”, “<b>My Beauty Buttons</b>” and “<b>My Personal Offers</b>” sections are temporarily available only on your personal profile page.',
		version: 'Version',
		settings: 'Settings',
		account: 'Account and security',
	},
	ja: {
		profile: 'プロフィール',
		chooseLang: '言語を選択してください',
		exit: 'ログアウト',
		backButton: '戻る',
		scan: 'バーコードを読み取る',
		payMethods: 'お支払い方法',
		orders: '私の注文履歴',
		bottom: '「<b>私の定期購入</b>」「<b>私のビューティボタン</b>」と「<b>私限定オファー</b>」の各項目は一時的にマイページからのみ利用できます',
		version: 'Version',
		settings: '設定',
		account: 'アカウントとセキュリティ',
	}
}
const trProduct = {
	ru: {
		unavailable: 'Нет в наличии',
		pending: 'Ожидает поступления',
		action: 'Акция!',
		effect: 'Эффект',
		details: 'Детали',
		ingredients: 'Ингредиенты',
		instructions: 'Инструкции',
		end: 'Скоро<br/>закончится',
		endPlain: 'Скоро закончится',
		notForJapan: 'Только для продажи за пределами Японии',
		promoOffer: 'Товар по промо цене может быть куплен только в заказе с другим товаром'
	},
	en: {
		unavailable: 'Out of stock',
		pending: 'On backorder',
		action: 'Campaign!',
		effect: 'Effect',
		details: 'Details',
		ingredients: 'Ingredients',
		instructions: 'Instructions',
		end: 'Will<br/>end soon',
		endPlain: 'Will end soon',
		notForJapan: 'For sale outside Japan only',
		promoOffer: 'A promotional price product can only be purchased in addition to another product'
	},
	ja: {
		unavailable: '在庫切れ',
		pending: '入荷待ち',
		action: 'キャンペーン！',
		effect: '効果',
		details: '詳細',
		ingredients: '成分',
		instructions: 'ご使用方法',
		end: '間も無く<br/>終了',
		endPlain: '間も無く終了',
		notForJapan: '海外専売品',
		promoOffer: 'A promotional price product can only be purchased in addition to another product'
	}
}
const trOrder = {
	ru: {
		open: 'Открыть',
		gift: 'Подарок от Shiawasedo',
		empty: 'Покупок нет',
		total: 'Итоговая стоимость заказа',
		subscriptionSend: 'Отправка подписки %groupName%',
		surprise: 'Основной состав подписки является сюрпризом, поэтому он скрыт.',
		orderHide: 'Основной состав заказа является сюрпризом, поэтому он скрыт.',
	},
	en: {
		open: 'Open',
		gift: 'Gift from Shiawasedo',
		empty: 'No orders',
		total: 'Total price',
		subscriptionSend: 'The %groupName% subscription shipment',
		surprise: 'The main selection of items in the subscription box is not displayed, as it is a secret.',
		orderHide: 'The main selection of items in the order is not displayed, as it is a secret.',
	},
	ja: {
		open: 'ご注文内容を確認する',
		gift: 'しあわせ堂からのギフト',
		empty: '購入履歴がありません。',
		total: '合計',
		subscriptionSend: '%groupName%サブスクリプション注文',
		surprise: 'このサブスクリプションのためにセレクトされた主な商品は、お客様には予め分からないようになっているので、非表示にされております。',
		orderHide: 'この注文のためにセレクトされた主な商品は、お客様には予め分からないようになっているので、非表示にされております。',
	}
}
const trProfile = {
	ru: {
		profile: 'Профиль',
		save: 'Сохранить',
		delete: 'Удалить аккаунт',
		deleteAccount: 'Удаление аккаунта',
		firstname: 'Имя',
		lastname: 'Фамилия',
		middlename: 'Отчество',
		birthday: 'День рождения',
		birthdayCheck: 'Я хочу получать специальные предложения и поздравления в честь дня рождения',
		city: 'Город',
		cityPlaceholder: 'Введите название города',
		cityCheck: 'Я хочу получать специальные предложения, основанные на моём городе',
		confirmHeader: 'Подтвердить',
		confirmMessage: 'Вы действительно хотите удалить аккаунт?',
		inputEmail: 'Введите основной email личного кабинета (указан наверху страницы раздела Профиль), чтобы подтвердить удаление:',
		deleteText: '<p>Вы можете удалить свой аккаунт</p>' +
			'<p>Ваши имя, email, история заказов и другие данные личного кабинета больше не будут доступны на сайте shiawasedo.co.jp, в приложении Summus Beauty Lab для iOS и Summus Beauty Lab для Android.</p>' +
			'<p>Вы сможете восстановить свой аккаунт в течение 30 дней, если он был удалён случайно или по ошибке. Чтобы восстановить аккаунт, обратитесь в службу поддержки.</p>' +
			'<p><b>Ваши подписки на косметику и кнопки красоты не будут автоматически отменены при удалении аккаунта.</b> Если вы хотите отказаться от них, сначала отмените подписки и деактивируйте кнопки, а затем удалите аккаунт. </p>' +
			'<p><b>Ваша подписка на рассылку новостей от Shiawasedo не будет отменена автоматически при удалении аккаунта.</b> Вы можете отменить подписку на рассылку новостей из любого письма с анонсом акции или обратившись в поддержку магазина. </p>' +
			'<p><b>Если у вас есть заказы, которые готовятся к отправке</b>, изменения их статусов продолжат приходить на указанный в заказе email. Уведомления в Summus Beauty Lab приходить не будут. </p>' +
			'<p><b>Остаток средств из Summus Кошелька</b> не будет списан автоматически после удаления аккаунта. Чтобы распорядиться остатком, сначала сделайте заказ или запросите возврат, если он доступен (зависит от суммы в кошельке), или переведите средства в Summus Кошелёк другого клиента. </p>' +
			'<p>Чтобы использовать свой основной email для создания нового аккаунта на сайте shiawasedo.co.jp или в приложении Summus Beauty Lab для iOS и Summus Beauty Lab для Android, измените его перед тем, как удалять аккаунт. Вы также не сможете использовать дополнительные email личного кабинета для создания нового аккаунта, если предварительно не удалите их.</p>',
	},
	en: {
		profile: 'Profile',
		save: 'Save',
		delete: 'Delete an account',
		deleteAccount: 'Deleting your Account',
		firstname: 'First name',
		lastname: 'Last name',
		middlename: 'Middle name',
		birthday: 'Birthday',
		birthdayCheck: "I'd like to receive special offers and congratulations prior to my birthday",
		city: 'City',
		cityPlaceholder: 'Enter your city',
		cityCheck: "I'd like to receive special offers relevant to my city or location",
		confirmHeader: 'Confirm',
		confirmMessage: 'Are you sure you want to delete the account?',
		inputEmail: 'Please, type the main email address associated with your personal profile (shown on the top of your Profile page) to confirm deletion:',
		deleteText: '<p>You can delete your account.</p>' +
			'<p>Your name, email address, order history will no longer be available on the shiawasedo.co.jp web-site, in the Summus Beauty Lab application for iOS and in Summus Beauty Lab for Android.</p>' +
			'<p>You can restore your account within 30 days, if you deleted it accidentally or by mistake. To re-store your account, please contact our Support services team.</p>' +
			'<p><b>Your beauty products subscriptions and beauty buttons will not be cancelled automatically, if you delete your account.</b> If you want to cancel them, please cancel subscriptions and de-activate beauty buttons first and then delete the account.</p>' +
			'<p><b>Your Shiawasedo news subscription will not be cancelled automatically, when you delete your account.</b> You can cancel the news subscription from any special campaign news email you receive or by contacting the store support services team.</p>' +
			'<p><b>If you have orders that are being prepared for shipment</b>, you will continue to receive notifica-tions on their status updates to the email you have provided at the time of order placement. You will not receive notifications in Summus Beauty Lab.</p>' +
			'<p><b>The remaining funds in your Summus Wallet</b> will not be withdrawn automatically after you de-lete your account. To make use of the remaining funds, please first place an order or request a refund, if it is available (depends on the amount of funds remaining in your wallet) or transfer the funds to another client’s Summus Wallet.</p>' +
			'<p>To use your main email address to register a new account on the shiawasedo.co.jp website or in the Summus Beauty Lab application for iOS and Summus Beauty Lab for Android, please change it before deleting your account. You also won’t be able to use secondary emails associated with your personal profile to register a new account, if you do not delete them beforehand.</p>',
	},
	ja: {
		profile: 'プロフィール',
		save: '保存する',
		delete: 'アカウントを削除する',
		deleteAccount: 'アカウントを削除する',
		firstname: '名',
		lastname: '姓',
		middlename: '父称',
		birthday: '生年月日',
		birthdayCheck: '誕生日のお祝いメッセージと誕生日限定特典を希望します',
		city: '市',
		cityPlaceholder: '都市名を入力してください',
		cityCheck: '都市限定特典や所在地限定特典を希望します',
		confirmHeader: '確認する',
		confirmMessage: 'アカウントを削除してもよろしいですか？',
		inputEmail: '削除を確認するには、お客様のアカウントに関連付けられた主要メールアドレス（プロフィールページの上部に表示されるもの）を入力してください：',
		deleteText: '<p>お持ちのアカウントを削除することができます。</p>' +
			'<p>お客様のお名前、メールアドレス、注文履歴その他個人アカウント情報は、shiawasedo.co.jpのホームページ、iOS版のSummus Beauty Labアプリケーション、そしてAndroid版のSummus Beauty Labアプリケーションでは、表示されなくなります。</p>' +
			'<p>誤ってまたは偶発的にアカウントを削除してしまった場合、30日以内であれば、アカウントを復元することができます。アカウントを復元するには、当店のサポートサービスまでお問い合わせください。</p>' +
			'<p>アカウントが削除されても、化粧品のサブスクリプション及びビューティーボタンは自動的に解約となりません。サブスクリプション及びビューティーボタンを解約する場合は、アカウントを削除する前にサブスクリプションを解約し、ビューティーボタンを無効にしてください。 </p>' +
			'<p>アカウントが削除されても、「しあわせ堂」メールマガジンの配信は自動的に停止されません。メールマガジンの配信は、キャンペーンの案内メールから停止していただくか（どの案内メールからでも可）、または当店のサポートサービスまでお問い合わせください。</p>' +
			'<p>出荷準備中のご注文がある場合、そのステータス変更の通知は、注文時に指定されたメールアドレス宛に引き続き送信されます。Summus Beauty Lab宛は送信されません。</p>' +
			'<p>アカウントが削除されても、Summus Walletの残高が自動で引き落とされることはありません。残っている資金を処分するには、まずご注文をいただき、または、可能であれば、返金を依頼していただくか、他のお客様の<b>Summus Wallet</b>宛に送金してください。（返金の可否はウォレットの残高によって決まります。）</p>' +
			'<p>shiawasedo.co.jpのホームページや、iOS版の Summus Beauty Labアプリ、Android版のSummus Beauty Labアプリで新規アカウントを作成する際に主要メールアドレスが使用できるようにするには、アカウントを削除する前に主要メールアドレスを変更してください。また、個人アカウントに関連付けられた追加のメールアドレスを事前に削除しておかなければ、その追加のメールアドレスを使って新規アカウントを登録することはできなくなります。</p>',
	}
}
const trCartForm = {
	ru: {
		wallet: 'Кошелëк',
		walletLose: 'Недостаточно средств (баланс',
		process: 'Перейти к подтверждению',
		postalCode: 'Индекс',
		country: 'Страна',
		state: 'Область/край',
		city: 'Город',
		street: 'Улица',
		house: 'Дом',
		housing: 'Корпус',
		building: 'Строение',
		apartment: 'Квартира',
		addressee: 'Получатель',
		address: 'Адрес',
		addAddress: 'Добавить адрес',
		otherAddress: 'Другой адрес',
		shippingAddress: 'Адрес доставки',
		addressSave: 'Запомнить этот адрес',
		buy: 'Оформление заказа',
		confirm: 'Подтверждение заказа',
		toPay: 'Перейти к оплате',
		pay: 'Оплатить',
		phone: 'Телефон',
		items: 'Ваш заказ',
		loggedInAs: 'Вы вошли в приложение как',
		firstOrder: 'Это мой первый заказ',
		firstOrderHint: 'Отметьте флажок, если вы оформляете заказ в нашем магазине впервые',
		email: 'Email для уведомлений о покупке',
		chooseCountry: 'Выберите страну',
		setUp: 'Настроить',
		confirmPay: 'Я принимаю <a href="https://www.shiawasedo.co.jp/terms-of-purchase" target="_blank">условия покупки</a>',
		confirmWalletReplenishment: 'Я принимаю <a href="https://www.shiawasedo.co.jp/terms-of-purchase" target="_blank">условия оплаты</a>',
		comment: 'Добавить комментарий к заказу',
		postcard: 'Добавить открытку',
		notFoundPromo: 'Промокод не найден',
		chooseCurrency: 'Выберите валюту платежа',
		totalAmount: 'К оплате:',
	},
	en: {
		wallet: 'Wallet',
		walletLose: 'There are no enough funds for payment (balance',
		process: 'Proceed to payment',
		postalCode: 'Zip',
		country: 'Country',
		state: 'State',
		city: 'City',
		street: 'Street',
		house: 'House',
		housing: 'Housing',
		building: 'Building',
		apartment: 'Apartment',
		addressee: 'Addressee',
		address: 'Address',
		addAddress: 'Add address',
		otherAddress: 'Another address',
		shippingAddress: 'Shipping address',
		addressSave: 'Save this address',
		buy: 'Ordering',
		confirm: 'Confirm',
		toPay: 'Proceed to payment',
		pay: 'Pay',
		phone: 'Phone',
		items: 'Your order',
		loggedInAs: 'You are logged in as',
		firstOrder: 'This is my first order in this store',
		firstOrderHint: 'Check this flag if you are making order here at the first time',
		email: 'Email for notifications about parcel',
		chooseCountry: 'Choose country',
		setUp: 'Set up',
		confirmPay: 'I accept <a href="https://www.shiawasedo.co.jp/terms-of-purchase" target="_blank">terms of purchase</a>',
		confirmWalletReplenishment: 'I accept <a href="https://www.shiawasedo.co.jp/terms-of-purchase" target="_blank">terms of purchase</a>',
		comment: 'Add a comment to this order',
		postcard: 'Add a postcard',
		notFoundPromo: 'Promo-code not found',
		chooseCurrency: 'Select payment currency',
		totalAmount: 'Total amount:',
	},
	ja: {
		wallet: 'Wallet',
		walletLose: '残高が不足しています（残高',
		process: '購入手続きへ進む',
		postalCode: '郵便番号',
		country: '国',
		state: '都道府県名',
		city: '区市町村名',
		street: '町',
		house: '番地',
		housing: '番館',
		building: '号館',
		apartment: '号室',
		addressee: '受取人',
		address: '住所',
		addAddress: '別のお届け先',
		otherAddress: '別の送付先',
		shippingAddress: 'お届け先',
		addressSave: 'この住所を保存する',
		buy: '購入手続き',
		confirm: '注文確定',
		toPay: 'お支払い手続きへ進む',
		pay: '支払う',
		phone: '電話番号',
		items: 'ご注文内容',
		loggedInAs: 'としてログイン済み',
		firstOrder: '当店の利用が初めてです',
		firstOrderHint: '当店にて初めて注文される場合、このチェックボックスを選択してください',
		email: 'お荷物の通知が届くメールアドレス',
		chooseCountry: '国を選択してください',
		setUp: '設定する',
		confirmPay: '購入規約を<a href="https://www.shiawasedo.co.jp/terms-of-purchase" target=“_blank">承諾します</a>',
		confirmWalletReplenishment: 'I accept <a href="https://www.shiawasedo.co.jp/terms-of-purchase" target="_blank">terms of purchase</a>',
		comment: 'ご注文にコメントを追加する',
		postcard: 'メッセージカードを追加する',
		notFoundPromo: 'プロモーションコードが見つかりません',
		chooseCurrency: 'お支払い通貨を選択してください',
		totalAmount: '小計',
	}
}
const trCart = {
	ru: {
		cart: 'Корзина',
		toCart: 'В корзину',
		buyNow: 'Перейти к оформлению',
		total: 'Итог',
		economy: 'Вы экономите ¥%discount%',
		notFoundPromo: 'Промокод не найден',
		empty: 'В корзине пусто',
		emptyLink: 'Начните покупки',
		notCanPay: 'Этот товар больше не продаётся. Удалите его для продолжения оформления заказа.',
		promo: 'Если у вас есть промокод, вставьте и примените его:',
		apply: 'Применить',
		promoCode: 'Промокод',
		isNoPromo: 'Скидка по промокоду на данный товар не распространяется'
	},
	en: {
		cart: 'Cart',
		toCart: 'To cart',
		buyNow: 'Proceed with you order',
		total: 'Total',
		economy: 'You save ¥%discount%',
		notFoundPromo: 'Promo-code not found',
		empty: 'Your cart is empty',
		emptyLink: 'Start shopping now',
		notCanPay: 'This product is no longer sold. Please delete it to proceed with your order.',
		promo: 'If you have a promo code, please, enter it to apply the offer to your order:',
		apply: 'Apply',
		promoCode: 'Promo code',
		isNoPromo: 'Promo code can not be applied to this product'
	},
	ja: {
		cart: 'カート',
		toCart: 'カートに入れる',
		buyNow: '注文処理手続きへ進む',
		total: '合計',
		economy: '¥%discount%お得です',
		notFoundPromo: 'プロモーションコードが見つかりません',
		empty: 'お客様のカートには商品がありません',
		emptyLink: '買い物を始める',
		notCanPay: 'この商品は廃盤となりました。ご注文手続きを続けるにはこの商品を削除してください。',
		promo: 'プロモーションコードをお持ちの場合は、入力していただくと、割引が適用されます',
		apply: '適用する',
		promoCode: 'プロモーションコード',
		isNoPromo: 'この商品はプロモーションコードの対象外になります'
	}
}
const trCard = {
	ru: {
		otherPay: 'Другой адрес доставки',
		otherPayAndAddress: 'Указать другой адрес или способ оплаты',
		payMethod: 'Адрес доставки и способ оплаты',
		otherPayText: '<b>Внимание!</b> В целях безопасности при изменении адреса вам придётся заново ввести данные карты оплаты, даже если вы не планировали её изменять.',
		page: 'Оплата кредитной картой',
		save: 'Использовать эту карту для быстрой оплаты заказов на сайте. Способ оплаты можно менять при желании.',
		pay: 'Оплатить',
		cardTitle: 'Введите данные карты',
		cardNumber: 'Номер карты',
		cardYearExp: 'Год',
		cardMonthExp: 'Месяц',
		cardExpires: 'Истекает',
		cardCvc: 'CVC-код',
		orderTitle: 'Благодарим за заказ!',
		orderSuccess: 'Ваш заказ успешно оформлен.',
		orderNumber: 'Номер заказа %orderNumber%.',
		orderText: '<p>На указанный вами электронный адрес отправлено письмо с подтверждением заказа.</p><p style="color: #de0071;">Если в течение пяти минут вы не нашли письма, проверьте папку «спам».</p><p>При обнаружении наших писем в папке спам, пожалуйста, отметьте их как «не спам» или добавьте наш электронный адрес в свою адресную книгу.</p>'
	},
	en: {
		otherPay: 'Another shipping address',
		otherPayAndAddress: 'Set another shipping address or payment method',
		otherPayText: '<b>Attention!</b> For security reasons, when your shipping address is changed, you will be required to re-enter your credit card information even if you do not plan to change the card.',
		payMethod: 'Shipping address and payment method',
		page: 'Credit Card Payment',
		save: 'Use this card to quickly pay for your online orders. You can change the payment method any time you want.',
		pay: 'Pay',
		cardTitle: 'Please enter your card details',
		cardNumber: 'Card number',
		cardYearExp: 'Year',
		cardMonthExp: 'Month',
		cardExpires: 'Expires',
		cardCvc: 'CVC',
		orderTitle: 'Thank you!',
		orderSuccess: 'Your order has been placed successfuly.',
		orderNumber: 'Order number is %orderNumber%.',
		orderText: '<p>We sent an order confirmation to the email address you specified.</p><p style="color: #de0071;">If you don\'t see the email within five minutes, check your Spam of Junk folders.</p><p>If you ever find our messages in your spam or junk folder, make sure to mark them as not spam or add our email to your contacts.</p>'
	},
	ja: {
		otherPay: '別のお届け先',
		otherPayAndAddress: '別のお届け先またはお支払い方法を指定する',
		otherPayText: '<b>ご注意ください！</b>セキュリティ上の理由から、住所を変更される場合、お支払いにご利用のクレジットカードの変更をお考えでなくとも、そのカードの情報を再入力する必要があります。',
		payMethod: 'お届け先またはお支払い方法',
		page: 'クレジットカード払い',
		save: 'オンラインストアのご注文のお支払いを迅速に行うにはこのカードをご利用ください。お支払い方法はいつでも変更できます。',
		pay: '支払う',
		cardTitle: 'クレジットカードの情報を入力してください',
		cardNumber: 'クレジットカード番号',
		cardYearExp: '年',
		cardMonthExp: '月',
		cardExpires: 'Expires',
		cardCvc: 'CVC',
		orderTitle: 'ご注文ありがとうございます。',
		orderSuccess: 'ご注文が完了しました。',
		orderNumber: 'ご注文番号 %orderNumber%',
		orderText: '<p>ご指定のメールアドレスにご注文確認メールを送信しました。</p><p style="color: #de0071;">もしご注文後５分以内に確認のメールが届かなかった場合、迷惑メールなどのフォルダーをご確認ください。</p><p>万が一ご注文確認メールなどは迷惑メールのフォルダーに入っていた際、迷惑メールのチェックを外して受信箱に移動させてください。</p>'
	}
}

const trPoll = {
	ru: {
		isFinished: 'Голосование завершено'
	},
	en: {
		isFinished: 'The poll is closed'
	},
	ja: {
		isFinished: '投票受付終了'
	},
}

const trCommon = {
	ru: {
		error: 'Ошибка',
		continue: 'Продолжить',
		newPassword: 'Новый пароль',
		create: 'Создать',
		save: 'Сохранить',
		createSchedule: 'Начать сегодня',
		edit: 'Изменить',
		restartSchedule: 'Начать с начала',
		weekDaysShort: ["Вс", "Пн","Вт","Ср","Чт","Пт","Сб"],
		delete: 'Удалить',
		calendarToast: 'Чтобы использовать все возможности календаря, <a style="color: #fff" href="/login">пожалуйста, войдите</a>',
		done: 'Завершено'
	},
	en: {
		error: 'Error!',
		continue: 'Continue',
		newPassword: 'New password',
		create: 'Create',
		save: 'Save',
		weekDaysShort: ["Su", "M","Tu","W","Th","F","S"],
		delete: 'Delete',
		createSchedule: 'Create now',
		edit: 'Edit',
		restartSchedule: 'Restart',
		calendarToast: 'To use all calendar features, <a style="color: #fff" href="/login">please, sign in</a>',
		done: 'Done'
	},
	ja: {
		error: 'エラー!',
		continue: '続ける',
		newPassword: '新しいパスワード',
		create: '作成する',
		save: 'Save',
		weekDaysShort: ["月","火","水","木","金","土", "日"],
		delete: '削除する',
		createSchedule: 'Create now',
		edit: 'Edit',
		restartSchedule: 'Restart',
		calendarToast: 'カレンダーの機能を全て利用するには<a style="color: #fff" href="/login">サインインしてください</a>',
		done: 'Done'
	},
}

const trSettings = {
	ru: {
		title: 'Уведомления календаря',
		indieActions: 'Уведомления об акциях',
		system: 'Уведомления о событиях',
		schedules: 'Уведомления по расписанию ухода за кожей',
	},
	en: {
		title: 'Calendar notifications',
		indieActions: 'Limited-time offer notifications',
		system: 'Event notifications',
		schedules: 'Skincare plan notifications'
	},
	ja: {
		title: 'カレンダーの通知',
		indieActions: 'キャンペーンの通知',
		system: 'イベントの通知',
		schedules: 'スキンケアプランの通知'
	}
}

const trCalendar = {
	ru: {
		eventDate: 'Дата',
		eventTime: 'Время',
		week: 'Неделя',
		empty: 'Ничего не запланировано',
		addEvent: 'Добавить стикер ухода за кожей',
		addSchedule: 'Добавить расписание ухода за кожей',
		confirmHeader: 'Подтвердить',
		confirmMessage: 'Вы действительно хотите удалить событие?',
		confirmYes: 'Да',
		stickers: 'Стикеры',
		schedules: 'Расписание ухода за кожей',
		adSchedule: 'Добавить расписание ухода за кожей',
		confirmCreate: 'Вы действительно хотите начать расписание?',
		confirmRestart: 'Для создания нового расписания все ненаступившие события старого расписания будут удалены. Продолжить?',
	},
	en: {
		eventDate: 'Date',
		eventTime: 'Time',
		week: 'Week',
		empty: 'Nothing scheduled',
		addEvent: 'Add a skincare sticker',
		addSchedule: 'Add a skincare schedule',
		confirmHeader: 'Confirm',
		confirmMessage: 'Are you sure you want to delete the event?',
		confirmYes: 'Yes',
		stickers: 'Stickers',
		schedules: 'Skincare plan',
		adSchedule: 'Add skincare plan',
		confirmCreate: 'Do you really want to start the schedule?',
		confirmRestart: 'For starting new schedule all non-occurring events will be deleted. Proceed?',
	},
	ja: {
		eventDate: '日付',
		eventTime: '時間',
		week: '週',
		empty: '予定はありません',
		addEvent: 'スキンケアのスタンプを追加する',
		addSchedule: 'Add a skincare schedule',
		confirmHeader: '確認する',
		confirmMessage: 'スタンプを削除してよろしいですか？',
		confirmYes: 'はい',
		stickers: 'ステッカー',
		schedules: 'ス キ ン ケ ア プ ラ ン の ',
		adSchedule: 'Add skincare plan',
		confirmCreate: 'Do you really want to start the schedule?',
		confirmRestart: 'For starting new schedule all non-occurring events will be deleted. Proceed?'
	}
}

import walletRu from '@/i18n/tpl/ru/wallet';
import walletEn from '@/i18n/tpl/en/wallet';
import walletJa from '@/i18n/tpl/ja/wallet';
const trWallet = {
	ru: {
		'What is Summus Wallet for': walletRu
	},
	en: {
		'What is Summus Wallet for': walletEn
	},
	ja: {
		'What is Summus Wallet for': walletJa
	}
}

// Для импорта только одной переменной
export { trSignIn, trTabs, trToolbar, trCart, trMenu, trProfile, trProduct, trCartForm, trCard, trOrder, trPoll, trCommon, trSettings, trCalendar, trOffer, trWallet }

// Для импорта сразу всех - использовать только когда есть необходимость, например для генерации переводов в эксель
export const messages = [trSignIn, trTabs, trToolbar, trCart, trMenu, trProfile, trProduct, trCartForm, trCard, trOrder, trPoll, trCommon, trSettings, trCalendar, trOffer, trWallet]