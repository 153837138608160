import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { TokenService } from "@/services/token.service";
import ShopTab from '@/views/ShopTab.vue';
import Cart from '@/views/Cart.vue';
import CalendarTab from '@/views/CalendarTab.vue';
import TimelineTab from '@/views/TimelineTab.vue';
import SignIn from '@/views/Signin.vue';
import Profile from '@/views/Profile.vue';
import PaymentMethod from '@/views/PaymentMethod.vue';
import ProductPage from "@/views/ProductPage.vue";
import TimelineInside from "@/views/TimelineInside.vue";
import Feed from "@/views/Feed.vue";
import Order from "@/views/Order.vue";
import Buy from "@/views/Buy.vue";
import AddCard from "@/views/AddCard.vue";
import Account from "@/views/Account.vue";
import Orders from "@/views/Orders.vue";
import OrderSuccess from "@/views/OrderSuccess.vue";
import {CartService} from "@/services/cart.service";
import AddCardWallet from "@/views/AddCardWallet.vue";
import WalletSuccess from "@/views/WalletSuccess.vue";
// Роутинг кошелька
import WalletPage from "@/views/WalletPage.vue";
import WalletReplenishment from "@/views/WalletReplenishment.vue";
import WalletBuy from "@/views/WalletBuy.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/timeline'
  },
  {
    path: '/',
    component: () => import('@/views/Tabs.vue'),
    children: [
      {
        path: '',
        redirect: '/timeline',
      },
      {
        path: 'product',
        name: 'Shop',
        component: ShopTab,
        meta: {
          public: true
        },
        children: [
          {
            path: '/product/:id',
            name: 'Product',
            component: ProductPage,
            meta: {
              public: true
            }
          },
        ]
      },
      {
        path: '/account',
        name: 'Account',
        component: Account
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: CalendarTab,
        meta: {
          public: true
        }
      },
      {
        path: 'timeline',
        name: 'Timeline',
        component: TimelineTab,
        meta: {
          public: true
        },
        children: [
          {
            path: '/timeline/:id',
            name: 'TimelineInside',
            component: TimelineInside,
            meta: {
              public: true
            }
          },
        ]
      }
    ]
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta: {
      public: true
    }
  },
  {
    path: '/wallet',
    name: 'WalletPage',
    component: WalletPage,
    meta: {
      public: false
    }
  },
  {
    path: '/wallet/buy',
    name: 'WalletBuy',
    component: WalletBuy,
    meta: {
      public: false
    }
  },
  {
    path: '/wallet/pay',
    name: 'WalletReplenishment',
    component: WalletReplenishment,
    meta: {
      public: false
    }
  },
  {
    path: '/wallet/add-card',
    component: AddCardWallet
  },
  {
    path: '/wallet/success/:id',
    component: WalletSuccess
  },
  {
    path: '/feed/:id',
    component: Feed,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/login',
    component: SignIn,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/profile',
    component: Profile
  },
  {
    path: '/pay-methods',
    component: PaymentMethod
  },
  {
    path: '/orders',
    component: Orders
  },
  {
    path: '/add-card',
    component: AddCard,
    meta: {
      public: true,
      cartMust: true
    }
  },
  {
    path: '/buynow',
    component: Order,
    meta: {
      public: true,
      cartMust: true
    }
  },
  {
    path: '/confirm',
    component: Buy,
    meta: {
      public: true,
      cartMust: true
    }
  },
  {
    path: '/order-success/:id',
    name: 'OrderSuccess',
    component: OrderSuccess,
    meta: {
      public: true
    }
  },
  {
    path: '/signup',
    component: () => import("@/views/Signup.vue"),
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/forgot',
    component: () => import("@/views/Forgot.vue"),
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
      record => record.meta.onlyWhenLoggedOut
  );

  if(to.name !== 'Timeline') {
    document.title = 'Summus Beauty Lab'
  }

  const cartMust = to.matched.some(
      record => record.meta.cartMust
  );

  if(cartMust && !CartService.getCart().length) {
    return next("/");
  }

  const loggedIn = !!TokenService.getToken();

  if (!isPublic && !loggedIn) {
    return next({
      path: "/login",
      query: { redirect: to.fullPath }
    });
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next("/timeline");
  }

  next();
});

export default router;
