<template>
  <ion-content fullscreen class="ion-padding">
    <form  @submit.prevent="handleLogin">
      <div id="logo-block">
        <main-logo />
      </div>
      <section style="margin-bottom: 20px;">
        <h5 style="margin-top: 10px;" class="text-center">{{ $tr(trSignIn, 'continue') }}</h5>
      </section>
      <section style="margin-bottom: 20px;">
        <ion-button class="sign-in-with-apple" color="white" expand="block" @click="openAppleSignIn" v-if="showAppleSignIn">
          <ion-icon :icon="logoApple" slot="start"></ion-icon>
          {{ $tr(trSignIn, 'apple') }}
        </ion-button>
        <ion-button color="facebook" expand="block" :disabled="loadingFb" @click="loginFb" type="button">
          <ion-icon slot="start" :icon="logoFacebook"></ion-icon>
          {{ $tr(trSignIn, 'social') }}
        </ion-button>
        <ion-button color="vk" expand="block" @click="loginVk" type="button">
          <ion-icon style="height: 90%;" slot="start" src="/assets/icon/vk.svg"></ion-icon>
          {{ $tr(trSignIn, 'socialVk') }}
        </ion-button>
        <template v-if="LanguageService.getLang() === RU_LANG">
          <ion-button color="mailru" expand="block" @click="loginMailRu" type="button">
            <ion-icon slot="start" src="/assets/icon/mailru.svg"></ion-icon>
            {{ $tr(trSignIn, 'socialMr') }}
          </ion-button>
          <ion-button color="ok" expand="block" @click="loginOk" type="button">
            <ion-icon slot="start" src="/assets/icon/ok.svg"></ion-icon>
            {{ $tr(trSignIn, 'socialOk') }}
          </ion-button>
        </template>
        <ion-button color="dark" expand="block" @click="loginGoogle" type="button">
          <ion-icon slot="start" src="/assets/icon/google.svg"></ion-icon>
          {{ $tr(trSignIn, 'socialGoogle') }}
        </ion-button>
      </section>
      <ion-item>
        <ion-label position="floating">{{ $tr(trSignIn, 'username') }}</ion-label>
        <ion-input v-model="form.username" type="email" id="username" required></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">{{ $tr(trSignIn, 'password') }}</ion-label>
        <ion-input type="password" v-model="form.password" id="password" required></ion-input>
      </ion-item>
      <section class="sign-margin">
        <ion-button expand="block" :disabled="loading" type="submit">
          <span v-if="!loading">{{ $tr(trSignIn, 'enter') }}</span>
          <span v-else>{{ $tr(trSignIn, 'loading') }}</span>
          <ion-icon slot="end" :icon="logIn"></ion-icon>
        </ion-button>
      </section>
      <section class="sign-margin">
        <ion-button type="button" expand="block" color="light" :disabled="loading" @click="$root.navi('/forgot')">
          {{ $tr(trSignIn, 'forgot') }}
          <ion-icon slot="end" :icon="helpCircle"></ion-icon>
        </ion-button>
      </section>
      <section class="sign-margin">
        <h5 class="text-center">{{ $tr(trSignIn, 'newUser') }}</h5>
        <ion-button type="button" expand="block" color="shiawasedo" :disabled="loading" @click="$root.navi('/signup')">
          {{ $tr(trSignIn, 'createAccount') }}
          <ion-icon slot="end" :icon="personAddSharp"></ion-icon>
        </ion-button>
      </section>
    </form>
  </ion-content>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonButton,
  IonInput,
  alertController,
  IonIcon,
  IonContent,
  loadingController,
    isPlatform
} from '@ionic/vue'
import { logIn, personAdd, logoFacebook, logoApple, personAddSharp, helpCircle } from 'ionicons/icons';
import { mapActions, mapGetters } from "vuex"
import { useRouter } from 'vue-router';
import {SignInWithApple} from '@capacitor-community/apple-sign-in'
import {trSignIn} from '@/i18n/messages'
import {Device} from '@capacitor/device'
import ApiService from "@/services/api.service";
import {TokenService} from "@/services/token.service";
import {LanguageService, RU_LANG} from "@/services/language.service";
import {VkAuthService} from "@/services/vk.auth.service";
import {MailRuAuthService} from "@/services/mail.auth.service";
import {AuthService} from "@/services/auth.service";
import {GoogleAuthService} from "@/services/google.auth.service";
import {OkAuthService} from "@/services/ok.auth.service";
import MainLogo from "@/components/MainLogo";
import {FbAuthService} from "@/services/fb.auth.service";

export default {
  name: 'Login',
  components: {MainLogo, IonItem, IonLabel, IonButton, IonInput, IonIcon, IonContent },
  setup() {
    const router = useRouter()
    return {
      router,
      logIn,
      personAdd,
      logoFacebook,
      trSignIn,
      logoApple,
      personAddSharp,
      RU_LANG,
      LanguageService,
      helpCircle
    };
  },
  data() {
    return {
      showAppleSignIn: false,
      form: {
        username: "",
        password: ""
      },
      loading: false,
      loadingFb: false,
      fbLogin: null
    };
  },
  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "authenticationError",
      "authenticationErrorCode"
    ])
  },
  methods: {
    ...mapActions("auth", ["signIn", "hybridSignIn"]),
    openAppleSignIn() {
      SignInWithApple.authorize({
        scopes: "email name",
        nonce: "nonce"
      })
      .then(async (res) => {
        if (res.response && res.response.identityToken) {
          ApiService.post('appleAuth', res.response).then(response => {
            TokenService.saveToken(response.data.data.access_token);
            LanguageService.setLangById(response.data.data.langId);
            TokenService.saveUser(response.data.data.id);
            TokenService.saveRefreshToken(response.data.data.refresh_token);
            ApiService.setHeader();
            ApiService.mount401Interceptor();
          })
        } else {
          this.presentAlert();
        }
      })
      .catch(() => {
        this.presentAlert();
      });
    },
    async handleLogin() {
      this.loading = true
      this.signIn(this.form).then((res) => {
        console.log(res)
        this.form.username = ""
        this.form.password = ""
        //window.location.reload()
        setTimeout(() => {
          this.loading = false
        }, 500)
      }).catch(async () => {
        this.loading = false
        const errorAlert = await alertController
            .create({
              header: this.$tr(trSignIn, 'errorHeader'),
              subHeader: this.$tr(trSignIn, 'errorSubHeader'),
              buttons: ['OK'],
            });
        await errorAlert.present()
      })
    },
    async loginFb() {
      FbAuthService.authRequest()
    },
    async loginVk() {
      VkAuthService.authRequest()
    },
    async loginMailRu() {
      MailRuAuthService.authRequest()
    },
    async loginGoogle() {
      GoogleAuthService.authRequest()
    },
    async loginOk() {
      OkAuthService.authRequest()
    },
    async presentAlert() {
      const alert = await this.alertController.create({
        header: 'Login Failed',
        message: 'Please try again later',
        buttons: ['OK'],
      });
      await alert.present();
    }
  },
  async mounted() {
    const device = await Device.getInfo();
    if(this.$route.query.code) {
      const loading = await loadingController.create({});
      await loading.present();
      let res = {}

      if(isPlatform('mobileweb')) {
        window.location = 'jp.co.shiawasedo.app://login?code=' + this.$route.query.code
      }

      const provider = AuthService.getProvider()
      try {
        switch (provider) {
          case 'vk':
            res = await VkAuthService.getToken(this.$route.query.code)
            break;
          case 'mru':
            res = await MailRuAuthService.getToken(this.$route.query.code)
            break;
          case 'google':
            res = await GoogleAuthService.getToken(this.$route.query.code)
            break;
          case 'okru':
            res = await OkAuthService.getToken(this.$route.query.code)
            break;
          case 'fb':
            res = await FbAuthService.getToken(this.$route.query.code)
            break;
        }
      } catch (e) {
        console.error(e)
        await loading.dismiss();
      }

      if(res.data && res.data.data && !res.data.data.error) {
        const data = res.data.data
        this.hybridSignIn({provider: provider, token: data.access_token, userId: data.user_id, email: data.email}).then(() => {
          loading.dismiss();
          window.location.reload()
        })
      } else {
        await loading.dismiss();
      }
    }
    this.showAppleSignIn = device.platform === 'ios';
  }
}
</script>
<style lang="scss" scoped>
#logo-block {
  padding: 10px 0;
  text-align: center;
  font-size: 100px;
  line-height: 1;
  .logo {
    margin: 0;
  }
}
.sign-in-with-apple {
  --border-color: var(--ion-color-dark);
  --border-width: 1px;
  --border-style: solid;
}

</style>
