
import Toolbar from "@/components/Toolbar.vue";
import {IonPage, IonContent, IonList, IonItem, IonLabel, IonThumbnail, IonText, IonButton, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonCol, IonSpinner } from '@ionic/vue';
import {gift} from 'ionicons/icons';
import {trMenu, trOrder} from "@/i18n/messages";
import { defineComponent, ref } from 'vue';
import Order from "@/models/Order";
import ApiService from "@/services/api.service";
import filters from "@/filters/filters";
import {TokenService} from "@/services/token.service";
import _ from "lodash";
import ImageLoader from "@/components/ImageLoader.vue";
export default defineComponent({
  name: "Orders",
  components: {
    ImageLoader,
    Toolbar, IonPage, IonContent, IonList, IonItem, IonLabel, IonThumbnail, IonText, IonButton, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonCol, IonSpinner },
  data() {
    return {
      orders: Array<Order>(),
      loading: true,
      page: 1,
      allLoading: false
    }
  },
  setup() {
    const isDisabled = ref(false);
    return { trMenu, filters, trOrder, gift, isDisabled }
  },
  methods: {
    goToSite(id: any) {
      window.open(process.env.VUE_APP_BACK_URL + 'profile/orders' + '?orderId=' + id + '&access_token=' + TokenService.getToken(), '_system', 'location=yes')
    },
    paginate($event: any) {
      if(this.allLoading) {
        return this.orders;
      }
      this.page++
      this.loadData($event);
    },
    loadData($event: any = null) {
      ApiService.get('orders?page=' + this.page).then(res => {
        if(res.data && res.data.data && res.data.data.length) {
          this.orders = [...this.orders, ...res.data.data]
          _.orderBy(this.orders, ['id', 'desc'])
        }

        if(res.data && res.data.data && res.data.data.length < 10) {
          this.allLoading = true
        }

        if($event) {
          $event.target.complete();
        }
        this.loading = false
      })
    },
    ionViewWillEnter() {
      this.loadData()
    }
  }
})
