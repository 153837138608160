export default "<h2 class=\"title\">Для чего нужен Summus Кошелёк？</h2>\n" +
"<div class=\"item-block\">\n" +
"    <ion-text>\n" +
"    <p><b>Это&nbsp;&mdash; самый быстрый способ оплаты на&nbsp;сайте.</b>&nbsp;Вам больше не&nbsp;нужно доставать карту или вспоминать пароль от&nbsp;PayPal во&nbsp;время акции.</p>\n" +
"\n" +
"<p>Для владельцев Summus Кошелька будет создана&nbsp;<b>собственная программа лояльности:</b>&nbsp;система скидок и&nbsp;подписок на&nbsp;косметику, а&nbsp;также специальные подарки к&nbsp;заказам.</p>\n" +
"\n" +
"<p>При желании&nbsp;<b>вы&nbsp;можете пополнить кошелёк другого клиента.</b>&nbsp;Для этого вам нужно знать только электронный адрес, с&nbsp;которым клиент зарегистрирован на&nbsp;сайте. Вы&nbsp;сможете добавить сообщение с&nbsp;поздравлением к&nbsp;своему подарку. Также чужой кошелёк можно будет пополнить анонимно.</p>\n" +
"</ion-text>\n" +
"</div>\n" +
"<h2 class=\"title\">Сколько денег попадёт в кошелёк?</h2>\n" +
"    <div class=\"item-block\">\n" +
"    <ion-text>\n" +
"    <p><b>Валюта Summus Кошелька&nbsp;&mdash; японская иена.</b></p>\n" +
"\n" +
"<p>Минимальная сумма пополнения кошелька&nbsp;&mdash;1000 иен, максимальная&nbsp;&mdash;100000 иен.</p>\n" +
"\n" +
"<p>При пополнении кошелька с&nbsp;помощью банковской карты или PayPal в&nbsp;него будет внесена та&nbsp;сумма, которую вы&nbsp;ввели в&nbsp;окошко пополнения. Пополнение кошелька произойдёт практически мгновенно.</p>\n" +
"\n" +
"<p><b>Комиссия за&nbsp;пополнение кошелька</b>&nbsp;с&nbsp;помощью банковской карты или PayPal&nbsp;&mdash; 4%, она остаётся у&nbsp;платёжных систем.</p>\n" +
"</ion-text>\n" +
"</div>\n" +
"<h2 class=\"title\">\n" +
"    Можно ли вернуть деньги из кошелька?\n" +
"    </h2>\n" +
"    <div class=\"item-block\">\n" +
"        <ion-text>\n" +
"        <p>Можно вернуть конкретные транзакции пополнения кошелька, если средства не&nbsp;были потрачены.</p>\n" +
"\n" +
"<p>Если средства в&nbsp;вашем кошельке&nbsp;&mdash; подарок от&nbsp;другого клиента, их&nbsp;можно вернуть только дарителю.</p>\n" +
"</ion-text>\n" +
"</div>";