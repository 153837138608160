<template>
  <template v-if="!load">
    <template v-if="canBuy">
      <ion-button expand="block" color="shiawasedo" :disabled="loading" @click="towalletReplenishment">
        {{ $tr(trCartForm, 'pay') }}
      </ion-button>
    </template>
    <div class="text-center" style="color: #f00;" v-else>
      {{ $tr(trCartForm, 'walletLose') }} {{ amount }})
    </div>
  </template>
  <div class="text-center" v-else>
    <ion-spinner name="circles" />
  </div>
</template>

<script>
import {trCartForm, trCommon} from "@/i18n/messages";
import ApiService from "@/services/api.service";
import {CartService} from "@/services/cart.service";
import {OrderService} from "@/services/order.service";
import {WalletService} from "@/services/wallet.service";
import {alertController, IonSpinner, IonButton} from "@ionic/vue";
const WALLET_PAY_METHOD = 130;
export default {
  name: "WalletButton",
  props: ['order', 'wallet'],
  setup() {
    return {
      trCartForm
    }
  },
  data() {
    return {
      load: true,
      loading: false,
      amount: 0,
      canBuy: true,
    }
  },
  components: {
    IonSpinner, IonButton
  },
  methods: {
    towalletReplenishment() {
      this.loading = true
      if(this.order) {
        ApiService
            .post('wallet', {
              order: this.order,
              cart: CartService.getCart(),
              total: CartService.total(),
              promoCode: CartService.getPromoCode()
            })
            .then((res) => {
              CartService.clearCart()
              OrderService.clear()
              if(res.data.data && res.data.data.number) {
                window.location.href = '/order-success/' + res.data.data.number
              }
              this.loading = false
            })
            .catch(async (err) => {
              const alert = await alertController.create({
                header: this.$tr(trCommon, 'error'),
                message: err.response.data.meta.error_message,
                buttons: ['OK'],
              });
              await alert.present();
              this.loading = false
            })
      }

      if(this.wallet) {
        ApiService
            .post('walletReplenishment', {
              wallet: this.wallet,
              payMethod: WALLET_PAY_METHOD
            })
            .then((res) => {
              WalletService.clear()
              if(res.data.data && res.data.data.id) {
                window.location.href = '/wallet/success/' + res.data.data.id
              }
              this.loading = false
            })
            .catch(async (err) => {
              const alert = await alertController.create({
                header: this.$tr(trCommon, 'error'),
                message: err.response.data.meta.error_message,
                buttons: ['OK'],
              });
              await alert.present();
              this.loading = false
            })
      }
    },
    loadAmount() {
      ApiService.get('wallet').then(res => {
        this.amount = res.data.data.amount.text
        this.canBuy = res.data.data.amount.amount >= CartService.total()
        this.load = false
      })
    }
  },
  mounted() {
    this.loadAmount()
    this.$emitter.on('update:wallet', () => {
      this.loadAmount()
    });
  }
}
</script>

<style scoped>

</style>