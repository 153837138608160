<template>
  <ion-page>
    <ion-content fullscreen class="timeline-inside-content">
      <span ref="tlRef">
        <template v-if="data">
          <div class="timeline-inside-data">
            <div class="timeline-header" :class="data.darkTheme ? 'text-white' : ''" :style="{backgroundImage: $getBg(data.mainImage)}"></div>
            <div class="timeline-body">
              <h1>{{ filters.striptags(data.title) }}</h1>
              <h4 v-html="data.lead"></h4>
              <div v-html="data.body"></div>
            </div>
          </div>
        </template>
      </span>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonContent, IonPage, isPlatform} from '@ionic/vue';
import {closeOutline, heart, heartOutline} from 'ionicons/icons';
import POST_TYPES from '@/components/timeline/PosTypes';
import ApiService from "@/services/api.service";
import filters from "@/filters/filters";
export default {
  name: 'Feed',
  components: {
    IonContent, IonPage
  },
  setup() {
    return {closeOutline, POST_TYPES, heart, heartOutline, filters}
  },
  data() {
    return {
      data: null
    }
  },
  mounted() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.indexOf("android") > -1;
    if(isPlatform('mobileweb')) {
      window.location = isAndroid
          ? 'https://play.google.com/store/apps/details?id=jp.co.shiawasedo.app'
          : 'https://apps.apple.com/ru/app/summus-beauty-lab/id1560234970'
    }

    ApiService.get(`feed?id=${this.$route.params.id}`).then(res => {
      this.data = res.data.data[0]
      if(this.data.type.id === POST_TYPES.PRODUCT) {
        window.location.href = 'https://www.shiawasedo.co.jp/shop/product-neo?sku=' + this.data.data.cartId
      }
    })
  },
  methods: {
    close() {
      this.$emitter.emit('modal:close')
    }
  }
}
</script>
<style lang="scss" scoped>
h4 {
  font-size: 14px;
}
.like-btn {
  z-index: 1;
  bottom: 20px;
  left: 10px;
  position: fixed;
  ion-button {
    background-color: var(--ion-color-shiawasedo);
    border-radius: 50%;
    width: 35px!important;
    height: 35px!important;
    padding: 0!important;
    vertical-align: middle;
    padding: 0!important;
  }
}
.timeline-inside-content {
  .timeline-inside-data {
    .timeline-header {
      position: relative;
      width: 100%;
      height: calc(100vw * 1.25);
      padding: 50px 5% 5%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      h1,h4 {
        margin: 0 0 5px;
      }
      .inside-share {
        position: absolute;
        bottom: 20px;
      }
    }
    .timeline-body {
      z-index: -1;
      padding: 10px 5% 10px;
      font-size: 18px;
    }
  }
}
</style>
