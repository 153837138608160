
import {
  loadingController,
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonToolbar,
  modalController,
  IonRadio,
  IonRadioGroup,
  IonCard, alertController
} from "@ionic/vue"
import {trCard, trCart, trCartForm, trCommon, trMenu, trProfile, trSignIn} from "@/i18n/messages";
import Toolbar from "@/components/Toolbar.vue";
import {CartService} from "@/services/cart.service";
import {mapActions} from "vuex";
import {card, logoApple, logoPaypal} from 'ionicons/icons';
import {OrderService} from "@/services/order.service";
import ApiService from "@/services/api.service";
import {ApplePayEventsEnum, GooglePayEventsEnum, Stripe} from '@capacitor-community/stripe';
import {Device} from '@capacitor/device'
import {useRouter} from "vue-router";
import {defineComponent} from 'vue';
import Drop from "@/components/Drop.vue";
import PayPalButton from "@/components/PayPalButton.vue";
import {InAppBrowser} from "@ionic-native/in-app-browser";
import WalletButton from "@/components/WalletButton.vue";

Stripe.initialize({
  publishableKey: process.env.VUE_APP_STRIPE_PKEY
})
export default defineComponent({
  name: "Buy",
  components: {
    WalletButton,
    PayPalButton,
    IonPage, IonContent, Toolbar, IonRow, IonCol, IonGrid, IonIcon, IonText, IonLabel, IonList, IonListHeader, IonButton, IonItem, IonSegment, IonSegmentButton, IonFooter, IonToolbar, IonRadio, IonRadioGroup, IonCard
  },
  ionViewDidEnter() {
    this.$emitter.on('update:cart', () => {
      this.total =  CartService.total();
      this.cartData = CartService.getCart();
    });
    this.$emitter.emit('update:wallet')
  },
  ionViewWillEnter() {
    this.loadProfile();
    this.loadCountries();
    Stripe.isApplePayAvailable().then(() => {
      this.canPay = true
    }).catch(() => {
      this.canPay = false
    });
    Stripe.isGooglePayAvailable().then(() => {
      this.canGooglePay = true
    }).catch(() => {
      this.canGooglePay = false
    });
  },
  methods: {
    converter() {
      ApiService.get('converter?total=' + this.total).then((res) => {
        this.order.currencies = res.data.data
      });
    },
    toCardPay() {
      if(!this.order.methodId) {
        OrderService.set(this.order)
        this.$router.push('/add-card')
      } else {
        this.loading = true
        ApiService
            .post('stripe', {
              order: this.order,
              methodId: this.order.methodId,
              cart: CartService.getCart(),
              total: CartService.total(),
              promoCode: CartService.getPromoCode()
            })
            .then((res) => {
              CartService.clearCart()
              OrderService.clear()
              if(res.data.data && res.data.data.number) {
                window.location.href = '/order-success/' + res.data.data.number
              }
              this.loading = false
            })
            .catch((e) => {
              this.loading = false
              this.alertError(e)
            })
      }
    },
    changePayMethod(ev: any) {
      this.payType = ev.target.value
    },
    setUpGooglePayment() {
      window.location.href = 'com.google.android.apps.walletnfcrel://'
    },
    setUpPayment() {
      window.location.href = 'shoebox://'
    },
    openModal: async function () {
      const modal = await modalController
          .create({
            component: Drop,
            componentProps: {
              order: this.order
            },
          })
      this.currentModal = modal
      return modal.present();
    },
    async googlePayed() {

      const clientSecret = (await ApiService.post('stripeIntent', {
        amount: this.total
      })).data.data

      await Stripe.createGooglePay({
        paymentIntentClientSecret: clientSecret,
        paymentSummaryItems: this.cartData.map((res: any) => {
          return {
            label: res.product.name + ' x' + res.count,
            amount: res.product.priceNumber * res.count,
          }
        }),
        merchantIdentifier: 'merchant.shiawasedo.inc',
        countryCode: 'JP',
        currency: 'JPY',
      })

      const result = await Stripe.presentGooglePay();
      if (result.paymentResult === GooglePayEventsEnum.Completed) {
        const loading = await loadingController.create({});
        await loading.present();
        ApiService
            .post('stripe', {
              clientSecret: clientSecret,
              order: this.order,
              cart: CartService.getCart(),
              total: CartService.total(),
              promoCode: CartService.getPromoCode()
            })
            .then((res) => {
              loading.dismiss()
              CartService.clearCart()
              OrderService.clear()
              if(res.data.data && res.data.data.number) {
                window.location.href = '/order-success/' + res.data.data.number
              }
            }).catch(() => {
          this.alertError('Произошел сбой при зачислении средств')
          loading.dismiss()
        })
      }

    },
    submitPaygentForm(form: HTMLFormElement) {
      form.submit()
    },
    async alertError(text: any) {
      const alert = await alertController.create({
        header: this.$tr(trCommon, 'error'),
        message: text,
        buttons: ['OK'],
      });
      await alert.present();
    },
    async toPaygentPay() {
      const loading = await loadingController.create({})
      await loading.present()
      ApiService
          .post('paygent', {
            order: this.order,
            cart: CartService.getCart(),
            total: CartService.total(),
            promoCode: CartService.getPromoCode()
          })
          .then((res) => {
            this.$nextTick(() => {
              const browser = InAppBrowser.create(
                  "data:text/html;base64," + btoa(res.data.data.form),
                  '_blank',
                  "hidden=no,location=no,clearsessioncache=yes,clearcache=yes"
              )
              loading.dismiss()

              browser.on('loadstart').subscribe((e) => {
                if (e && e.url) {
                  if(e.url.includes('summusbeauty.shiawasedo.co.jp')) {
                    const url = new URL(e.url)
                    browser.close();
                    if(!e.url.includes('error')) {
                      CartService.clearCart()
                      OrderService.clear()
                    }
                    window.location.href = url.pathname + url.search
                  }
                }
              });

            })
          }).catch(() => {
            this.alertError('Произошел сбой при обмене данных с платёжной системой (20005)')
            loading.dismiss()
          })
    },
    async applePayed() {
      const clientSecret = (await ApiService.post('stripeIntent', {
        amount: this.total
      })).data.data

      await Stripe.createApplePay({
        paymentIntentClientSecret: clientSecret,
        paymentSummaryItems: this.cartData.map((res: any) => {
          return {
            label: res.product.name + ' x' + res.count,
            amount: res.product.priceNumber * res.count,
          }
        }),
        merchantIdentifier: 'merchant.shiawasedo.inc',
        countryCode: 'JP',
        currency: 'JPY'
      });


      // Present Apple Pay
      const result = await Stripe.presentApplePay();
      if (result.paymentResult === ApplePayEventsEnum.Completed) {
        const loading = await loadingController.create({});
        await loading.present();
        ApiService
            .post('stripe', {
              clientSecret: clientSecret,
              order: this.order,
              cart: CartService.getCart(),
              total: CartService.total(),
              promoCode: CartService.getPromoCode()
            })
            .then((res) => {
              loading.dismiss()
              CartService.clearCart()
              OrderService.clear()
              if(res.data.data && res.data.data.number) {
                window.location.href = '/order-success/' + res.data.data.number
              }
            }).catch(() => {
              this.alertError('Произошел сбой при зачислении средств')
              loading.dismiss()
            })
      }
    },
    ...mapActions("profile", ["get", "post"]),
    async loadProfile() {
      await this.get().then((res: any) => {
        this.profile = res.data.data;
      });
    },
    loadCountries() {
      ApiService.get('countries').then(res => {
        this.countries = res.data.data
      })
    }
  },
  data() {
    return {
      countries: null,
      showApplePay: false,
      showGooglePay: false,
      showPayPal: true,
      showPaygent: true,
      showWallet: true,
      order: OrderService.get(),
      payType: 'card',
      canPay: false,
      profile: null,
      loading: false,
      canMakePayments: false,
      total:  CartService.total(),
      cartData: CartService.getCart(),
      cartActive: true,
      isCheckout: false,
      currentModal: null,
      canGooglePay: false,
      paygentForm: null
    }
  },
  setup() {
    const router = useRouter()
    return {
      trCart, trCartForm, trSignIn, trCard, logoApple, trProfile, Stripe, loadingController, router, trMenu, card, logoPaypal
    }
  },
  watch: {
    // Для методов оплаты без выбора валюты сбрасываем конвертацию
    payType(val) {
      if(val && ['paypal', 'paygent', 'wallet'].indexOf(val) > -1) {
        this.order.currencyId = 3
      } else {
        this.converter()
      }
    }
  },
  async mounted() {
    if(this.$route.query.error) {
      await this.alertError(this.$route.query.error)
    }

    this.converter()

    this.$emitter.on('modal:close', () => {
      if(this.currentModal) {
        this.currentModal.dismiss()
      }
    })
    const device = await Device.getInfo();
    this.showApplePay = device.platform === 'ios';
    if(this.showApplePay) {
      this.payType = OrderService.get().methodId ? 'card' : 'apple'
    }
    this.showGooglePay = device.platform === 'android';
    if(this.showGooglePay) {
      this.payType = OrderService.get().methodId ? 'card' : 'google'
    }
  }
})
