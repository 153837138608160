<template>
  <ion-page>
    <toolbar/>
    <ion-content fullscreen class="shop">
      <ion-refresher slot="fixed" @ionRefresh="loadProducts($event)">
        <ion-refresher-content
            refreshing-spinner="circles">
        </ion-refresher-content>
      </ion-refresher>
      <span ref="shopTab">
        <shop :products="products" />
      </span>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonRefresher, IonRefresherContent, IonPage } from '@ionic/vue';
import {cartOutline, addCircle, removeCircle} from 'ionicons/icons';
import Toolbar from "@/components/Toolbar.vue";
import {mapActions} from "vuex";
import {CartService} from "@/services/cart.service";
import Cart from "@/models/Cart";
import {useRouter} from "vue-router";
import {trCart, trProduct} from '@/i18n/messages'
import Shop from "@/components/Shop";

export default {
  name: 'ShopTab',
  components: {
    Shop,
    Toolbar, IonContent, IonRefresher, IonRefresherContent, IonPage},
  data() {
    return {
      products: [],
      cart: CartService.getCart(),
      querySearch: null,
      loading: false
    }
  },
  setup() {
    const router = useRouter()
    return {
      cartOutline, addCircle, removeCircle, router, trCart, trProduct
    }
  },
  mounted() {
    this.$emitter.on('update:cart', () => {
      this.cart = CartService.getCart()
    });
  },
  methods: {
    goToProduct(id) {
      this.router.push(`/product/${id}`)
    },
    ...mapActions("products", ["getList"]),
    async loadProducts(event) {
      await this.getList({liked: true}).then((res) => {
        this.products = res.data.data
        if(event) {
          event.target.complete();
        }
      });
    },
    addToCart(product) {
      if(product.isAction && product.url) {
        return window.location.href = product.url
      }
      const cart = new Cart();
      cart.product = product;
      cart.count = 1;
      CartService.addTo(cart);
      this.$emitter.emit('update:cart');
      this.cart = CartService.getCart()
    },
    deleteFromCart(productId) {
      CartService.deleteFrom(productId);
      this.$emitter.emit('update:cart');
      this.cart = CartService.getCart()
    },
    inCartNotExist(id) {
      return typeof this.cart.find((item) => item.product.id === id) === "undefined"
    },
    goToCart() {
      this.router.push('/cart')
    },
    inCartCount(id) {
      if(!this.inCartNotExist(id)) {
        const cart = this.cart.find((item) => item.product.id === id)
        return cart?.count
      }
      return 0
    },
    ionViewWillEnter() {
      this.loadProducts()
    }
  },
  watch: {
    querySearch() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>
<style lang="scss">
.ion-padding-top-shop {
  padding-top: 1px;
}
.shop {
  background-color: #f5f5f5;
  .d-table {
    border-top: 6px solid #f5f5f5;
    width: 100%;
    display: table;
    &:last-child {
      border-bottom: 6px solid #f5f5f5;
    }
    .d-table-row {
      display: table-row;
      .d-table-cell {
        .corner {
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 45px;
          border-color: #e21d41 transparent transparent #e21d41;
          color: #fff;
          .corner-text {
            display: block;
            position: relative;
            text-align: center;
            top: -45px;
            left: -45px;
            font-size: 12px;
            font-weight: 600;
            padding-top: 5px;
            width: 90px;
            height: 90px;
            transform: rotate(-45deg);
          }
        }
        position: relative;
        vertical-align: middle;
        width:50%;
        display: table-cell;
        text-align: center;
        background-color: #fff;
        &.empty {
          background-color: #f5f5f5;
        }
        &:first-child {
          border-left: 6px solid #f5f5f5;
          border-right: 3px solid #f5f5f5;
        }
        &:last-child {
          border-right: 6px solid #f5f5f5;
          border-left: 3px solid #f5f5f5;
        }
        &.button-block {
          text-align: center;
          padding: 12px 6px 3px;
          height: 55px;
          ion-button {
            font-size: 12px;
            height: 32px;
            text-transform: none;
          }
          ion-buttons {
            ion-button {
              width: 34%;
              &:first-child, &:last-child {
                width: 33%;
              }
            }
          }
        }
        .product-title {
          font-size: 15px;
          font-weight: 600;
          margin: 0px 0 5px;
          padding: 0 2px;
        }
        .product-image {
          padding-top: 10px;
          height: 120px;
          text-align: center;
          width: 100%;
          margin: 0 0 5px!important;
        }
        .product-size {
          font-size: 10px;
          padding: 0 2px;
        }
        .product-price {
          font-weight: 500;
          color: #666666;
          margin: 10px 0 10px;
        }
        .product-currencies {
          font-size: 11px;
        }
        .product-real-price {
          font-size: 13px;
          text-decoration: line-through;
          margin: 5px 0 -5px;
        }
      }
    }
  }
}
</style>
