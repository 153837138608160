import UserAddress from "@/models/UserAddress";
import OrderItem from "@/models/OrderItem";
import Country from "@/models/Country";
import Subscription from "@/models/Subscription";

export default class Order {
    public items: OrderItem[] = []
    public price: any = ''
    public dateCreate: any = ''
    public currencyId = 3
    public currencies: any = null
    public number: any = ''
    public status: any = ''
    public isGift: any = ''
    public clientsPrice: any = ''
    public orderData?: any = ''
    public methodId?: any = null
    public paymentMethod: any = null
    public addressId: any = 0
    public userAddress: any|UserAddress = null
    public addAddressToBook: any = 0
    public name: any = ''
    public firstName: any = null
    public middleName: any = null
    public lastName: any = null
    public phone: any = null
    public postalCode: any = ''
    public countryCode: any = ''
    public country: Country|any = null
    public state: any = ''
    public city: any = ''
    public address: any = ''
    public address2: any = ''
    public street: any = ''
    public house: any = ''
    public housing: any = ''
    public building: any = ''
    public apartment: any = ''
    public email: any = null
    public language: any = ''
    public comment: any = ''
    public managersComment: any = ''
    public postcard: any = ''
    public promoCode: any = ''
    public cooperativeId: any = 0
    public allItemsInOneParcel?: any = ''
    public userId?: any = ''
    public categoryId?: any = ''
    public deliveryMethodId: any = 0
    public coupon?: any = ''
    public firstOrder?: any = false
    public tanabata: any = false
    public canShowContents: any = true
    public subscription: any|Subscription = null
}
