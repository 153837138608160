
import Toolbar from "../components/Toolbar.vue";
import {IonPage, IonContent, IonList, IonItem, IonIcon, IonText, IonRadio, IonSpinner, IonRadioGroup, alertController } from "@ionic/vue"
import {logoApple, card} from 'ionicons/icons';
import ApiService from "../services/api.service";
import PayMethod from "@/models/PayMethod";
import {trMenu} from '@/i18n/messages';
import { defineComponent } from 'vue'

export default defineComponent({
  components: {Toolbar, IonPage, IonContent, IonList, IonItem, IonIcon, IonText, IonRadio, IonSpinner, IonRadioGroup },
  methods: {
    async ionViewWillEnter() {
      const res = await ApiService.get('payMethods')
      this.payMethods = res.data.data
      this.loaded = true
    }
  },
  data() {
    return {
      payMethods: Array<PayMethod>(),
      defaultMethod: 'apple',
      loaded: false,
    }
  },
  setup() {
    return {
      trMenu, logoApple, card, alertController
    }
  },
})
