const trError = {
	ru: {
		601: 'Клиент с указанным email не найден. Пожалуйста, введите другой email.',
		602: 'Введённая сумма не соответствует минимально или максимально возможной. Пожалуйста, введите другую сумму.',
		603: 'Клиент с указанным email не найден. Пожалуйста, введите другой email.',
		604: 'Это ваш email. Чтобы пополнить свой кошелёк, перейдите во вкладку Мой кошелёк.'
	},
	en: {
		601: 'No client with the specified email was found. Please enter another email.',
		602: 'The amount you entered does not correspond to the minimum or maximum wallet top-up amount. Please enter another amount.',
		603: 'No client with the specified email was found. Please enter another email.',
		604: 'This is your own email. To top-up your wallet, please switch to the My Wallet tab.'
	},
	ja: {
		601: 'No client with the specified email was found. Please enter another email.',
		602: 'The amount you entered does not correspond to the minimum or maximum wallet top-up amount. Please enter another amount.',
		603: 'No client with the specified email was found. Please enter another email.',
		604: 'This is your own email. To top-up your wallet, please switch to the My Wallet tab.'
	}
}

export { trError }

export const messages = [trError]