import { createStore } from 'vuex';
import { auth } from "./auth.store";
import { home } from "./home.store";
import { profile } from "./profile.store";
import { products } from "./products.store";

export const store = createStore({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		auth,
		home,
		profile,
		products
	},
})
