<template>
  <ion-page>
    <toolbar :title="$tr(trCart, 'cart')" :backbutton="true"/>
    <ion-content fullscreen>
      <div ref="cartRef" v-if="cartData.length">
        <template v-if="!loading">
          <ion-list class="cart-list" ref="listRef">
          <ion-item v-for="(cartItem, key) in cartData" :key="key">
            <ion-thumbnail class="cart-image" slot="start" :style="{backgroundImage: $getBg(cartItem.product.mainImage)}"></ion-thumbnail>
            <ion-grid>
              <ion-row v-if="cartItem.product.canPay">
                <ion-col>
                  <h6 v-if="cartItem.product.isDiscount" class="product-real-price">
                    ¥{{ cartItem.product.realPrice }}
                  </h6>
                  <h5 v-html="cartItem.product.price"></h5>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <p v-html="cartItem.product.name"></p>
                  <p v-if="totalDiff && !cartItem.product.allowPromoCodeDiscount">
                    <small>{{$tr(trCart, 'isNoPromo')}}</small>
                  </p>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col :size="cartItem.product.canPay ? '6' : '10'" class="ion-text-left">
                  <ion-chip v-if="cartItem.product.canPay">
                      <ion-input class="text-center" @ionChange="changeCount(cartItem.count)" pattern="[1-9]" type="tel" v-model="cartItem.count" />
                  </ion-chip>
                  <ion-text v-else color="shiawasedo-danger" style="font-size: 14px; line-height: 1;" v-html="$tr(trCart, 'notCanPay')"></ion-text>
                </ion-col>
                <ion-col class="ion-text-right">
                  <ion-icon class="delete-product" color="shiawasedo-danger" @click.self="removeFromCart(cartItem)" tappable :icon="trash"/>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
          <ion-item v-if="totalDiff" lines="full" class="item">
            <span slot="end" class="price-text">
              <span style="color: #333;">{{ $tr(trCart, 'economy') }}: </span> ¥{{totalDiff}}
            </span>
          </ion-item>
            <ion-item v-if="canPay" lines="none" class="item">
            <span slot="end" class="price-text" v-if="!totalDiff">
              <span style="color: #333;">{{ $tr(trCart, 'total') }}: </span> ¥{{total}}
            </span>
            <span slot="end" class="price-text" v-else>
              <div class="product-real-price text-right" style="font-size: 90%">¥{{CartService.totalReal()}}</div>
              <span style="color: #333;">{{ $tr(trCart, 'total') }}:</span> ¥{{CartService.total()}}
            </span>
            </ion-item>
          </ion-list>
          <ion-list style="margin: 50px 0 0;">
            <ion-item lines="none">
              <ion-text class="promo-code-header">{{$tr(trCart, 'promo')}}</ion-text>
            </ion-item>
          </ion-list>
          <ion-row style="margin: 0 0 50px;">
            <ion-col size="6">
              <ion-chip>
                <ion-input v-model="promoCode"></ion-input>
                <ion-icon :icon="checkmarkCircleOutline" style="font-weight: bold; min-width: 24px;" :style="{opacity: totalDiff ? 1 : 0}" color="green" />
              </ion-chip>
            </ion-col>
            <ion-col size="6" class="text-right">
              <ion-button size="small" @click="checkPromoCode" fill="outline" color="shiawasedo">{{$tr(trCart, 'apply')}}</ion-button>
            </ion-col>
          </ion-row>
        </template>
        <div class="spin" v-else>
          <ion-spinner name="circles" />
        </div>
      </div>
      <span ref="cartRef" v-else>
        <ion-list class="cart-list" ref="listRef">
          <ion-item lines="none">{{ $tr(trCart, 'empty') }}</ion-item>
        </ion-list>
      </span>
    </ion-content>
    <ion-footer v-if="cartData.length">
      <ion-toolbar>
        <ion-button :disabled="loading || !canPay" @click="nextStep()" expand="block" color="shiawasedo">{{ $tr(trCart, 'buyNow') }}</ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import Toolbar from "../components/Toolbar";
import _ from 'lodash'
import {
  IonPage,
  IonContent,
  IonItem,
  IonList,
  IonThumbnail,
  IonInput,
  IonButton,
  IonIcon,
  IonRow,
  IonCol,
  IonChip,
  IonGrid,
  IonFooter,
  IonText,
  IonSpinner,
  IonToolbar,
  loadingController,
  alertController,
    //IonLabel
} from '@ionic/vue';
import { trash, logoYen, checkmarkCircleOutline } from 'ionicons/icons';
import {trCart} from '@/i18n/messages'
import {CartService} from "@/services/cart.service";
import {useRouter} from "vue-router";
import {TokenService} from "@/services/token.service";
import ApiService from "@/services/api.service";
export default {
name: "Cart",
  components: {
    Toolbar,
    IonPage,
    IonContent,
    IonItem,
    IonList,
    IonThumbnail,
    IonInput,
    IonButton,
    IonIcon,
    IonRow,
    IonCol,
    IonChip,
    IonGrid,
    IonFooter,
    IonText,
    IonSpinner,
    IonToolbar,
    //IonLabel
  },
  setup() {
    const router = useRouter();
    return { CartService, trash, router, trCart, logoYen, checkmarkCircleOutline }
  },
  data() {
    return {
      promoCode: null,
      promoObj: null,
      cartData: CartService.getCart(),
      loading: true,
      total:  CartService.total(),
      canPay: true,
      totalDiff: 0
    }
  },
  methods: {
    async presentAlert() {
      const alert = await alertController
          .create({
            header: this.$tr(trCart, 'notFoundPromo'),
            buttons: ['OK'],
          });
      await alert.present();
    },
    async checkPromoCode() {
      const load = await loadingController.create({})
      await load.present()
      ApiService.post('promoCode', {
        code: this.promoCode,
        cart: this.cartData
      }).then((res) => {
        if (res.data.data.length) {
          this.canPay = true
          res.data.data.forEach(product => {
            this.cartData = this.cartData.map((cart) => {
              if(cart.product.id === product.id) {
                cart.product = product;
              }
              if(!cart.product.canPay) {
                this.canPay = false
              }
              return cart;
            })
          })
          CartService.setCart(this.cartData)
          this.$emitter.emit('update:cart')
          this.totalDiff = CartService.totalDiff()
          if(this.totalDiff) {
            CartService.setPromoCode(this.promoCode)
          } else {
            this.promoCode = null
          }
        }
        load.dismiss()
      }).catch(() => {
        load.dismiss()
        this.presentAlert()
      })
    },
    removeFromCart(cartItem) {
      this.cartData = this.cartData.filter(item => {
        return item.product.id !== cartItem.product.id
      })
      CartService.setCart(this.cartData)
      this.$emitter.emit('update:cart')
      this.reload()
    },
    changeCount(count) {
      if(count > 0) {
        this.cartData = this.cartData.map((item) => {
          item.count = Number.parseInt(item.count)
          return item
        })
        CartService.setCart(this.cartData)
        this.$emitter.emit('update:cart')
      }
    },
    convertProxyObjectToPojo(proxyObj) {
      return _.cloneDeep(proxyObj);
    },
    param(array) {
      let params = '';
      array.forEach(item => {
        params += '&cart[' + item.product.cartId + ']=' + item.count
      })
      return params
    },
    nextStep() {
      this.router.push('/buynow')
    },
    goToSite() {
      window.open(process.env.VUE_APP_BACK_URL + 'data_request/cart.php' + '?access_token=' + TokenService.getToken() +  this.param(this.cartData), '_system', 'location=yes')
      CartService.clearCart()
      this.$emitter.emit('update:cart')
      this.router.push('/shop')
    },
    reload() {
      this.loading = true
      const ids = []
      this.cartData.forEach((cart) => {
        ids.push(cart.product.id)
      })
      ApiService.get('products', {
        params: {
          ids: ids
        }
      }).then((res) => {
        this.loading = false
        if (res.data.data.length) {
          this.canPay = true
          res.data.data.forEach(product => {
            this.cartData = this.cartData.map((cart) => {
              if(cart.product.id === product.id) {
                cart.product = product;
              }
              if(!cart.product.canPay) {
                this.canPay = false
              }
              return cart;
            })
          })
          CartService.setCart(this.cartData)
          this.$emitter.emit('update:cart')
          const promoCode = CartService.getPromoCode()
          if(promoCode) {
            this.promoCode = promoCode;
            this.checkPromoCode()
          }
        }
      });
    }
  },
  mounted() {
    this.reload()
    this.$emitter.on('update:cart', () => {
      this.cartData = CartService.getCart()
      this.total = CartService.total()
    });
  }
}
</script>

<style lang="scss" scoped>
.cart-label {
  white-space: normal;
}
.price-text {
  color: var(--ion-color-shiawasedo);
  font-size: 18px;
}
ion-note {
  font-size: 16px;
  color: inherit;
  ion-input {
    width: 80px;
  }
}
.item-inner {
  padding-inline-end: 0!important;
}
.delete-product {
  font-size: 27px;
  margin-top: 10px;
}
.cart-list {
  h5, p {
    margin: 0!important;
  }
  item-item {
    &:last-child {
      .item-inner {
        border: 0;
      }
    }
  }
}
ion-thumbnail {
  width: 80px;
  height: 80px;
  margin: 0 0 0 -10px;
}
.product-real-price {
  text-decoration: line-through;
  color: #333!important;
  font-weight: normal;
  margin: 15px 0 0!important;
}
.promo-code-header {
  line-height: 1;
  padding-top: 5px;
}
</style>
