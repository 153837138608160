export default {
	ru: {
		"Имя клиента": "Имя клиента",
		'Доступные средства': 'Доступные средства',
		'Summus Кошелёк': '<b>Summus Кошелёк — новая форма оплаты в онлайн-магазине</b>. Пополняйте его в любое время одним из доступных способов, а затем используйте баланс для оплаты заказов на сайте. При желании вы также сможете добавить средства в кошелёк другого клиента — в качестве подарка.',
		'Пополнение кошелька': 'Пополнение кошелька',
		'Summus Purpose': '<p>Summus Кошелёк использует основной email вашего профиля&nbsp;<b>%email%</b>. На&nbsp;него будут приходить уведомления по&nbsp;поступлению средств в&nbsp;кошелёк. Вы можете изменить email своего кошелька, назначив новый основной email в&nbsp;разделе&nbsp;<a href="https://www.shiawasedo.co.jp/profile#extraEmails">Профиль&mdash;Сохранённые email</a>.</p>' +
			'<p>Чтобы пополнить кошелёк другого человека, вам нужно знать email его кошелька.</p>',
		'Мой кошелёк': 'Мой кошелёк',
		'Кошелёк другого клиента': 'Кошелёк другого клиента',
		'Email кошелька получателя': 'Email кошелька получателя',
		'Сумма пополнения': 'Сумма пополнения',
		'Введите email': 'Введите email',
		'Введите сумму': 'Введите сумму',
		'Введите подпись': 'Введите подпись',
		'Подписаться': 'Подписаться',
		'Отправить анонимно': 'Отправить анонимно',
		'Сообщение для получателя': 'Сообщение для получателя',
		'Перейти к оплате': 'Перейти к оплате',
		'Адрес плательщика': 'Адрес плательщика',
		'Кошелёк пополнен успешно': 'Кошелёк пополнен успешно',
		'Введите текст сообщения': 'Введите текст сообщения'
	},
	en: {
		"Имя клиента": "Client's name",
		'Доступные средства': 'Available balance',
		'Summus Кошелёк': '<b>Summus Wallet</b> is a new form of payment for purchases made from our online store. Top up your wallet any time using one of the available top-up methods and then use the balance to pay for orders placed from the website. If you wish, you can also add funds to another person’s wallet as a gift.',
		'Summus Purpose': '<p>Summus Wallet uses the main email address&nbsp;<b>%email%</b>&nbsp;associated with your profile. You will receive notifications on wallet topups to this email. You can change your wallet email address by specifying a new main email address in the&nbsp;<a href="https://www.shiawasedo.co.jp/profile#extraEmails">Profile&mdash;Extra emails</a>&nbsp;section.</p><p>To top up another person&rsquo;s wallet, you need to know the email address linked to that person&rsquo;s wallet.</p>',
		'Пополнение кошелька': 'Wallet top-up',
		'Мой кошелёк': 'My wallet',
		'Кошелёк другого клиента': 'Another client\'s wallet',
		'Email кошелька получателя': 'Recipient’s email',
		'Сумма пополнения': 'Top-up amount',
		'Введите email': 'Enter email',
		'Введите сумму': 'Enter amount',
		'Введите подпись': 'Enter sender name',
		'Подписаться': 'Sender name',
		'Отправить анонимно': 'Send anonymously',
		'Сообщение для получателя': 'Message for the recipient',
		'Перейти к оплате': 'Proceed to payment',
		'Адрес плательщика': 'Payer\'s address',
		'Кошелёк пополнен успешно': 'The wallet has been topped up successfully',
		'Введите текст сообщения': 'Enter message'
	},
	ja: {
		"Имя клиента": "Client's name",
		'Доступные средства': 'Available balance',
		'Summus Кошелёк': '<b>Summus Wallet</b> is a new form of payment for purchases made from our online store. Top up your wallet any time using one of the available top-up methods and then use the balance to pay for orders placed from the website. If you wish, you can also add funds to another person’s wallet as a gift.',
		'Summus Purpose': '<p>Summus Wallet uses the main email address&nbsp;<b>%email%</b>&nbsp;associated with your profile. You will receive notifications on wallet topups to this email. You can change your wallet email address by specifying a new main email address in the&nbsp;<a href="https://www.shiawasedo.co.jp/profile#extraEmails">Profile&mdash;Extra emails</a>&nbsp;section.</p><p>To top up another person&rsquo;s wallet, you need to know the email address linked to that person&rsquo;s wallet.</p>',
		'Пополнение кошелька': 'Wallet top-up',
		'Мой кошелёк': 'My wallet',
		'Кошелёк другого клиента': 'Another client\'s wallet',
		'Email кошелька получателя': 'Recipient’s email',
		'Сумма пополнения': 'Top-up amount',
		'Введите email': 'Enter email',
		'Введите сумму': 'Enter amount',
		'Введите подпись': 'Enter sender name',
		'Подписаться': 'Sender name',
		'Отправить анонимно': 'Send anonymously',
		'Сообщение для получателя': 'Message for the recipient',
		'Перейти к оплате': 'Proceed to payment',
		'Адрес плательщика': 'Payer\'s address',
		'Кошелёк пополнен успешно': 'The wallet has been topped up successfully',
		'Введите текст сообщения': 'Enter message'
	}
}