<template>
  <span v-if="countries && addresses">
    <template v-if="!addAddress">
      <ion-list>
        <ion-list-header>
          <ion-text color="shiawasedo">
            <h4 id="shippingAddress" class="order-header">
              <template v-if="title">
                {{ title }}
              </template>
              <template v-else>
                {{$tr(trCartForm, 'shippingAddress')}}
              </template>
            </h4>
          </ion-text>
        </ion-list-header>
        <ion-radio-group v-model="defaultIndex" @ionChange="changeDefault">
          <ion-card v-for="(address, key) in addresses" :key="key" :class="defaultIndex === key ? 'active' : ''">
            <ion-card-content>
              <ion-item class="card-bg">
                <ion-label><b>{{ address.contact }}</b></ion-label>
                <ion-radio mode="md" :value="key" slot="end" />
              </ion-item>
              <ion-item class="card-bg" lines="none">
                <ion-label @click="defaultIndex = key" v-html="address.toString"></ion-label>
              </ion-item>
            </ion-card-content>
          </ion-card>
        </ion-radio-group>
        <ion-button v-if="addresses" style="margin: 0 16px 20px;" expand="block" @click="changeAddAddress()">{{$tr(trCartForm, 'otherAddress')}}</ion-button>
      </ion-list>
    </template>
    <template v-else>
      <ion-list>
        <ion-list-header>
          <ion-text color="shiawasedo">
            <h4 class="order-header">{{$tr(trCartForm, 'addressee')}}</h4>
          </ion-text>
        </ion-list-header>
        <ion-item>
          <ion-label position="floating"><ion-text color="danger">*</ion-text> {{$tr(trProfile, 'lastname') }}</ion-label>
          <ion-input required v-model="address.lastName">
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating"><ion-text color="danger">*</ion-text> {{$tr(trProfile, 'firstname') }}</ion-label>
          <ion-input required v-model="address.firstName">
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">{{$tr(trProfile, 'middlename') }}</ion-label>
          <ion-input v-model="address.middleName">
          </ion-input>
        </ion-item>
        <ion-item lines="full">
          <ion-label position="floating"><ion-text color="danger">*</ion-text> {{$tr(trCartForm, 'phone') }}</ion-label>
          <ion-input type="tel" required v-model="address.phone">
          </ion-input>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-list-header>
          <ion-text color="shiawasedo">
            <h4 class="order-header">{{$tr(trCartForm, 'shippingAddress')}}</h4>
          </ion-text>
        </ion-list-header>
        <ion-item v-if="address.country && address.country.isWithoutZip">
          <ion-label position="floating">{{$tr(trCartForm, 'postalCode') }}</ion-label>
          <ion-input type="tel" v-model="address.postalCode">
          </ion-input>
        </ion-item>
        <ion-item v-else>
          <ion-label position="floating"><ion-text color="danger">*</ion-text> {{$tr(trCartForm, 'postalCode') }}</ion-label>
          <ion-input required type="tel" v-model="address.postalCode">
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating"><ion-text color="danger">*</ion-text> {{$tr(trCartForm, 'country') }}</ion-label>
          <ion-select required @ionChange="setCountry" aria-required="required" v-model="address.countryCode">
            <ion-select-option v-for="(country, key) in countries" :value="country.code" :key="key">
              {{ country.name }}
            </ion-select-option>
          </ion-select>
          <input v-model="address.countryCode" required style="opacity: 0; height: 1px; margin-top: -1px; display: block;">
        </ion-item>
        <ion-item>
          <ion-label position="floating">{{$tr(trCartForm, 'state') }}</ion-label>
          <ion-input v-model="address.state"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating"><ion-text color="danger">*</ion-text> {{$tr(trCartForm, 'city') }}</ion-label>
          <ion-input required v-model="address.city">
          </ion-input>
        </ion-item>
        <template v-if="isYandex">
          <ion-item>
            <ion-label position="floating"><ion-text color="danger">*</ion-text> {{$tr(trCartForm, 'street') }}</ion-label>
            <ion-input required v-model="address.street"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating"><ion-text color="danger">*</ion-text> {{$tr(trCartForm, 'house') }}</ion-label>
            <ion-input required v-model="address.house"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">{{$tr(trCartForm, 'housing') }}</ion-label>
            <ion-input v-model="address.housing"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">{{$tr(trCartForm, 'building') }}</ion-label>
            <ion-input v-model="address.building"></ion-input>
          </ion-item>
          <ion-item lines="none">
            <ion-label position="floating">{{$tr(trCartForm, 'apartment') }}</ion-label>
            <ion-input v-model="address.apartment"></ion-input>
          </ion-item>
        </template>
        <template v-else>
          <ion-item>
            <ion-label position="floating"><ion-text color="danger">*</ion-text> {{$tr(trCartForm, 'address') }}</ion-label>
            <ion-input required v-model="address.address1"></ion-input>
          </ion-item>
        </template>
        <ion-item v-if="isAuth">
          <ion-label>{{ $tr(trCartForm, 'addressSave') }}</ion-label>
          <ion-checkbox slot="end" v-model="address.isSave"></ion-checkbox>
        </ion-item>
      </ion-list>
    </template>
  </span>
  <div class="spin" v-else>
    <ion-spinner name="circles" />
  </div>
</template>

<script>
import {IonItem, IonInput, IonLabel, IonText, IonSelect, IonSelectOption, IonListHeader, IonList, IonSpinner, IonCard, IonCardContent, IonRadioGroup, IonRadio, IonButton, IonCheckbox} from "@ionic/vue";
import UserAddress from "../models/UserAddress";
import ApiService from "@/services/api.service";
import {defineComponent} from "vue";
import {trCartForm, trProfile} from "@/i18n/messages";

export default defineComponent({
  name: "UserAddresses",
  props: ['countries', 'isAuth', 'title'],
  data() {
    return {
      address: new UserAddress(),
      addAddress: this.isAuth ? false : true,
      showAll: false,
      addresses: [],
      defaultIndex: null,
      saveLoad: false
    }
  },
  components: {
    IonItem, IonInput, IonLabel, IonText, IonSelect, IonSelectOption, IonListHeader, IonList, IonSpinner, IonCard, IonCardContent, IonRadioGroup, IonRadio, IonButton, IonCheckbox
  },
  mounted() {
    this.loadAddresses()
    this.$emitter.on('address:save', () => {
      if(this.address.isSave && !this.saveLoad) {
        this.saveLoad = true
        ApiService.post('addresses', this.address).then(() => {
          this.saveLoad = false
        })
      }
    })
  },
  setup() {
    return {
      trCartForm, trProfile
    }
  },
  computed: {
    isYandex: function () {
      return this.address.country ? this.address.country.isYandex : false
    }
  },
  watch: {
    address: {
      deep: true,
      handler(newAddress) {
        this.$emitter.emit('address:upd', newAddress);
      }
    }
  },
  methods: {
    changeAddAddress() {
      if(!this.addAddress) {
        this.addAddress = true
        this.$emitter.emit('address:scroll');
      }
      this.defaultIndex = null
      this.$emitter.emit('address:upd', this.address);
    },
    changeDefault() {
      this.$emitter.emit('address:upd', this.addresses[this.defaultIndex]);
    },
    setCountry() {
      if(!this.countries) {
        return;
      }
      this.address.country = this.countries.find((country) => {
        return this.address.countryCode === country.code
      })
    },
    loadAddresses() {
      ApiService.get('addresses').then(res => {
        this.addresses = res.data.data
        if(this.addresses.length) {
          this.defaultIndex = 0
          this.$emitter.emit('address:upd', this.addresses[this.defaultIndex]);
        } else {
          this.changeAddAddress()
        }
      });
    }
  }
})
</script>

<style lang="scss" scoped>
  ion-card {
    &.active {
      background: #aec7ff!important;
      border: 2px solid #3948c1;
      ion-card-content {
        background: #aec7ff!important;
      }
      ion-item::part(native) {
        background-color: #aec7ff!important;
      }
    }
  }
</style>