<template>
  <ion-page>
      <toolbar :title="$tr(trCartForm, 'buy')" :backbutton="true"/>
      <ion-content fullpage id="container">
        <template v-if="countries">
          <form id="order" ref="order" @submit.prevent="saveOrder">
            <input type="submit" id="submit" value="Submit" style="display:none"/>
            <ion-list ref="listRef">
              <ion-list-header>
                <ion-text color="shiawasedo">
                  <h4>{{$tr(trCartForm, 'items')}}</h4>
                </ion-text>
              </ion-list-header>
              <ion-item v-for="(cartItem, key) in cartData" :key="key">
                <ion-grid>
                  <ion-row>
                    <ion-col size="7"><p v-html="cartItem.product.name"></p></ion-col>
                    <ion-col size="3"><h5 v-html="cartItem.product.price"></h5></ion-col>
                    <ion-col size="2">
                      <p>
                        x {{ cartItem.count }}
                      </p>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-item>
              <ion-item lines="full" style="margin-bottom: 20px;">
                <ion-label slot="end"><ion-text color="dark" style="font-size: 20px;">{{ $tr(trCart, 'total') }}: <b>¥{{total}}</b></ion-text></ion-label>
              </ion-item>
            </ion-list>
            <ion-list>
              <ion-list-header>
                <ion-text color="shiawasedo">
                  <h4 class="order-header">{{$tr(trCartForm, 'email')}}</h4>
                </ion-text>
              </ion-list-header>
              <ion-item lines="full">
                <ion-input placeholder="Email" type="email" required v-model="order.email" />
              </ion-item>
              <ion-item lines="full" v-if="profile && profile.isFirstOrder || !isAuth">
                <ion-label>{{$tr(trCartForm, 'firstOrder')}}</ion-label>
                <ion-checkbox
                    slot="end"
                    v-model="order.firstOrder">
                </ion-checkbox>
              </ion-item>
            </ion-list>
            <ion-list id="address">
              <ion-radio-group v-model="order.methodId" v-if="hasPayMethods" @ionChange="changePayMethod">
                <ion-list-header>
                  <ion-text color="shiawasedo">
                    <h4 class="order-header">{{$tr(trCard, 'payMethod')}}</h4>
                  </ion-text>
                </ion-list-header>
                <template v-for="(payMethod, key) in payMethods" :key="key">
                    <ion-card :class="order.methodId === payMethod.id ? 'active' : '' ">
                      <ion-card-content>
                        <ion-item lines="none">
                          <ion-icon v-if="payMethod.cardBrand === 'MasterCard'" slot="start" src="/assets/icon/mastercard.svg"></ion-icon>
                          <ion-icon v-else-if="payMethod.cardBrand === 'Visa'" slot="start" src="/assets/icon/visa.svg"></ion-icon>
                          <ion-icon v-else slot="start" :icon="card"></ion-icon>
                          <ion-label class="ion-text-wrap"><b>**** **** **** {{payMethod.cardLastFour}}, {{ payMethod.cardMonth + '/' + payMethod.cardYear.slice(-2) }}</b></ion-label>
                          <ion-radio mode="md" :value="payMethod.id" slot="end" />
                        </ion-item>
                        <ion-item lines="none">
                          <ion-label @click="order.methodId = payMethod.id" class="ion-text-wrap">{{payMethod.contactName + ', ' + payMethod.phone + ', ' + payMethod.postalCode + ', ' + payMethod.city + ', ' + payMethod.address}}</ion-label>
                        </ion-item>
                      </ion-card-content>
                    </ion-card>
                  </template>
                <ion-item :lines="hasPayMethods ? 'none' : ''">
                  <ion-label>
                    <ion-text style="white-space: normal">
                      {{ hasPayMethods ? $tr(trCard, 'otherPayAndAddress') : $tr(trCard, 'otherPay') }}
                    </ion-text>
                  </ion-label>
                  <ion-radio mode="md" :value="false" slot="end" />
                </ion-item>
                <ion-item v-if="hasPayMethods" lines="full">
                  <ion-text style="white-space: normal; margin-bottom: 10px; font-size: 16px" v-html="$tr(trCard, 'otherPayText')"></ion-text>
                </ion-item>
              </ion-radio-group>
              <div id="userAddresses" v-if="!order.methodId || !isAuth">
                <user-addresses :isAuth="isAuth" :countries="countries"/>
              </div>
              <ion-item :lines="addComment ? 'none' : 'full'" style="margin-top: 50px;">
                <ion-label>
                  {{ $tr(trCartForm, 'comment') }}
                </ion-label>
                <ion-checkbox v-model="addComment" />
              </ion-item>
              <ion-item v-if="addComment" lines="full">
                <ion-textarea v-model="order.comment" rows="10" />
              </ion-item>
              <ion-item :lines="addPostcard ? 'none' : 'full'" style="margin-top: 50px;">
                <ion-label>
                  {{ $tr(trCartForm, 'postcard') }}
                </ion-label>
                <ion-checkbox v-model="addPostcard" />
              </ion-item>
              <ion-item v-if="addPostcard" lines="full">
                <ion-textarea v-model="order.postcard" rows="10" />
              </ion-item>
            </ion-list>
          </form>
        </template>
        <div class="spin" v-else>
          <ion-spinner name="circles" />
        </div>
      </ion-content>
      <ion-footer>
        <ion-toolbar>
          <ion-item>
            <ion-label>
              <ion-text v-html="$tr(trCartForm, 'confirmPay')" />
            </ion-label>
            <ion-checkbox
                slot="end"
                v-model="confirmPay">
            </ion-checkbox>
          </ion-item>
        </ion-toolbar>
        <ion-toolbar>
          <ion-button type="submit" :disabled="!confirmPay" expand="block" color="shiawasedo">
            <label for="submit">
              <ion-icon slot="start"></ion-icon> {{$tr(trCartForm, 'process')}}
            </label>
          </ion-button>
        </ion-toolbar>
      </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonSpinner,
  IonRow,
  IonCol,
  IonGrid,
  IonIcon,
  IonText,
  IonLabel,
  IonList,
  IonButton,
  IonItem,
  IonCheckbox,
  alertController,
  IonFooter,
  IonToolbar,
  IonRadio,
  IonRadioGroup,
  IonCard,
  IonCardContent,
  IonInput,
  IonListHeader,
  IonTextarea, loadingController
} from "@ionic/vue"
import {trCart, trCartForm, trProfile, trCard} from "@/i18n/messages";
import Toolbar from "@/components/Toolbar";
import {CartService} from "@/services/cart.service";
import {mapActions} from "vuex";
import { logoApple, card } from 'ionicons/icons';
import {useRouter} from "vue-router";
import ApiService from "@/services/api.service";
import {OrderService} from "@/services/order.service";
import { defineComponent } from 'vue'
import {AuthService} from "@/services/auth.service";
import UserAddresses from "@/components/UserAddresses";
export default defineComponent({
  name: "Order",
  components: {
    UserAddresses,
    IonPage, IonContent, IonSpinner, Toolbar, IonRow, IonCol, IonGrid, IonIcon, IonText, IonLabel, IonList, IonButton, IonItem, IonCheckbox, IonFooter, IonToolbar, IonRadio, IonRadioGroup, IonCard, IonCardContent, IonInput, IonListHeader, IonTextarea
  },
  methods: {
    ionViewDidEnter() {
      this.$emitter.emit('update:wallet')
      this.$emitter.on('update:cart', () => {
        this.total =  CartService.total();
        this.cartData = CartService.getCart();
      });
    },
    ionViewWillEnter() {
      if(AuthService.isAuth()) {
        this.loadProfile();
        ApiService.get('payMethods').then(res => {
          if(res.data && res.data.data && res.data.data.length) {
            this.payMethods = res.data.data
          } else {
            this.order.methodId = null
          }
        });
      }
      ApiService.get('countries').then(res => {
        this.countries = res.data.data
      })
    },
    async changePayMethod() {
      if(this.order.methodId === false) {
        const loading = await loadingController.create({});
        await loading.present();
        const offsetTop = document.getElementById("address").offsetTop + document.getElementById("address").offsetHeight
        setTimeout(async () => {
          await loading.dismiss();
          this.$el.querySelector("#container").scrollToPoint(0, offsetTop, 1000);
        }, 1000)
      }
    },
    getPayMethod() {
      if(this.hasPayMethods) {
        return this.payMethods.filter(method => {
          return this.order.methodId === method.id
        })[0]
      }
      return null;
    },
    setCountry() {
      if(!this.countries) {
        return;
      }
      return this.order.country = this.countries.find((country) => {
        return this.order.countryCode === country.code
      })
    },
    saveOrder() {
      const payMethod = this.getPayMethod()
      if(this.order.methodId) {
        this.order.countryCode = payMethod.deliveryCountryCode
        const names = payMethod.contactName.split(' ')
        this.order.lastName = names.length ? names[0] : ''
        this.order.firstName = names.length > 1 ? names[1] : ''
        this.order.middleName = names.length > 2 ? names[2] : ''
        this.order.postalCode = payMethod.postalCode
        this.order.address = payMethod.address
        this.order.house = payMethod.house
        this.order.housing = payMethod.housing
        this.order.phone = payMethod.phone
        this.order.city = payMethod.city
        this.order.email = payMethod.contactEmail
        this.order.apartment = payMethod.apartment
        this.order.building = payMethod.building
        this.order.street = payMethod.street
        this.order.state = payMethod.state
        this.setCountry()
      }
      this.order.countryCode = this.order.country.code
      OrderService.set(this.order)
      if(!this.order.methodId) {
        this.$emitter.emit('address:save')
      }
      this.router.push('/confirm')
    },
    ...mapActions("profile", ["get", "post"]),
    async loadProfile() {
      await this.get().then((res) => {
        this.profile = res.data.data
        this.order.firstName = this.profile.firstName
        this.order.middleName = this.profile.middleName
        this.order.lastName = this.profile.lastName
        this.order.email = this.profile.email
      });
    },
    async errorAlert() {
      const alert = await this.alertController.create({
        header: 'Failed!',
        message: this.$tr(trCartForm, 'chooseCountry'),
        buttons: ['OK'],
      });
      await alert.present();
    }
  },
  mounted() {
    this.$emitter.on('address:upd', (address) => {
      if(address) {
        this.order = {...this.order, ...address}
        this.setCountry()
      }
    })
    this.$emitter.on('address:scroll', async () => {
      const loading = await loadingController.create({});
      await loading.present();
      let offsetTop = 0
      if(!this.hasPayMethods) {
        offsetTop = document.getElementById("address").offsetTop
      } else {
        offsetTop = document.getElementById("userAddresses").offsetTop
      }
      setTimeout(async () => {
        await loading.dismiss();
        // eslint-disable-next-line no-undef
        this.$el.querySelector("#container").scrollToPoint(0, offsetTop, 1000);
      }, 1000)
    })
  },
  data() {
    return {
      profile: null,
      total:  CartService.total(),
      cartData: CartService.getCart(),
      canMakePayments: false,
      order: OrderService.get(),
      countries: null,
      payMethods: null,
      confirmPay: false,
      isAuth: AuthService.isAuth(),
      addComment: false,
      addPostcard: false
    }
  },
  setup() {
    const router = useRouter()
    return {
      trCart, trCartForm, logoApple, trProfile, router, alertController, trCard, card
    }
  },
  computed: {
    hasPayMethods() {
      return this.payMethods && this.payMethods.length;
    }
  }
})
</script>