import Country from "@/models/Country";

export default class UserAddress {
    public id = ''
    public userId = ''
    public firstName = ''
    public middleName = ''
    public lastName = ''
    public phone = ''
    public postalCode = ''
    public countryCode = ''
    public state = ''
    public city = ''
    public address1 = ''
    public address2 = ''
    public street = ''
    public house = ''
    public housing = ''
    public building = ''
    public apartment = ''
    public isDefault = false
    public created = ''
    public deleted = false
    public timestamp = ''
    public dataSetClassName = ''
    public country: Country|any = null
    public toString = ''
    public contact = ''
    public isSave = false
}