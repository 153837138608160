<template>
  <ion-header translucent>
    <ion-toolbar>
      <ion-buttons v-if="backbutton" slot="start">
        <ion-back-button type="reset" default-href="/" :text="$tr(trMenu, 'backButton')" color="shiawasedo"></ion-back-button>
      </ion-buttons>
      <ion-buttons v-else slot="start">
        <ion-button color="shiawasedo" @click="$root.openMenu()">
          <ion-icon slot="icon-only" :icon="menu"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title v-if="routeName === 'Timeline'">
        <ion-segment v-if="$isAuth" @ionChange="changeLike" v-model="changeLikeData" value="all">
          <ion-segment-button value="all">{{ $tr(trToolbar, 'all') }}</ion-segment-button>
          <ion-segment-button value="favorites">{{ $tr(trToolbar, 'favorites') }}</ion-segment-button>
        </ion-segment>
        <template v-else v-html="filters.striptags(title)"></template>
      </ion-title>
      <ion-title v-if="routeName !== 'Timeline' && routeName !== 'Shop' && title" v-html="filters.striptags(title)"></ion-title>
      <ion-searchbar v-model="searchQuery" id="search" v-if="routeName === 'Shop'"></ion-searchbar>
      <cart />
    </ion-toolbar>
  </ion-header>
</template>

<script>
import {trToolbar} from '@/i18n/messages';
import {useRouter} from "vue-router";
import { menu, cartOutline } from 'ionicons/icons';
import { IonIcon, IonButton, IonButtons, IonHeader, IonToolbar, IonBackButton, IonTitle, IonSegment, IonSegmentButton, IonSearchbar } from '@ionic/vue';
import Cart from "@/components/Cart";
import { trMenu } from "@/i18n/messages";
import filters from "@/filters/filters";

export default {
  name: "Toolbar",
  components: {Cart, IonIcon, IonButton, IonButtons, IonHeader, IonToolbar, IonBackButton, IonTitle, IonSegment, IonSegmentButton, IonSearchbar },
  props: {
    backbutton: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      changeLikeData: 'all',
      searchQuery: null
    }
  },
  setup() {
    const router = useRouter();
    const routeName = router.currentRoute.value.name;
    return {
      trToolbar,
      router,
      menu,
      routeName,
      cartOutline,
      trMenu,
      filters
    }
  },
  methods: {
    changeLike() {
      this.$emitter.emit('like:change', this.changeLikeData === 'favorites');
    },
    addToCart() {
      this.$emitter.emit('addTo:cart');
    },
  },
  watch: {
    searchQuery(val) {
      this.$emitter.emit('search:change', val);
    }
  }
}
</script>

<style lang="scss">
.hcs {
  display: flex;
  flex-direction: row;
  align-items: center;
  .logo-image {
    width: 35px;
    margin-right: 10px;
  }
}
//makes header bigger site wide on ionic serve
ion-navbar.toolbar {
  min-height: 110px!important;
}
//on Android
ion-navbar.toolbar-md {
  min-height: 80px!important;
}
#search {
  .searchbar-input.sc-ion-searchbar-md {
    padding: 6px 6px 6px 30px;
  }
  .searchbar-search-icon.sc-ion-searchbar-md {
    left: 6px;
  }
}
ion-segment {
  margin-bottom: 5px;
}
:host {
  //--padding-bottom: 10px;
  //--min-height: 40px;
}
</style>
