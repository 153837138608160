<template>
  <ion-app v-if="!splashShow">
    <ion-modal
        css-class="scan-modal"
        :is-open="openModal"
        @didDismiss="setOpen(false)"
    >
      <scaner/>
    </ion-modal>
    <ion-menu v-show="!scannerShow" side="start" menu-id="menu" transition="fadeIn" content-id="main" class="app-menu">
      <ion-content class="ion-padding">
        <ion-list>
          <ion-item class="logo-item" lines="none">
            <main-logo/>
          </ion-item>
          <ion-item class="choose-lang">
            <ion-icon :icon="language" slot="start"></ion-icon>
            <ion-button @click="setLanguage('ru')" :color="lang === 'ru' ? 'shiawasedo' : 'default'">RU</ion-button>
            <ion-button @click="setLanguage('en')" :color="lang === 'en' ? 'shiawasedo' : 'default'">EN</ion-button>
            <ion-button @click="setLanguage('ja')" :color="lang === 'ja' ? 'shiawasedo' : 'default'">JA</ion-button>
          </ion-item>
          <ion-item v-if="AuthService.isAuth()" button @click="navi('/profile')">
            <ion-icon :icon="person" slot="start"></ion-icon>
            <ion-label>{{ $tr(trMenu, 'profile') }}</ion-label>
          </ion-item>
          <ion-item v-if="AuthService.isAuth()" button @click="navi('/orders')">
            <ion-icon :icon="list" slot="start"></ion-icon>
            <ion-label>{{ $tr(trMenu, 'orders') }}</ion-label>
          </ion-item>
          <ion-item v-if="AuthService.isAuth() && walletAmount !== null" button @click="navi('/wallet')">
            <ion-icon src="/assets/icon/wallet.svg" slot="start"></ion-icon>
            <ion-label>{{ $tr(trCartForm, 'wallet') }} {{ walletAmount }}</ion-label>
          </ion-item>
          <ion-item @click="setOpen(true)">
            <ion-icon src="/assets/icon/barcode.svg" slot="start"></ion-icon>
            <ion-label v-html="$tr(trMenu, 'scan')"></ion-label>
          </ion-item>
          <template v-if="AuthService.isAuth()">
            <ion-item button @click="navi('/account')">
              <ion-icon :icon="shieldHalfSharp" slot="start"></ion-icon>
              <ion-label>{{ $tr(trMenu, 'account') }}</ion-label>
            </ion-item>
          </template>
          <template v-else>
            <ion-item button @click="navi('/login')">
              <ion-icon :icon="enter" slot="start"></ion-icon>
              <ion-label>{{ $tr(trSignIn, 'enter') }}</ion-label>
            </ion-item>
            <ion-item button @click="navi('/signup')">
              <ion-icon :icon="personAdd" slot="start"></ion-icon>
              <ion-label>{{ $tr(trSignIn, 'createAccount') }}</ion-label>
            </ion-item>
          </template>
          <ion-item v-if="AuthService.isAuth()" style="margin: 45px -10px 0; font-size: 80%; text-align: left" lines="none">
            <span v-html="$tr(trMenu, 'bottom')"></span>
          </ion-item>
        </ion-list>
      </ion-content>
      <ion-footer v-if="version">
        <ion-item lines="none">
          <ion-label class="text-center">
            {{ $tr(trMenu, 'version') }} {{ version }} {{ environment }}
          </ion-label>
        </ion-item>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet v-show="!scannerShow" transition="fadeIn" id="main" />
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonContent,
  IonItem,
  IonList,
  IonMenu,
  IonRouterOutlet,
  menuController,
  IonIcon,
  IonLabel,
  IonButton,
  alertController,
  loadingController,
  IonModal,
    IonFooter
} from '@ionic/vue';
import {logOut, person, language, barcode, enter, card, list, personAdd, shieldHalfSharp} from 'ionicons/icons';
import { defineComponent } from 'vue';
import {useRouter} from "vue-router";
import {LanguageService} from "@/services/language.service";
import { App } from '@capacitor/app'
import { SplashScreen } from '@capacitor/splash-screen'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Device } from '@capacitor/device'
import { PushNotifications } from '@capacitor/push-notifications'
import {trMenu, trSignIn, trCartForm} from '@/i18n/messages'
import { inject } from 'vue';
import {AuthService} from "@/services/auth.service";
import {TokenService} from "@/services/token.service";
import ApiService from "@/services/api.service";
import Scaner from "@/components/Scaner";
import {OrderService} from "@/services/order.service";
import {UpdateService} from "@/services/update.service";
import {DeviceService} from "@/services/device.service";
import {AppVersion} from "@ionic-native/app-version";
import MainLogo from "@/components/MainLogo";
export default defineComponent({
  name: 'App',
  components: {
    MainLogo,
    Scaner,
    IonApp,
    IonContent,
    IonItem,
    IonList,
    IonMenu,
    IonRouterOutlet,
    IonIcon,
    IonLabel,
    IonButton,
    IonModal,
    IonFooter,
  },
  data() {
    return {
      splashShow: true,
      environment: process.env.VUE_APP_BACK_URL !== 'https://www.shiawasedo.co.jp/' ? 'dev' : '',
      ready: false,
      lang: LanguageService.getLang(),
      firstTime: localStorage.getItem('firstTime') !== 'false',
      deviceModel: null,
      deviceAppId: null,
      timeout: 1000,
      scannerShow: false,
      openModal: false,
      version: null,
      walletAmount: null,
    }
  },
  async mounted() {

    console.log('TokenService.getFcmToken() = ' + TokenService.getFcmToken());

    async function getDeviceInfo() {
      return await Device.getInfo();
    }

    async function getDeviceId() {
      const id = (await Device.getId())
      return id.identifier || id.uuid;
    }

    const info = await getDeviceInfo()

    DeviceService.set(info.model)

    if(info.platform !== 'web') {
      PushNotifications.addListener(
          'registration',
          async (token) => {
            const info = await getDeviceInfo()
            const uuid = await getDeviceId()

            if(token.value !== TokenService.getFcmToken()) {
              TokenService.setFcmToken(token.value)
              const request = {
                token: TokenService.getFcmToken(),
                language: LanguageService.getLang(),
                userId: TokenService.getUser(),
                appId: info.appId,
                uuid: uuid,
                deviceName: info.name,
                deviceModel: info.model
              }
              console.log(token)
              console.log('FCM request 1', request)
              const res = await ApiService.post('fcm', request)
              console.log('FCM response', res)
            }
          },
      );

      PushNotifications.addListener('registrationError', (error) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      });

      PushNotifications.addListener(
          'pushNotificationReceived',
          (notification) => {
            console.log('Push received: ' + JSON.stringify(notification));
          },
      );

      PushNotifications.addListener(
          'pushNotificationActionPerformed',
          async (notification) => {
            const url = notification.notification.data.url

            if(!url.includes('summusbeauty.shiawasedo.co.jp')) {
              if(url.includes('shiawasedo.co.jp') && AuthService.isAuth()) {
                window.open(url + (url.includes('?') ? '&' : '?') + 'access_token=' + TokenService.getToken(), '_system', 'location=yes');
              } else {
                window.open(url, '_system', 'location=yes');
              }
            } else if(url.includes('/feed/')) {
              const id = url.split('co.jp/feed/').pop()
              window.location.href = '/timeline/' + id
            } else {
              window.location.href = url.split('co.jp').pop()
            }
          },
      );

      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      console.log('permStatus.receive = ' + permStatus.receive)

      if (permStatus.receive !== 'granted') {
        console.log('User denied permissions!')
      }

      await PushNotifications.register();

      // Обновляемся каждый раз, если есть токен
      if(TokenService.getFcmToken()) {
        const info = await getDeviceInfo()
        const uuid = await getDeviceId()

        const request = {
          token: TokenService.getFcmToken(),
          language: LanguageService.getLang(),
          userId: TokenService.getUser(),
          appId: info.appId,
          uuid: uuid,
          deviceName: info.name,
          deviceModel: info.model
        }
        await ApiService.post('fcm', request)
      }
    }

    window.scrollTo(0, 0);
    this.getVersion().then((version) => {
      this.version = version
    })
    if(AuthService.isAuth()) {
      ApiService.get('wallet').then(res => {
        this.walletAmount = res.data.data.amount.text
      })
    }
    UpdateService.checkForUpdate()
    SplashScreen.hide()
    StatusBar.setStyle({
      style: Style.Light
    });
    StatusBar.setOverlaysWebView({
      overlay: true
    });

    setTimeout(() => {
      this.splashShow = false
    },800)
    this.$emitter.on('scan:stop', () => {
      this.setOpen(false)
    });
  },
  setup() {
    const router = useRouter();
    const ionRouter = inject('navManager');
    App.addListener('appUrlOpen', async function (data) {
      if(data.url.indexOf('jp.co.shiawasedo.app') > -1) {
        const link = data.url.split('jp.co.shiawasedo.app:/').pop()
        return window.location.href = link
      }

      const loading = await loadingController.create({
        duration: 2000
      });
      await loading.present();

      if(data.url.indexOf('/feed/') === -1) {
        const codeUrl = data.url.split('co.jp/').pop()
        return window.location.href = codeUrl
      }

      const id = data.url.split('co.jp/feed/').pop()
      if (id) {
        return window.location.href = '/timeline/' + id
      }
    });

    return {
      router,
      logOut,
      enter,
      person,
      language,
      trMenu,
      trSignIn,
      ionRouter,
      barcode,
      alertController,
      AuthService,
      card,
      list,
      personAdd,
      shieldHalfSharp,
      trCartForm
    };
  },
  methods: {
    async getVersion() {
      return await AppVersion.getVersionNumber()
    },
    getLangId(lang) {
      if(lang === 'ru') {
        return 2
      }
      if(lang === 'ja') {
        return 3
      }
      return 1
    },
    async setLanguage(lang) {
      this.lang = lang
      if(AuthService.isAuth()) {
        const loading =  await loadingController.create({})
        await loading.present()
        ApiService.post('profile', {
          langId: this.getLangId(this.lang)
        }).then(res => {
          if(res.status === 200) {
            LanguageService.setLang(lang)
          }
          loading.dismiss()
        }).catch(() => {
          loading.dismiss()
        })
      } else {
        OrderService.clear()
        LanguageService.setLang(lang)
      }
    },
    setOpen(state) {
      menuController.enable(false, 'menu')
      this.scannerShow = state
      this.openModal = state
    },
    openMenu() {
      menuController.enable(true, 'menu');
      menuController.open('menu')
    },
    async navi(state) {
      await menuController.enable(false, 'menu')
      return await this.ionRouter.navigate({
        routerDirection: 'forward',
        routerLink: state,
        transitionName: 'slide-right'
      });
    }
  }
});
</script>
<style lang="scss">
.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  .col {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  align-items: center;
}
</style>
