<template>
    <ion-page>
      <toolbar :title="$tr(trSignIn, 'enter')" :backbutton="true" />
      <login/>
    </ion-page>
</template>

<script>
import { IonPage } from '@ionic/vue'
import {trSignIn} from '@/i18n/messages'
import Toolbar from "@/components/Toolbar";
import Login from "@/components/Login";

export default {
  name: 'SignIn',
  components: {Login, Toolbar, IonPage},
  setup() {
    return { trSignIn }
  }
}
</script>
<style lang="scss">
</style>
