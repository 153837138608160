<template>
  <ion-page>
    <toolbar :title="data ? data.title : '...'" :backbutton="true" />
    <ion-content fullscreen class="timeline-inside-content" :class="data && data.type.id === POST_TYPES.CATALOG ? 'shop' : ''">
      <span ref="tlRef">
        <template v-if="data">
          <div class="timeline-inside-data">
            <div class="timeline-header" :class="data.darkTheme ? 'text-white' : ''" :style="{backgroundImage: $getBg(data.mainImage)}">
              <timeline-poll class="inside-poll" v-if="data.type.id === POST_TYPES.POLL" :data="data"/>
              <timeline-like-short-share class="inside-share" :data="data" />
            </div>
            <div v-if="data.type.id !== POST_TYPES.PRODUCT" class="timeline-body">
              <h1>{{ filters.striptags(data.title) }}</h1>
              <h4 v-html="filters.striptags(data.lead)"></h4>
              <div v-html="data.body"></div>
              <div class="timeline-shop-wrapper">
                <shop :is-search="false" :short-desc="true" :products="data.data" v-if="data.type.id === POST_TYPES.CATALOG && data.data" />
              </div>
            </div>
            <div v-else class="timeline-body">
              <product :body="data.body" :product="data.data" />
            </div>
          </div>
        </template>
        <div class="spin" v-else>
          <ion-spinner name="circles" />
        </div>
      </span>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonSpinner} from '@ionic/vue';
import {closeOutline, heart, heartOutline} from 'ionicons/icons';
import POST_TYPES from '@/components/timeline/PosTypes';
import Toolbar from '@/components/Toolbar';
import ApiService from "@/services/api.service";
import filters from "@/filters/filters";
import TimelineLikeShortShare from "@/components/timeline/TimelineLikeShortShare";
import Product from "@/components/Product";
import TimelinePoll from "@/components/timeline/TimelinePoll";
import Shop from "@/components/Shop";
import {MetaTags} from "@/services/meta.tags";
export default {
  name: 'TimelineInside',
  components: {
    Shop,
    TimelinePoll,
    Product,
    TimelineLikeShortShare,
    IonContent,
    IonPage,
    Toolbar,
    IonSpinner
  },
  setup() {
    return {closeOutline, POST_TYPES, heart, heartOutline, filters}
  },
  data() {
    return {
      data: null
    }
  },
  mounted() {
    this.load()
    this.$emitter.on('timeline:upd', () => {
      this.load()
    })
  },
  methods: {
    load() {
      ApiService.get(`timeline?id=${this.$route.params.id}`).then(res => {
        if(!res.data.data.length) {
          return this.$root.navi('/timeline')
        }
        this.data = res.data.data[0]
        const el = this.$refs.tlRef
        document.title = 'Summus Beauty Lab - ' + filters.striptags(this.data.title)
        MetaTags.setTag('title');
        MetaTags.setTag('url');
        MetaTags.setTag('img', window.location.origin + this.data.mainImage);
        el.addEventListener('click', event => {
          const hasHref = event.target.hasAttribute('href');
          const href = event.target.getAttribute('href');
          if (hasHref && href.indexOf('https://') === -1) {
            event.preventDefault();
            this.$root.navi(event.target.getAttribute('href'))
          } else {
            return true;
          }
        });
      })
    },
    close() {
      this.$emitter.emit('modal:close')
    }
  },
  watch: {
    '$route': function(oldNav, newNav) {
      // Если жмем ссылки в одной локали
      if(oldNav.name === 'TimelineInside' && newNav.name === 'TimelineInside' && oldNav.path !== newNav.path) {
        this.data = null;
        this.load()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
h4 {
  font-size: 14px;
}
.like-btn {
  z-index: 1;
  bottom: 20px;
  left: 10px;
  position: fixed;
  ion-button {
    background-color: var(--ion-color-shiawasedo);
    border-radius: 50%;
    width: 35px!important;
    height: 35px!important;
    padding: 0!important;
    vertical-align: middle;
    padding: 0!important;
  }
}
.timeline-inside-content {
  .timeline-inside-data {
    .timeline-header {
      position: relative;
      width: 100%;
      height: calc(100vw * 1.25);
      padding: 50px 5% 5%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      h1,h4 {
        margin: 0 0 5px;
      }
      .inside-share {
        position: absolute;
        bottom: 20px;
      }
      .inside-poll {
        padding: 0 20px;
        position: absolute;
        bottom: 65px;
        left: 0;
        width: 100%;
      }
    }
    .timeline-body {
      z-index: -1;
      padding: 10px 5% 10px;
      font-size: 18px;
      margin-bottom: 70px;
      .timeline-shop-wrapper {
        margin: 20px -15px 0;
      }
    }
  }
}
</style>
