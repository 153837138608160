<template>
  <form v-if="data" class="form-pool">
    <ion-list-header v-if="data.body">
      <ion-label>{{data.body}}</ion-label>
    </ion-list-header>
    <div class="d-table">
      <div class="d-table-row">
        <div tappable v-for="(pool, index) in data.data" :key="index" class="d-table-cell" :style="{width: getWidth(pool.percent)}" :class="pool.isAnswer ? 'answered' : ''" @click="sendPool(pool.id)">
          <div v-if="isAnswer || isFinished">{{pool.percent}}%</div>
          {{pool.answer}}
        </div>
      </div>
    </div>
    <p v-if="isFinished">{{ $tr(trPoll, 'isFinished') }}</p>
  </form>
</template>

<script>
import {IonLabel, IonListHeader, loadingController} from "@ionic/vue";
import ApiService from "@/services/api.service";
import {AuthService} from "@/services/auth.service";
import {trPoll} from "@/i18n/messages";
export default {
  name: "TimelinePoll",
  props: ['data'],
  components: {IonLabel, IonListHeader},
  data() {
    return {
      isAnswer: null,
      isFinished: false
    }
  },
  setup() {
    return { trPoll }
  },
  mounted() {
    if(this.data.extra && (new Date(this.data.extra).getTime() < new Date().getTime())) {
      this.isFinished = true
    }
    this.data.data.forEach((answer) => {
      if(answer.isAnswer) {
        this.isAnswer = answer.id
      }
    })
  },
  methods: {
    getWidth(percent) {
      if(this.data.data.length === 3) {
        if(!this.isAnswer && !this.isFinished) {
          return '33%'
        }
        if(percent < 25) {
          return '25%'
        } else if(percent > 25 && percent <= 50) {
          return '33%'
        } else {
          return '42%'
        }
      }
      if(this.data.data.length === 2) {
        if(!this.isAnswer && !this.isFinished) {
          return '33%'
        }
        if(percent < 25) {
          return '25%'
        } else {
          return '50%'
        }
      }
      return percent + '%';
    },
    async sendPool(id) {
      if(this.isAnswer || !AuthService.isAuth() || this.isFinished) {
        return
      }
      const loading = await loadingController.create({})
      await loading.present();
      ApiService.post('poll', {id: id}).then(res => {
        if(res.status === 200) {
          this.$emitter.emit('timeline:upd')
          this.isAnswer = true
          loading.dismiss();
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-pool {
  margin-top: 20px;
  .d-table {
    display: table;
    width: 100%;
    min-height: 48px;
    border-spacing: 2px;
    .d-table-row {
      text-align: center;
      display: table-row;
      .d-table-cell {
        backdrop-filter: blur(10px);
        background: rgba(128,128,128, 0.4);
        display: table-cell;
        vertical-align: middle;
        font-size: 14px;
        min-width: 25%;
        padding: 5px;
        &.answered {
          background: #fff;
          color: #333;
          border: 1px solid #333;
        }
        &:first-child {
          border-radius: 24px 0 0 24px;
          margin-right: 1%;
        }
        &:last-child {
          border-radius: 0 24px 24px 0;
          margin-left: 1%;
        }
      }
    }
  }
}
</style>
