<template>
  <template v-if="products.length && !loading">
    <div class="d-table" v-for="(products, index) in productChunks" :key="index">
      <div class="d-table-row">
        <div class="d-table-cell">
          <div v-if="products[0].isAlmostOutOfStock" class="corner">
            <div class="corner-text" v-html="$tr(trProduct, 'end')"></div>
          </div>
          <image-loader @click="goToProduct(products[0])" class="product-image" :src="products[0].mainImage" />
        </div>
        <div v-if="products[1]" class="d-table-cell">
          <div v-if="products[1].isAlmostOutOfStock" class="corner">
            <div class="corner-text" v-html="$tr(trProduct, 'end')"></div>
          </div>
          <image-loader @click="goToProduct(products[1])" class="product-image" :src="products[1].mainImage" />
        </div>
        <div v-else class="d-table-cell empty"></div>
      </div>
      <div class="d-table-row">
        <div class="d-table-cell" @click="goToProduct(products[0])">
          <div class="product-title" v-html="products[0].name"></div>
        </div>
        <div v-if="products[1]" class="d-table-cell" @click="goToProduct(products[1])">
          <div class="product-title" v-html="products[1].name"></div>
        </div>
        <div v-else class="d-table-cell empty"></div>
      </div>
      <div class="d-table-row">
        <div class="d-table-cell">
          <div v-if="products[0].size" class="product-size" v-html="products[0].size"></div>
        </div>
        <div v-if="products[1]" class="d-table-cell">
          <div  v-if="products[1].size" class="product-size" v-html="products[1].size"></div>
        </div>
        <div v-else class="d-table-cell empty"></div>
      </div>
      <div class="d-table-row" v-if="shortDesc">
        <div class="d-table-cell">
          <div v-if="products[0].shortDescription" class="product-short-description" v-html="products[0].shortDescription"></div>
        </div>
        <div v-if="products[1]" class="d-table-cell">
          <div  v-if="products[1].shortDescription" class="product-short-description" v-html="products[1].shortDescription"></div>
        </div>
        <div v-else class="d-table-cell empty"></div>
      </div>
      <div class="d-table-row">
        <div class="d-table-cell">
          <div v-if="products[0].isDiscount" class="product-real-price">
            ¥{{ products[0].realPrice }}
          </div>
          <div class="product-price" @click="goToProduct(products[0])" v-html="products[0].price"></div>
        </div>
        <div v-if="products[1]" class="d-table-cell">
          <div v-if="products[1].isDiscount" class="product-real-price">
            ¥{{ products[1].realPrice }}
          </div>
          <div class="product-price" @click="goToProduct(products[1])" v-html="products[1].price "></div>
        </div>
        <div v-else class="d-table-cell empty"></div>
      </div>
      <div class="d-table-row">
        <div class="d-table-cell">
          <div class="product-currencies" @click="goToProduct(products[0])" v-html="products[0].currenciesHTMLShort"></div>
        </div>
        <div v-if="products[1]" class="d-table-cell">
          <div class="product-currencies" @click="goToProduct(products[1])" v-html="products[1].currenciesHTMLShort"></div>
        </div>
        <div v-else class="d-table-cell empty"></div>
      </div>
      <div class="d-table-row">
        <div v-if="products[0]" class="d-table-cell button-block">
          <cart-btn :product="products[0]" />
        </div>
        <div v-if="products[1]" class="d-table-cell button-block">
          <cart-btn :product="products[1]" />
        </div>
        <div v-else class="d-table-cell empty"></div>
      </div>
    </div>
  </template>
  <div class="spin" v-else>
    <ion-spinner name="circles" />
  </div>
</template>

<script>
import { IonSpinner } from '@ionic/vue';
import _ from "lodash";
import {cartOutline, addCircle, removeCircle} from 'ionicons/icons';
import {mapActions} from "vuex";
import ImageLoader from "@/components/ImageLoader.vue";
import {useRouter} from "vue-router";
import {trCart, trProduct} from '@/i18n/messages'
import Fuse from "fuse.js";
import CartBtn from "@/components/CartBtn";

export default {
  name: 'Shop',
  components: {CartBtn, ImageLoader, IonSpinner},
  props: {
    products: {
      type: Array,
      default: null
    },
    isSearch: {
      type: Boolean,
      default: true
    },
    shortDesc: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      querySearch: null,
      loading: false
    }
  },
  setup() {
    const router = useRouter()
    return {
      cartOutline, addCircle, removeCircle, router, trCart, trProduct
    }
  },
  mounted() {
    this.$emitter.on('search:change', (query) => {
      if(this.isSearch) {
        this.querySearch = query
      }
    });
  },
  computed: {
    productChunks() {
      return _.chunk(Object.values(this.filtered()), 2);
    }
  },
  methods: {
    goToProduct(product) {
      if(product.isAction && product.url) {
        return window.location.href = product.url
      }
      this.router.push(`/product/${product.id}`)
    },
    ...mapActions("products", ["getList"]),
    filtered() {
      if(this.querySearch) {
        const options = {
          includeScore: true,
          keys: ['name']
        }
        const fuse = new Fuse(this.products, options)
        const result = fuse.search(this.querySearch)
        return result.map(item => {
          return item.item
        });
      }
      return this.products
    },
  },
  watch: {
    querySearch() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>
<style lang="scss">
.ion-padding-top-shop {
  padding-top: 1px;
}
.shop {
  background-color: #f5f5f5;
  .d-table {
    border-top: 6px solid #f5f5f5;
    width: 100%;
    display: table;
    &:last-child {
      border-bottom: 6px solid #f5f5f5;
    }
    .d-table-row {
      display: table-row;
      .d-table-cell {
        .corner {
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 45px;
          border-color: #e21d41 transparent transparent #e21d41;
          color: #fff;
          .corner-text {
            display: block;
            position: relative;
            text-align: center;
            top: -45px;
            left: -45px;
            font-size: 12px;
            font-weight: 600;
            padding-top: 5px;
            width: 90px;
            height: 90px;
            transform: rotate(-45deg);
          }
        }
        position: relative;
        vertical-align: middle;
        width:50%;
        display: table-cell;
        text-align: center;
        background-color: #fff;
        &.empty {
          background-color: #f5f5f5;
        }
        &:first-child {
          border-left: 6px solid #f5f5f5;
          border-right: 3px solid #f5f5f5;
        }
        &:last-child {
          border-right: 6px solid #f5f5f5;
          border-left: 3px solid #f5f5f5;
        }
        &.button-block {
          text-align: center;
          padding: 12px 6px 3px;
          height: 55px;
          ion-button {
            font-size: 12px;
            height: 32px;
            text-transform: none;
          }
          ion-buttons {
            ion-button {
              width: 34%;
              &:first-child, &:last-child {
                width: 33%;
              }
            }
          }
        }
        .product-title {
          font-size: 15px;
          font-weight: 600;
          margin: 0px 0 5px;
          padding: 0 2px;
        }
        .product-image {
          padding-top: 10px;
          height: 120px;
          text-align: center;
          width: 100%;
          margin: 0 0 5px!important;
        }
        .product-size {
          font-size: 10px;
          padding: 0 2px;
        }
        .product-short-description {
          padding: 5px 2px;
          font-size: 12px;
        }
        .product-price {
          font-weight: 500;
          color: #666666;
          margin: 10px 0 10px;
        }
        .product-currencies {
          font-size: 11px;
        }
        .product-real-price {
          font-size: 13px;
          text-decoration: line-through;
          margin: 5px 0 -5px;
        }
      }
    }
  }
}
</style>
