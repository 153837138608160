<template>
  <div class="feed-body">
    <template v-for="(item, index) in items" :key="index">
      <div v-bind:style="{backgroundImage: $getBg(item.mainImage)}" v-if="item.type.id === POST_TYPES.POLL || item.contentType === 'over_image'" class="feed-card" :class="item.type.id === POST_TYPES.POLL ? 'poll-card' : ''" tappable>
        <div @click.self="toPost(item)" class="feed-card-content" :class="item.darkTheme ? 'text-white' : ''">
            <div :class="item.type.id === POST_TYPES.POLL ? 'poll-block' : ''">
              <h1 @click="toPost(item)" v-html="item.title"></h1>
              <h2 @click="toPost(item)" v-html="item.lead"></h2>
              <timeline-poll v-if="item.type.id === POST_TYPES.POLL" :data="item" />
            </div>
          <timeline-like-share class="feed-card-footer" :data="item"></timeline-like-share>
        </div>
      </div>
      <div v-else-if="item.contentType === 'normal'" class="feed-card">
        <div @click.self="toPost(item)" class="feed-card-content feed-card-content-normal" :class="item.darkTheme ? 'text-white' : ''">
          <h1 @click="toPost(item)" v-html="item.title"></h1>
          <h2 @click="toPost(item)" v-html="item.lead"></h2>
          <image-loader class="feed-image-normal" :src="item.mainImage" />
          <timeline-like-share class="feed-card-footer feed-content-normal" :data="item"></timeline-like-share>
        </div>
      </div>
      <div v-else class="feed-card" v-bind:style="{backgroundImage: $getBg(item.mainImage)}" tappable>
        <div @click.self="toPost(item)" class="feed-card-content" :class="item.darkTheme ? 'text-white' : ''">
          <timeline-like-share class="feed-card-footer" :data="item"></timeline-like-share>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import POST_TYPES from '@/components/timeline/PosTypes';
import TimelineLikeShare from "./TimelineLikeShare";
import { defineComponent } from 'vue';
import {TokenService} from "@/services/token.service";
import TimelinePoll from "./TimelinePoll";
import ImageLoader from "../ImageLoader";
import {useRouter} from "vue-router";
import {AuthService} from "@/services/auth.service";

export default defineComponent({
  name: 'Timeline',
  props: ['items'],
  components: {
    ImageLoader,
    TimelinePoll,
    TimelineLikeShare
  },
  data() {
    return {
      POST_TYPES: POST_TYPES
    }
  },
  setup() {
    const router = useRouter()
    return { router }
  },
  methods: {
    isShared(data) {
      return data && data.data && data.data.url;
    },
    toPost(data) {
      if(this.isShared(data)) {
        if(data.data.url.includes('shiawasedo.co.jp') && AuthService.isAuth()) {
          return window.open(data.data.url + '?access_token=' + TokenService.getToken(), '_system', 'location=yes');
        }
        return window.open(data.data.url, '_system', 'location=yes');
      }
      this.router.push(`/timeline/${data.id}`)
    }
  }
});
</script>

<style lang="scss">
.feed-body {
  background-color: #fff;
  .feed-card {
    background-color: #f5f5f5;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 0 5px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    h2 {
      margin: 10px 0;
      font-size: 18px;
    }
    h1 {
      margin: 0;
      padding-top: 15px;
      font-size: 24px;
    }
    .feed-card-content {
      padding: 5px 20px 20px;
      position: relative;
      width: 100%;
      height: calc(100vw * 1.25);
      .poll-block {
        padding: 0 20px;
        position: absolute;
        bottom: 65px;
        left: 0;
      }
      .feed-image-normal {
        margin: 5px -10px 0;
      }
      &.feed-card-content-normal {
        height: auto;
      }
      .feed-card-footer {
        display: block;
        z-index: 1000;
        position: absolute;
        width: calc(100% - 40px);
        bottom: 20px;
        &.feed-content-normal {
          position: relative;
          bottom: 0;
          margin: 2px 0 0;
          width: 100%;
        }
      }
    }
  }
}
</style>
