import ApiService from "./api.service";
import { TokenService } from "./token.service";
import { AxiosRequestConfig } from "axios";
import qs from "qs";
import {LanguageService} from "@/services/language.service";
import {OrderService} from "@/services/order.service";
import {CartService} from "@/services/cart.service";
import {FcmUpdateService} from "@/services/fcm.service";

const AUTH_PROVIDER = 'auth_provider'

class AuthenticationError extends Error {
	errorCode: any;
	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}

const AuthService = {
	signIn: async function(signInData: any) {
		const requestData: AxiosRequestConfig = {
			method: "post",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				//Authorization: 'Basic ' + btoa(process.env.VUE_APP_CLIENT_ID + ':' + process.env.VUE_APP_CLIENT_SECRET)
			},
			url: "/jwtAuth",
			data: qs.stringify({
				//"grant_type": "password",
				username: signInData.username,
				password: signInData.password
			})
		};

		try {
			const response = await ApiService.customRequest(requestData);
			TokenService.saveToken(response.data.data.access_token);
			LanguageService.setLangById(response.data.data.langId);
			TokenService.saveUser(response.data.data.id);
			TokenService.saveRefreshToken(response.data.data.refresh_token);
			ApiService.setHeader();
			FcmUpdateService.setWasChange('1');

			return response.data.access_token;
		} catch (error) {
			this.catchError(error);
		}
	},
	hybridSignIn: async function(signInData: any) {
		const requestData: AxiosRequestConfig = {
			method: "post",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				//Authorization: 'Basic ' + btoa(process.env.VUE_APP_CLIENT_ID + ':' + process.env.VUE_APP_CLIENT_SECRET)
			},
			url: "/hybridAuth",
			data: qs.stringify({
				provider: signInData.provider,
				token: signInData.token,
				userId: signInData.userId,
				email: signInData.email
			})
		};
		try {
			const response = await ApiService.customRequest(requestData);
			TokenService.saveToken(response.data.data.access_token);
			LanguageService.setLangById(response.data.data.langId);
			TokenService.saveUser(response.data.data.id);
			TokenService.saveRefreshToken(response.data.data.refresh_token);
			ApiService.setHeader();
			FcmUpdateService.setWasChange('1');
			return response.data.access_token;
		} catch (error) {
			this.catchError(error);
		}
	},
	//TODO: create refresh on server
	refreshToken: async function() {
		const refreshToken = TokenService.getRefreshToken();

		const requestData: AxiosRequestConfig = {
			method: "post",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Authorization: 'Basic ' + btoa(process.env.VUE_APP_CLIENT_ID + ':' + process.env.VUE_APP_CLIENT_SECRET)
			},
			url: "/oauth/token",
			data: qs.stringify({
				"grant_type": "refresh_token",
				refreshToken: refreshToken
			})
		};

		try {
			const response = await ApiService.customRequest(requestData);

			TokenService.saveToken(response.data.access_token);
			LanguageService.setLangById(response.data.langId);
			TokenService.saveUser(response.data.id);
			TokenService.saveRefreshToken(response.data.refresh_token);
			ApiService.setHeader();

			return response.data.access_token;
		} catch (error) {
			throw new AuthenticationError(
				error.response.status,
				error.response.data.error_description
			);
		}
	},
	signOut() {
		TokenService.removeToken();
		OrderService.clear();
		CartService.clearCart();
		TokenService.clearUser();
		ApiService.removeHeader();
		AuthService.removeAuthProvider();
		ApiService.unmount401Interceptor();
		FcmUpdateService.setWasChange('1');
	},
	signup: async function(email: any, password: any, name: any) {
		const signupData: AxiosRequestConfig = {
			method: "post",
			headers: { "Content-Type": "application/json" },
			url: "/oauth/signup",
			data: {
				email: email,
				password: password,
				name: name
			}
		};

		try {
			return await ApiService.customRequest(signupData);
		} catch (error) {
			this.catchError(error);
		}
	},
	isAuth() {
		return TokenService.getToken()
	},
	catchError: function(error: any) {
		let status;
		let description;

		if (error.response === undefined) {
			status = error.message;
			description = error.message;
		} else {
			status = error.response.status;
			description = error.response.data.error_description;
		}

		throw new AuthenticationError(status, description);
	},
	getProvider() {
		return localStorage.getItem(AUTH_PROVIDER);
	},
	setAuthProvider(provider: string) {
		return localStorage.setItem(AUTH_PROVIDER, provider);
	},
	removeAuthProvider() {
		return localStorage.removeItem(AUTH_PROVIDER);
	}
}

export { AuthService, AuthenticationError };
