<template>
  <ion-page>
    <toolbar/>
    <banner v-if="showBanner" slot="fixed" :scroll-point="scrollTop" />
    <ion-content fullscreen id="content" :scroll-events="true" @ionScroll="logScrolling($event)">
      <ion-refresher slot="fixed" @ionRefresh="loadTimeline($event)">
        <ion-refresher-content
            pulling-text="Pull to refresh"
            refreshing-spinner="null"
            refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <div class="dynamic-padding" ref="timeLineTab">
        <timeline v-if="items" :items="items.filter(item => likeMode ? item.isLike === true : true)" />
        <div class="spin" v-else>
          <ion-spinner name="circles" />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonContent, IonRefresher, IonRefresherContent, IonPage, IonSpinner} from '@ionic/vue';
import { Badge } from '@ionic-native/badge';
import Timeline from '@/components/timeline/Timeline.vue';
import { useRouter } from 'vue-router';
import Toolbar from "@/components/Toolbar.vue";
import {mapActions} from "vuex";
import ApiService from "@/services/api.service";
import {Device} from '@capacitor/device'
import Banner from "@/components/Banner";
export default {
  name: 'TimelineTab',
  components: {Banner, Toolbar, Timeline, IonContent, IonRefresher, IonRefresherContent, IonPage, IonSpinner},
  data() {
    return {
      items: null,
      modal: null,
      likeMode: false,
      scrollTop: 0,
      paddingTop: 0,
      showBanner: true
    }
  },
  mounted() {
    this.$emitter.on('inside:like', this.loadTimeline())
    this.$emitter.on('banner:scroll', (padding) => {
      this.paddingTop = padding
    })
    this.$emitter.on('banner:close', () => {
      this.paddingTop = 0
      this.showBanner = false
    });
  },
  setup() {
    const router = useRouter()
    return {
      router, Timeline, Badge
    };
  },
  methods: {
    logScrolling($event) {
      this.scrollTop = $event.detail.scrollTop
    },
    ...mapActions("home", ["loadSecretArea"]),
    async loadTimeline(event) {
      this.items = null;
      const uuid = (await Device.getId()).identifier
      await this.loadSecretArea().then((res) => {
        this.items = res.data.data
        Badge.get(async (badge) => {
          if(badge) {
            await ApiService.post('notification', { uuid: uuid})
          }
          await Badge.clear()
        })
        if(event) {
          event.target.complete();
        }
        if(this.$route.query.redirect) {
          window.location.href = '/' + this.$route.query.redirect
        }
        if(this.$route.query.navi) {
          window.location.href = this.$route.query.navi
        }
      });
    },
    ionViewWillEnter() {
      this.loadTimeline()
      this.$emitter.on('like:change', (isLiked) => {
        this.likeMode = isLiked
      })
      this.$emitter.on('timeline:upd', () => {
        this.loadTimeline()
      })
    }
  }
}
</script>
<style>
  .dynamic-padding {
    transition: padding-top 0.25s ease-in;
  }
</style>