import ApiService from "@/services/api.service";
import {AuthService} from "@/services/auth.service";

/* eslint-disable @typescript-eslint/camelcase */
export const OkAuthService =  {
    authRequest: function() {
        AuthService.setAuthProvider('okru');
        let href = 'https://connect.ok.ru/oauth/authorize'
        href += '?client_id=' + process.env.VUE_APP_OK_ID
        href += '&redirect_uri=' + process.env.VUE_APP_OK_REDIRECT
        href += '&scope=GET_EMAIL'
        href += '&response_type=code'
        window.open(href, '_system', 'location=yes');
    },
    getToken: function (code: any) {
        return ApiService.post('okAuth',
            {
                client_id: process.env.VUE_APP_OK_ID,
                client_secret: process.env.VUE_APP_OK_SECRET,
                redirect_uri: process.env.VUE_APP_OK_REDIRECT,
                code: code
            })
    }
}

export default { OkAuthService }