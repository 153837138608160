<template>
  <div id="container" v-if="profile && amount" class="ion-padding">
    <div class="item-block-list">
      <h1 class="title mb-0">
        {{ 'Summus ' + $tr(trCartForm, 'wallet') }}
      </h1>
      <div class="wallet-case">
        {{ profile.email }}
      </div>
      <div class="balance-wrapper">
        <div class="balance-block">
          <p>{{$td('Доступные средства')}}:</p>
          <ion-text color="shiawasedo-primary" class="balance">{{ amount.text }}</ion-text>
        </div>
      </div>
      <h2 class="title">{{ $td('Пополнение кошелька') }}</h2>
      <div class="item-block" style="padding-top: 10px;">
        <ion-segment value="my" v-model="walletCase">
          <ion-segment-button value="my">
            {{ $td('Мой кошелёк') }}
          </ion-segment-button>
          <ion-segment-button value="other">
            {{ $td('Кошелёк другого клиента') }}
          </ion-segment-button>
        </ion-segment>
      </div>
    </div>
    <form v-if="walletCase === 'other'" class="ion-padding-horizontal">
      <div class="item-block floating-item ion-no-padding">
        <ion-label class="top-label">{{ $td('Email кошелька получателя') }}:</ion-label>
        <ion-input class="grey-input" v-model.lazy="otherWallet.email" clearInput="true" clearOnEdit="true" inputmode="text" :placeholder="$td('Введите email')" type="email" required="true"></ion-input>
        <template  v-if="otherWallet.error || otherWallet.success">
          <ion-label v-if="otherWallet.error" class="text-error ion-text-wrap">{{ $tr(trError, otherWallet.error.toString()) }}</ion-label>
          <ion-label v-if="otherWallet.success" class="text-success ion-text-wrap">{{ $td('Имя клиента') }}: {{ otherWallet.success }}</ion-label>
        </template>
      </div>
      <div class="item-block floating-item ion-no-padding">
        <ion-label type="number" class="top-label">{{ $td('Сумма пополнения') }}:</ion-label>
        <div class="input-block-with-icon">
          <ion-icon slot="start" :icon="logoYen"></ion-icon>
          <ion-input type="tel" class="grey-input" clearInput="true" clearOnEdit="true" inputmode="text" v-model.lazy="otherWallet.amount" :placeholder="$td('Введите сумму')" required="true"></ion-input>
        </div>
        <template class="item-block" v-if="otherWallet.amountError">
          <ion-label v-if="otherWallet.amountError" class="text-error ion-text-wrap">{{ $tr(trError, otherWallet.amountError.toString()) }}</ion-label>
        </template>
      </div>
      <div class="floating-item item-block mb-10">
        <ion-label class="top-label">{{ $td('Подписаться') }}:</ion-label>
        <ion-input clearInput="true" clearOnEdit="true" inputmode="text" class="grey-input" :disabled="otherWallet.isNoSignature" v-model="otherWallet.signature" :placeholder="$td('Введите подпись')" required="true"></ion-input>
      </div>
      <ion-item lines="none" class="item-block ion-no-padding">
        <ion-checkbox id="isNoSignature" v-model="otherWallet.isNoSignature" slot="end"></ion-checkbox>
        <ion-label clearInput="true" clearOnEdit="true" inputmode="text" class="top-label">{{ $td('Отправить анонимно') }}:</ion-label>
      </ion-item>
      <div class="item-block floating-item ion-no-padding" style="padding-top: 10px;">
        <ion-label class="top-label">{{ $td('Сообщение для получателя') }}:</ion-label>
        <ion-textarea :placeholder="$td('Введите текст сообщения')" class="grey-input" v-model="otherWallet.message"></ion-textarea>
      </div>
      <ion-button @click="toWallet()" :disabled="isError" class="mt-20" expand="block" size="lg" color="shiawasedo">
        <ion-icon src="/assets/icon/wallet-white.svg" slot="start"></ion-icon>
        {{ $td('Перейти к оплате') }}
      </ion-button>
      <hr class="mt-20" />
    </form>
    <form v-if="walletCase === 'my'" class="ion-padding-horizontal">
      <div class="floating-item item-block">
        <ion-label class="top-label">
          {{ $td('Сумма пополнения') }}:
        </ion-label>
        <div class="input-block-with-icon">
          <ion-icon slot="start" :icon="logoYen"></ion-icon>
          <ion-input type="number" class="grey-input" v-model="myWallet.amount" autocorrect="on" clearInput="true" clearOnEdit="true" inputmode="text" :placeholder="$td('Введите сумму')" required="true"></ion-input>
        </div>
        <template v-if="myWallet.error">
          <ion-label v-if="myWallet.error" class="text-error ion-text-wrap">{{ $tr(trError, myWallet.error.toString()) }}</ion-label>
        </template>
      </div>
      <ion-button @click="toWallet()" :disabled="isErrorMy" class="mt-20" expand="block" size="lg" color="shiawasedo">
        <ion-icon src="/assets/icon/wallet-white.svg" slot="start"></ion-icon>
        {{ $td('Перейти к оплате') }}
      </ion-button>
      <hr/>
    </form>
    <div class="item-block">
      <p v-html="$td('Summus Кошелёк')"></p>
      <div v-html="$td('Summus Purpose').replaceAll('%email%', profile.email)"></div>
    </div>
    <div class="item-block-list" v-html="$tr(trWallet, 'What is Summus Wallet for')"></div>
  </div>
  <div class="spin" v-else>
    <ion-spinner name="circles" />
  </div>
</template>

<script>
import {trCartForm, trWallet} from "@/i18n/messages";
import trCommon from "@/i18n/translates";
import {trError} from "@/i18n/errors";
import {logoYen} from "ionicons/icons";
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import {
  IonCheckbox,
  IonButton,
  IonLabel,
  IonIcon,
  IonInput,
  IonSpinner,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonItem,
    IonTextarea
} from "@ionic/vue";
import {mapActions} from "vuex";
import ApiService from "@/services/api.service";
import Wallet from "@/models/Wallet";
import {WalletService} from "@/services/wallet.service";
export default {
  name: "Wallet",
  components: {
    IonInput,
    IonCheckbox,
    IonButton,
    IonLabel,
    IonSpinner,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    IonText,
    IonItem,
    IonTextarea
  },
  data() {
    return {
      loading: false,
      profile: null,
      amount: null,
      walletCase: 'my',
      myWallet: {
        amount: '',
        error: null,
      },
      otherWallet: {
        email: '',
        error: null,
        success: null,
        amountError: null,
        amountSuccess: null
      }
    }
  },
  setup: () => ({trCartForm, logoYen, trError, trCommon, v$: useVuelidate(), trWallet}),
  validations () {
    return {
      myWallet: {
        amount: {required}
      },
      otherWallet: {
        email: { required, email },
        amount: {required}
      }
    }
  },
  mounted() {
    this.loadProfile()
    this.loadAmount()
  },
  methods: {
    toWallet() {
      let wallet = new Wallet();
      if(this.walletCase === 'my') {
        wallet.amount = this.myWallet.amount
        wallet.email = this.profile.email
      } else {
        wallet = this.otherWallet
        wallet.fullName = this.otherWallet.success
        console.log(wallet.fullName)
      }
      WalletService.set(wallet)
      this.$router.push('/wallet/buy')
    },
    ...mapActions("profile", ["get"]),
    async loadProfile() {
      await this.get().then((res) => {
        this.profile = res.data.data;
        this.otherWallet.signature = this.profile.firstName + (this.profile.lastName ? ' ' + this.profile.lastName : '')
      });
    },
    loadAmount() {
      ApiService.get('wallet').then(res => {
        this.amount = res.data.data.amount
      }).catch(err => {
        console.log(err.response)
      })
    }
  },
  computed: {
    otherEmail() {
      return this.otherWallet.email
    },
    myAmount() {
      return this.myWallet.amount
    },
    otherAmount() {
      return this.otherWallet.amount
    },
    isError() {
      return this.otherWallet.amountError
          || this.otherWallet.error
          || this.v$.otherWallet.email.$invalid
          || this.v$.otherWallet.amount.$invalid
          || this.loading
    },
    isErrorMy() {
      return this.myWallet.error || this.v$.myWallet.amount.$invalid || this.loading
    }
  },
  watch: {
    otherEmail(newVal) {
      if (!this.v$.otherWallet.email.$invalid) {
        this.loading = true
        ApiService.post('accountWallet', {
          action: 'userForCheck',
          forEmail: newVal
        }).then(async (res) => {
          this.otherWallet.success = res.data.data.userForName
          this.otherWallet.error = null
          this.loading = false
        }).catch(async (err) => {
          this.otherWallet.error = err.response.data.data
          this.otherWallet.success = null
          this.loading = false
        })
      }
    },
    async otherAmount(newVal) {
      this.loading = true
      ApiService.post('accountWallet', {
        action: 'amountInput',
        amount: newVal
      }).then(async () => {
        this.otherWallet.amountError = null
        this.loading = false
      }).catch(async (err) => {
        this.otherWallet.amountError = err.response.data.data
        this.loading = false
      })
    },
    async myAmount(newVal) {
      this.loading = true
      ApiService.post('accountWallet', {
        action: 'amountInput',
        amount: newVal
      }).then(async () => {
        this.myWallet.error = null
        this.loading = false
      }).catch(async (err) => {
        this.myWallet.error = err.response.data.data
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#container {
  padding: 0 var(--padding-start);
}
ion-segment-button {
  font-size: 100%;
  overflow: inherit;
  white-space: normal;
}
.item-block {
  margin: 5px 0 50px;
}
.floating-item {
  margin-top: 10px;
  ion-input, ion-textarea {
    margin: 5px 0 5px;
  }
  .top-label {
    white-space: normal;
    font-size: 16px;
    font-weight: 600;
  }
}
.text-success, .text-error {
  font-size: 14px;
  z-index: 100;
  margin: 0;
}
.wallet-case {
  font-size: 1.2rem;
}
p {
  margin: 0 0 15px;
}
.balance-wrapper {
  padding: 30px 7px 50px;
  margin-left: -7px;
  overflow: hidden;
  .balance-block {
    float: left;
    border-radius: 6px;
    line-height: 1.5;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .75);
    padding: 20px;
    .balance {
      font-size: 2em;
      line-height: 100%;
      font-weight: bold;
    }
  }
}
ion-button {
  margin-bottom: 40px;
}
</style>