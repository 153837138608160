<template>
  <ion-content>
    <div class="scan-box">
      <div class="scan-red-line"></div>
    </div>
  </ion-content>
  <ion-footer>
    <ion-button color="shiawasedo" expand="full" @click="stopScan">Cancel</ion-button>
  </ion-footer>
</template>

<script>
import {alertController, loadingController, menuController, IonContent, IonButton, IonFooter} from "@ionic/vue";
import {scan} from "ionicons/icons";
import {trCommon, trMenu} from "@/i18n/messages";
import ApiService from "@/services/api.service";
import {TokenService} from "@/services/token.service";
import { InAppBrowser } from '@ionic-native/in-app-browser';
import {Device} from '@capacitor/device'
import {BarcodeScanner} from '@capacitor-community/barcode-scanner'

export default {
  name: "Scaner",
  components: { IonContent, IonButton, IonFooter },
  setup() {
    return {
      trMenu, alertController, loadingController, scan, menuController
    }
  },
  data() {
    return  { timeout: 1000 }
  },
  mounted() {
    this.prepare()
    if(this.checkPermission()) {
      this.startScan()
    }
  },
  methods: {
    prepare() {
      BarcodeScanner.prepare();
    },
    async alertError() {
      const alert = await alertController.create({
        header: this.$tr(trCommon, 'error'),
        message: 'Not found!',
        buttons: ['OK'],
      });
      await alert.present();
    },
    async stopScan() {
      document.body.style.background = "var(--ion-background-color)"
      document.getElementById('app').style.background = "#fff"
      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();
      this.$emitter.emit('scan:stop');
    },
    deactivated() {
      this.stopScan();
    },
    beforeDestroy() {
      this.stopScan();
    },
    async checkPermission() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise( async (resolve) => {
        const status = await BarcodeScanner.checkPermission({ force: true });

        if (status.granted) {
          // We are fine
          resolve(true);
        } else if (status.denied) {
          // Denied before
          const alert = await this.alertController.create({
            header: 'No permission',
            message: 'Please allow camera access in your settings',
            buttons: [{
              text: 'No',
              role: 'cancel'
            },
              {
                text: 'Open Settings',
                handler: () => {
                  resolve(false);
                  BarcodeScanner.openAppSettings();
                }
              }]
          });

          await alert.present();
        } else {
          resolve(false);
        }
      });
    },
    async startScan() {
      const loading = await loadingController.create({duration: 200});
      await loading.present();
      this.$emitter.emit('scan:start');
      BarcodeScanner.hideBackground();
      document.body.style.background = "transparent";
      document.getElementById('app').style.background = 'transparent'
      const result = await BarcodeScanner.startScan({ targetedFormats: ['EAN_13', 'QR_CODE'] });
      if (result.hasContent) {
        const loading = await loadingController.create({});
        await loading.present();
        let request = 'barcode?id=' + result.content;
        if(result.content.indexOf('linkId') > -1) {
          request = 'barcode?linkId=' + result.content.split('linkId=').pop();
        }
        if(result.content.indexOf('linkId') > -1) {
          request = 'barcode?linkId=' + result.content.split('linkId=').pop();
        }
        ApiService
            .get(request)
            .then(async (res) => {
              const device = await Device.getInfo();
              await loading.dismiss()
              this.stopScan().then(() => {
                if(res.data.data.location) {
                  const link = res.data.data.location + '?access_token=' + TokenService.getToken() + '&client_type='+(device.platform === 'ios' ? '20' : '30')
                  setTimeout(() => {
                    InAppBrowser.create(link, '_system')
                  }, 200)
                  return;
                }
                setTimeout(() => {
                  this.$router.push(`/product/${res.data.data.id}`)
                }, 200)
              })
            })
            .catch(async (error) => {
              console.log(error.response)
              await loading.dismiss()
              this.stopScan().then(() => {
                this.alertError()
              })
            })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.scanner-buttons {
  margin: 0;
  position: absolute;
  bottom: 100px;
  width: 100vw;
  height: 50px;
  z-index: 11;
}

.scan-box {
  border: 2px solid #fff;
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
  content: '';
  display: block;
  left: 50%;
  height: 200px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  .scan-red-line {
    position: absolute;
    top: 50%;
    margin-top: -1px;
    border-top: 2px solid var(--ion-color-gift);
    width: 100%;
  }
}
ion-content{
  --background: transparent;
}
</style>