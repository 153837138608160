import Wallet from "@/models/Wallet";

const WALLET_KET = "wallet";

const WalletService = {
	get(): Wallet {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const wallet = localStorage.getItem(WALLET_KET);
		if(wallet) {
			return JSON.parse(wallet)
		}
		return new Wallet();
	},
	getAmount(): number {
		const wallet = this.get()
		if(wallet) {
			return wallet.amount;
		}
		return 0;
	},
	getFee() {
		const wallet = this.get()
		if(wallet) {
			return wallet.fee ? wallet.fee.amount : 0;
		}
		return 0;
	},
	getTotal(): number {
		return Number(this.getAmount()) + Number(this.getFee());
	},
	set(wallet: Wallet) {
		localStorage.setItem(WALLET_KET, JSON.stringify(wallet))
	},
	clear() {
		localStorage.removeItem(WALLET_KET)
	}
};

export { WalletService };
