<template>
  <div v-if="!product.canPay" class="text-center">
    <ion-label color="gift">
      <template v-if="product.isRestrictedForCurrentCountry">
        {{ $tr(trProduct, 'notForJapan') }}
      </template>
      <template v-else-if="product.isSalePending">
        {{ $tr(trProduct, 'pending') }}
      </template>
      <template v-else>
        {{ $tr(trProduct, 'unavailable') }}
      </template>
    </ion-label>
  </div>
  <div class="text-center" v-else-if="product.promoOffer">
    <ion-label color="gift">
      {{ $tr(trProduct, 'promoOffer') }}
    </ion-label>
  </div>
  <div :class="wrapperClass" v-else>
    <ion-button v-if="inCartNotExist(product.id)" @click="addToCart(product)" :size="size ? size : 'small'" expand="block" :color="product.isAction ? 'action' : 'shiawasedo'">
      {{ product.isAction ? $tr(trProduct, 'action') : $tr(trCart, 'toCart') }}
      <ion-icon slot="start" :icon="cartOutline"></ion-icon>
    </ion-button>
    <ion-buttons v-else>
      <ion-button @click="deleteFromCart(product.id)" color="shiawasedo">
        <ion-icon slot="icon-only" :icon="removeCircle"></ion-icon>
      </ion-button>
      <ion-button class="cart-count" @click="goToCart">
        {{ inCartCount(product.id) }}
      </ion-button>
      <ion-button slot="end" @click="addToCart(product)" color="shiawasedo">
        <ion-icon slot="icon-only" :icon="addCircle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>
</template>

<script>
import Cart from "../models/Cart";
import {IonIcon} from "@ionic/vue";
import {CartService} from "@/services/cart.service";
import {trCart, trProduct} from '@/i18n/messages'
import {useRouter} from "vue-router";
import {addCircle, cartOutline, removeCircle} from "ionicons/icons";

export default {
  name: "CartBtn",
  props: ['product', 'wrapperClass', 'size'],
  components: {IonIcon},
  data() {
    return {
      cart: CartService.getCart()
    }
  },
  mounted() {
    this.$emitter.on('update:cart', () => {
      this.cart = CartService.getCart()
    });
  },
  setup() {
    const router = useRouter()
    return {
      cartOutline, addCircle, removeCircle, router, trCart, trProduct
    }
  },
  methods: {
    addToCart(product) {
      if(product.isAction && product.url) {
        return window.location.href = product.url
      }
      const cart = new Cart();
      cart.product = product;
      cart.count = 1;
      CartService.addTo(cart);
      this.$emitter.emit('update:cart');
      this.cart = CartService.getCart()
    },
    deleteFromCart(productId) {
      CartService.deleteFrom(productId);
      this.$emitter.emit('update:cart');
      this.cart = CartService.getCart()
    },
    inCartNotExist(id) {
      return typeof this.cart.find((item) => item.product.id === id) === "undefined"
    },
    goToCart() {
      this.router.push('/cart')
    },
    inCartCount(id) {
      if(!this.inCartNotExist(id)) {
        const cart = this.cart.find((item) => item.product.id === id)
        return cart?.count
      }
      return 0
    },
  }
}
</script>

<style lang="scss" scoped>
.buttons-wrapper {
  height: 45px;
  ion-buttons {
    ion-button {
      width: 34%!important;
      &:first-child, &:last-child {
        width: 33%!important;
      }
    }
  }
}
</style>