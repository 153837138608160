<template>
  <span class="feed-footer">
    <ion-icon class="shared" @click="basicShare(data)" tappable :icon="shareOutline"></ion-icon>
    <ion-icon v-if="$isAuth" class="liked" @click="toggleLikeState(data)" tappable :icon="data.isLike ? heart : heartOutline"></ion-icon>
    <ion-icon v-if="data.type.id === POST_TYPES.ACTION || data.type.id === POST_TYPES.ANNOUNCE"  class="liked" @click="addEvent()" tappable :icon="calendarOutline"></ion-icon>
  </span>
</template>

<script>
import POST_TYPES from '@/components/timeline/PosTypes';
import {IonIcon} from '@ionic/vue';
import {heart, heartOutline, chevronForwardCircle, shareOutline, calendarOutline} from 'ionicons/icons';
import {Share} from '@capacitor/share'
import ApiService from "@/services/api.service";
import {useRouter} from "vue-router";
import { Calendar } from '@ionic-native/calendar';

export default {
  name: "TimelineLikeShortShare",
  props: {
    data: Object
  },
  components: {IonIcon},
  setup() {
    const router = useRouter()
    return {heartOutline, heart, shareOutline, chevronForwardCircle, POST_TYPES, router, calendar: Calendar, calendarOutline}
  },
  computed: {
    isShared() {
      return this.data && this.data.data && this.data.data.url;
    }
  },
  methods: {
    addEvent() {
      const date = new Date();
      const options = { calendarId: 1, calendarName: 'Shiawasedo', url: 'https://ionicacademy.com', firstReminderMinutes: 15 };
      this.calendar.createEventInteractivelyWithOptions('My new Event', 'Münster', 'Special Notes', date, date, options)
    },
    toggleLikeState(data) {
      data.isLike = !data.isLike;
      ApiService.post('like', {
        id: data.id
      })
    },
    async basicShare(data) {
      if(data.data && data.data.url) {
        await Share.share({
          url: process.env.VUE_APP_WEBCLIENT_URL + data.id
        })
      } else {
        await Share.share({
          url: process.env.VUE_APP_WEBCLIENT_URL + data.id
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.feed-footer {
  ion-icon  {
    font-size: 22px;
    line-height: 1;
    padding: 8px 0;
    color: inherit;
  }
  .ion-float-right {
    font-size: 38px;
    padding: 0;
    &.price-button {
      border-radius: 24px;
      font-size: 14px;
      padding: 8px 10px;
      margin-top: 2px;
      background-color: #333;
      color: #fff;
      &.white-theme {
        background-color: #fff;
        color: #333;
      }
    }
  }
  .shared {
    margin: 0 8px -5px 0;
  }
  .liked {
    margin: 0 8px -7px 0;
  }
}
</style>
