const TOKEN_KEY = "access_token";
const FCM_TOKEN_KEY = "fcm_token";
const USER_KEY = "user_id";
const REFRESH_TOKEN_KEY = "refresh_token";

const TokenService = {
	getToken() {
		return localStorage.getItem(TOKEN_KEY);
	},

	saveToken(accessToken: string) {
		localStorage.setItem(TOKEN_KEY, accessToken);
	},

	getFcmToken() {
		return localStorage.getItem(FCM_TOKEN_KEY);
	},

	setFcmToken(accessToken: string) {
		localStorage.setItem(FCM_TOKEN_KEY, accessToken);
	},

	getUser() {
		return localStorage.getItem(USER_KEY);
	},

	clearUser() {
		return localStorage.removeItem(USER_KEY);
	},

	saveUser(userId: string) {
		localStorage.setItem(USER_KEY, userId);
	},

	removeToken() {
		localStorage.removeItem(TOKEN_KEY);
	},

	getRefreshToken() {
		return localStorage.getItem(REFRESH_TOKEN_KEY);
	},

	saveRefreshToken(refreshToken: string) {
		localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
	},

	removeRefreshToken() {
		localStorage.removeItem(REFRESH_TOKEN_KEY);
	}
};

export { TokenService };
