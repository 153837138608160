import Order from "@/models/Order";
const ORDER_KEY = 'order';

const OrderService = {
    get(): Order {
        const order = localStorage.getItem(ORDER_KEY);
        if(order) {
            return JSON.parse(order)
        }
        return new Order();
    },
    set(order: Order) {
        localStorage.setItem(ORDER_KEY, JSON.stringify(order))
    },
    clear() {
        return localStorage.removeItem(ORDER_KEY)
    }
}

export { OrderService};
