<template>
  <ion-buttons slot="end" @click="goToCart">
    <ion-button color="shiawasedo">
      <ion-icon slot="icon-only" :icon="cartSharp"></ion-icon>
      <ion-badge v-if="count" class="cart-badge" color="danger">{{ count }}</ion-badge>
    </ion-button>
  </ion-buttons>
</template>

<script>
import {IonButtons, IonButton, IonIcon, IonBadge} from '@ionic/vue'
import {cartSharp} from 'ionicons/icons';
import {CartService} from "@/services/cart.service";
import {useRouter} from "vue-router";
export default {
name: "Cart",
  components: { IonButtons, IonButton, IonIcon, IonBadge},
  setup() {
    const router = useRouter()
    return { cartSharp, router }
  },
  data() {
    return {
      count: CartService.getCount()
    }
  },
  mounted() {
    this.$emitter.on('update:cart', () => {
      this.count = CartService.getCount()
    });
  },
  methods: {
    goToCart() {
      return this.router.push('/cart')
    }
  }
}
</script>

<style scoped>
.cart-badge {
  position: absolute;
  right: -3px;
  top: 0;
  font-size: 10px;
}
</style>
