<template>
  <div class="product-wrapper" v-if="product">
    <div v-if="body" v-html="body"></div>
    <ion-label class="product-name text-center" v-html="product.name"></ion-label>
    <div class="text-center">
      <small><span v-html="product.size"></span></small>
    </div>
    <div class="product-short-description text-center" v-if="product.shortDescription !== ''" v-html="product.shortDescription"></div>
    <div class="text-center almost-out-of-stock" v-if="product.isAlmostOutOfStock">
      <ion-label color="danger">
        {{$tr(trProduct, 'endPlain')}}
      </ion-label>
    </div>
    <h4 v-if="product.isDiscount" class="text-center product-real-price">
      ¥{{ product.realPrice }}
    </h4>
    <h3 class="text-center price-text" v-html="product.price"></h3>
    <div class="product-currencies text-center" v-html="product.currenciesHTMLShort"></div>
    <cart-btn size="big" wrapper-class="buttons-wrapper" :product="product" />
    <div class="product-long-description" v-if="product.longDescription !== ''" v-html="product.longDescription"></div>
    <template v-if="product.aboutEffect">
      <h4>{{ $tr(trProduct, 'effect') }}</h4>
      <div v-html="product.aboutEffect"></div>
    </template>
    <template v-if="product.aboutDetails">
      <h4>{{ $tr(trProduct, 'details') }}</h4>
      <div v-html="product.aboutDetails"></div>
    </template>
    <template v-if="product.aboutIngredients">
      <h4>{{ $tr(trProduct, 'ingredients') }}</h4>
      <div v-html="product.aboutIngredients"></div>
    </template>
    <template v-if="product.aboutInstructions">
      <h4>{{ $tr(trProduct, 'instructions') }}</h4>
      <div v-html="product.aboutInstructions"></div>
    </template>
  </div>
</template>

<script>
import {IonLabel} from "@ionic/vue"
import {cartOutline, removeCircle, addCircle} from 'ionicons/icons';
import ApiService from "@/services/api.service";
import Cart from "../models/Cart";
import {useRouter} from "vue-router";
import {CartService} from "@/services/cart.service";
import {trCart, trProduct} from '@/i18n/messages'
import {AuthService} from "@/services/auth.service";
import CartBtn from "@/components/CartBtn";
export default {
  name: "Product",
  components: {CartBtn, IonLabel },
  props: {
    product: {
      type: Object,
      default: null
    },
    body: {
      type: String,
      default: null
    }
  },
  setup() {
    const router = useRouter()
    return { router, cartOutline, trCart, trProduct, removeCircle, addCircle }
  },
  methods: {
    ionViewWillEnter() {
      this.$emitter.on('img:loaded', () => {
        this.loading = false
      })
      if(!AuthService.isAuth()) {
        ApiService.setHeader()
      }
    },
    goToCart() {
      this.router.push('/cart')
    },
    addToCart() {
      const cart = new Cart();
      cart.product = this.product;
      cart.count = 1;
      CartService.addTo(cart);
      this.$emitter.emit('update:cart');
      this.cart = CartService.getCart()
    },
    deleteFromCart() {
      CartService.deleteFrom(this.product.id);
      this.$emitter.emit('update:cart');
      this.cart = CartService.getCart()
    },
    inCartNotExist() {
      return typeof this.cart.find((item) => item.product.id === this.product.id) === "undefined"
    },
    inCartCount() {
      if(!this.inCartNotExist()) {
        const cart = this.cart.find((item) => item.product.id === this.product.id)
        return cart?.count
      }
      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
.product-currencies {
  margin-bottom: 20px;
}
.price-text {
  margin-top: 10px;
  font-size: 28px;
  color: var(--ion-color-shiawasedo);
}
.product-name {
  margin-top: 16px;
  white-space: normal;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: var(--ion-color-shiawasedo);
  display: block;
}
.product-wrapper {
  font-size: 18px;
  h4 {
    color: var(--ion-color-shiawasedo);
    margin: 45px 0 15px;
  }
}
.product-real-price {
  text-decoration: line-through;
  color: #333!important;
  font-weight: normal;
  margin: 15px 0 0!important;
}
.product-short-description {
  margin: 15px 0;
}
.product-long-description {
  margin: 45px 0 0;
}
.almost-out-of-stock {
  margin: 15px 0;
}
</style>
