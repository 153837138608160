import ApiService from "@/services/api.service";
import {FcmUpdateService} from "@/services/fcm.service";

const DEFAULT_LANG = "en";
const RU_LANG = "ru";
const JA_LANG = "ja";
const LANG_KEY = "language";

const LanguageService = {
	getLang(): string {
		let lang = localStorage.getItem(LANG_KEY);
		if(lang === null) {
			lang = DEFAULT_LANG
		}
		return lang;
	},
	setLang(lang: string) {
		localStorage.setItem(LANG_KEY, lang);
		FcmUpdateService.setWasChange('1');
		ApiService.setHeader(lang);
		window.location.reload()
	},
	setLangById(id: string) {
		if(id === '2') {
			return this.setLang(RU_LANG)
		}
		if(id === '3') {
			return this.setLang(JA_LANG)
		}
		return this.setLang(DEFAULT_LANG)
	}
};

export { LanguageService, DEFAULT_LANG, RU_LANG, JA_LANG};
