<template>
  <ion-header translucent>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="dismissModal()">Close</ion-button>
      </ion-buttons>
      <ion-title>PayPal</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div id="dropContainer"></div>
  </ion-content>
</template>

<script>
//import ApiService from "../services/api.service";
import { loadScript } from "@paypal/paypal-js";
import {alertController, IonContent, IonTitle, IonHeader, IonButton, IonButtons, IonToolbar} from "@ionic/vue";
import { defineComponent } from 'vue';
import {CartService} from "@/services/cart.service";
export default defineComponent({
  components: {
    IonContent, IonTitle, IonHeader, IonButton, IonButtons, IonToolbar
  },
  name: "Drop",
  props: ['order'],
  data() {
    return {
      cartActive: true,
      cart: CartService.getCart(),
      total: CartService.total(),
    }
  },
  mounted() {
    this.checkout()
  },
  methods: {
    dismissModal() {
      this.$emitter.emit('modal:close')
    },
    async alertSuccess() {
      const alert = await alertController.create({
        header: 'Success!',
        buttons: ['OK'],
      });
      await alert.present();
    },
    async checkout() {
      loadScript({ "client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID })
          .then((paypal) => {
            paypal.Buttons().render("#dropContainer");
          })
          .catch((err) => {
            console.error("failed to load the PayPal JS SDK script", err);
          });
    },
    amount() {
      let amount = 0;
      this.cart.forEach((item) => {
        amount += item.product.priceNumber * item.count
      })
      console.log(amount)
      return amount
    },
    async presentAlert(msg) {
      const alert = await alertController.create({
        header: 'Error',
        message: msg,
        buttons: ['OK']
      });

      await alert.present();
    },
    async presentSuccess() {
      const alert = await alertController.create({
        header: 'Order submitted',
        message: 'Thanks for your order!',
        buttons: [
          {
            text: 'Close',
            handler: () => {
              console.log('success!')
            }
          }
        ]
      });

      await alert.present();
    }
  }
})
</script>

<style scoped>
.braintree-option__card:not(.braintree-option__paypal) {
  display: none !important;
}
.braintree-option__paypal {
  border-width: 1px 1px 1px 1px !important;
  border-radius: 4px 4px 4px 4px !important;
}
</style>