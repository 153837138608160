<template>
  <div>
    <div class="row">
      <div class="col" style="width: 100%">
        <ion-label position="floating">{{$tr(trCard, 'cardNumber')}}</ion-label>
        <div id="card-number"></div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ion-label position="floating">{{$tr(trCard, 'cardExpires')}}</ion-label>
        <div id="card-expiry"></div>
      </div>
      <div class="col">
        <ion-label position="floating">{{$tr(trCard, 'cardCvc')}}</ion-label>
        <div id="card-cvc"></div>
      </div>
    </div>
    <div id="card-error"></div>
  </div>
</template>

<script>
import {IonLabel} from '@ionic/vue'
import {trCard} from '@/i18n/messages';

export default {
  name: "StripeCardToken",
  components: {
    IonLabel
  },
  setup() {
    return { trCard }
  }
}
</script>