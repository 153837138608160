<template>
  <ion-skeleton-text animated v-if="loading"/>
  <img v-else :class="cssClass" :src="resSrc" />
</template>

<script>
import {IonSkeletonText} from "@ionic/vue";
export default {
  name: "ImageLoader",
  components: {IonSkeletonText},
  props: {
    loaded: {
      type: Boolean,
      default: true
    },
    src: {
      type: String,
      default: '/assets/image-not-found.svg'
    },
    styleClass: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      resSrc: process.env.VUE_APP_ROOT_STORAGE + this.src,
      cssClass: null,
      loading: true
    }
  },
  mounted() {
    this.handleLoaded()
  },
  methods: {
    handleLoaded() {
      if(!this.styleClass) {
        const img = new Image();
        img.src = this.resSrc;
        img.onload = () => {
          if(img.height > img.width) {
            this.cssClass = 'img-load-height'
          }
          this.loading = false
        }
      } else if (this.styleClass) {
        this.loading = false
        this.cssClass = this.styleClass
      }
      this.$emitter.emit('img:loaded', true)
    }
  }
}
</script>

<style lang="scss" scoped>
  img {
    display: block;
    object-fit: contain;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
    &.img-load-error {
      width: 50%!important;
      margin: 0 auto!important;
    }
    &.img-load-height {
      width: 50%;
      height: 200px;
      margin: 0 auto!important;
    }
    &.img-cart {
      height: 70px!important;
      min-height: 70px!important;
    }
  }

</style>
