<template>
  <ion-page>
    <toolbar/>
    <ion-content fullscreen>
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Календарь</ion-title>
        </ion-toolbar>
      </ion-header>
      <ExploreContainer name="Календарь" />
    </ion-content>
  </ion-page>
</template>

<script>
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage } from '@ionic/vue';
import ExploreContainer from '@/components/ExploreContainer.vue';
import Toolbar from "@/components/Toolbar.vue";

export default  {
  name: 'CalendarTab',
  components: {Toolbar, ExploreContainer, IonHeader, IonToolbar, IonTitle, IonContent, IonPage }
}
</script>
