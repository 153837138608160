<template>
  <ion-page>
    <toolbar :title="product ? product.name : ''" :backbutton="true" />
    <ion-content class="ion-padding" fullscreen>
      <ion-refresher slot="fixed" @ionRefresh="reload($event)">
        <ion-refresher-content
            pulling-text="Pull to refresh"
            refreshing-spinner="circles"
            refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <div class="product-wrapper product-page" v-if="product">
        <image-loader class="product-image" :src="product.mainImage" />
        <product :product="product" />
      </div>
      <div class="spin" v-else>
        <ion-spinner name="circles" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import Toolbar from "../components/Toolbar";
import Product from "../components/Product";
import {IonPage, IonContent, IonSpinner, IonRefresher, IonRefresherContent } from "@ionic/vue"
import {cartOutline, removeCircle, addCircle} from 'ionicons/icons';
import ApiService from "@/services/api.service";
import Cart from "../models/Cart";
import {useRouter} from "vue-router";
import ImageLoader from "@/components/ImageLoader";
import {CartService} from "@/services/cart.service";
import {trCart, trProduct} from '@/i18n/messages'
import {AuthService} from "@/services/auth.service";
export default {
name: "ProductPage",
  components: {ImageLoader, Toolbar, IonPage, IonContent, IonSpinner, IonRefresher, IonRefresherContent, Product },
  data() {
    return {
      product: null,
      loading: true,
      cart: CartService.getCart()
    }
  },
  setup() {
    const router = useRouter()
    return { router, cartOutline, trCart, trProduct, removeCircle, addCircle }
  },
  methods: {
    ionViewWillEnter() {
      this.$emitter.on('img:loaded', () => {
        this.loading = false
      })
      if(!AuthService.isAuth()) {
        ApiService.setHeader()
      }
      ApiService.get(`products?id=${this.$route.params.id}`).then(res => {
        if(!res.data.data.length) {
          setTimeout(() => {
            this.router.push('/shop')
          }, 1000)
        } else {
          this.product = res.data.data[0]
          console.log(this.product)
        }
      })
    },
    goToCart() {
      this.router.push('/cart')
    },
    addToCart() {
      const cart = new Cart();
      cart.product = this.product;
      cart.count = 1;
      CartService.addTo(cart);
      this.$emitter.emit('update:cart');
      this.cart = CartService.getCart()
    },
    deleteFromCart() {
      CartService.deleteFrom(this.product.id);
      this.$emitter.emit('update:cart');
      this.cart = CartService.getCart()
    },
    inCartNotExist() {
      return typeof this.cart.find((item) => item.product.id === this.product.id) === "undefined"
    },
    inCartCount() {
      if(!this.inCartNotExist()) {
        const cart = this.cart.find((item) => item.product.id === this.product.id)
        return cart?.count
      }
      return 0
    },
    async reload(event) {
      await ApiService.get(`products?id=${this.$route.params.id}`).then(res => {
        this.product = res.data.data[0]
        if(event) {
          event.target.complete();
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .product-image {
    height: 200px;
  }
  .product-page {
    margin-bottom: 70px;
  }
</style>
