
import {WalletService} from "@/services/wallet.service";
import {trCartForm, trCommon, trSignIn, trCart, trCard} from "@/i18n/messages";
import {Stripe, ApplePayEventsEnum, GooglePayEventsEnum} from '@capacitor-community/stripe';
import {Device} from '@capacitor/device'
import {card, logoApple, logoPaypal} from 'ionicons/icons';

Stripe.initialize({
  publishableKey:  process.env.VUE_APP_STRIPE_PKEY 
})
import {
  IonContent,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonIcon,
  IonRow,
  IonCol, modalController, loadingController, alertController, IonFooter,
  IonToolbar,
    IonList, IonListHeader, IonLabel, IonRadio, IonRadioGroup,
    IonItem, IonButton,
  IonSpinner,
    IonCard,
  IonText
} from "@ionic/vue";
import {mapActions} from "vuex";
import ApiService from "@/services/api.service";
import Drop from "@/components/Drop.vue";
import {InAppBrowser} from "@ionic-native/in-app-browser";
import PayPalButton from "@/components/PayPalButton.vue";
import Toolbar from "@/components/Toolbar.vue";
import { defineComponent } from "vue";
import WalletButton from "@/components/WalletButton.vue";
export default defineComponent({
  name: "walletReplenishment",
  components: {
    WalletButton,
    PayPalButton,
    Toolbar,
    IonContent,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonGrid,
    IonIcon,
    IonRow,
    IonCol,
    IonFooter,
    IonToolbar,
    IonList, IonListHeader, IonLabel, IonRadio, IonRadioGroup,
    IonItem, IonButton, IonSpinner,
    IonCard,
    IonText
  },
  data: () => ({
    wallet: WalletService.get(),
    showApplePay: false,
    showGooglePay: false,
    showPayPal: true,
    showPaygent: true,
    showWallet: true,
    payType: 'card',
    payMethodMapping: {
      'card': 30,
      'apple': 150,
      'google': 160,
      'paygent': 140,
      'paypal': 1,
      'wallet': 130
    },
    canPay: false,
    canGooglePay: false,
    profile: null,
    loading: false,
    countries: null,
    total: 0,
    paygentForm: null
  }),
  setup: () => ({trCartForm, card, logoApple, logoPaypal, trSignIn, trCart, trCard}),
  methods: {
    converter() {
      ApiService.get('converter?total=' + this.getTotal()).then((res) => {
        this.wallet.currencies = res.data.data
      });
    },
    getTotal() {
      return Number(this.wallet.amount) + Number(this.wallet.fee ? parseInt(this.wallet.fee.amount) : 0);
    },
    toCardPay() {
      if(!this.wallet.methodId) {
        WalletService.set(this.wallet)
        this.$router.push('/wallet/add-card')
      } else {
        this.loading = true
        ApiService
            .post('walletReplenishment', {
              wallet: this.wallet,
              payMethod: this.payMethodMapping[this.payType]
            })
            .then((res) => {
              if(res.data.data) {
                WalletService.clear()
                window.location.href = '/wallet/success/' + res.data.data.id
              }
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
      }
    },
    changePayMethod(ev: any) {
      this.payType = ev.target.value
      this.getFee()
    },
    setUpGooglePayment() {
      window.location.href = 'com.google.android.apps.walletnfcrel://'
    },
    setUpPayment() {
      window.location.href = 'shoebox://'
    },
    openModal: async function () {
      const modal = await modalController
          .create({
            component: Drop,
            componentProps: {
              order: this.wallet
            },
          })
      this.currentModal = modal
      return modal.present();
    },
    async googlePayed() {

      const clientSecret = (await ApiService.post('stripeIntent', {
        amount: this.getTotal(),
      })).data.data

      const summaryItems = [
        {
          label: 'Summus ' + this.$tr(trCartForm, 'wallet'),
          amount: this.getTotal(),
        }
      ];

      await Stripe.createGooglePay({
        paymentIntentClientSecret: clientSecret,
        paymentSummaryItems: summaryItems,
        merchantIdentifier: 'merchant.shiawasedo.inc',
        countryCode: 'JP',
        currency: 'JPY',
      })

      // Present Apple Pay
      const result = await Stripe.presentGooglePay();
      if (result.paymentResult === GooglePayEventsEnum.Completed) {
        const loading = await loadingController.create({});
        await loading.present();
        ApiService
            .post('walletReplenishment', {
              wallet: this.wallet,
              clientSecret: clientSecret,
              payMethod: this.payMethodMapping[this.payType]
            })
            .then((res) => {
              loading.dismiss()
              if(res.data.data && res.data.data.number) {
                window.location.href = '/order-success/' + res.data.data.number
              }
            }).catch(() => {
          this.alertError('Произошел сбой при зачислении средств')
          loading.dismiss()
        })
      }

    },
    submitPaygentForm(form: HTMLFormElement) {
      form.submit()
    },
    async alertError(text: any) {
      const alert = await alertController.create({
        header: this.$tr(trCommon, 'error'),
        message: text,
        buttons: ['OK'],
      });
      await alert.present();
    },
    async toPaygentPay() {
      const loading = await loadingController.create({})
      await loading.present()
      ApiService
          .post('walletReplenishment', {
            wallet: this.wallet,
            payMethod: this.payMethodMapping[this.payType]
          })
          .then((res) => {
            this.$nextTick(() => {
              const browser = InAppBrowser.create(
                  "data:text/html;base64," + btoa(res.data.data.form),
                  '_blank',
                  "hidden=no,location=no,clearsessioncache=yes,clearcache=yes"
              )
              loading.dismiss()

              browser.on('loadstart').subscribe((e) => {
                if (e && e.url) {
                  if(e.url.includes('summusbeauty.shiawasedo.co.jp')) {
                    const url = new URL(e.url)
                    browser.close();
                    if(!e.url.includes('error')) {
                      WalletService.clear()
                    }
                    window.location.href = url.pathname + url.search
                  }
                }
              });

            })
          }).catch(() => {
        this.alertError('Произошел сбой при обмене данных с платёжной системой (20005)')
        loading.dismiss()
      })
    },
    async applePayed() {

      const clientSecret = (await ApiService.post('stripeIntent', {
        amount: this.getTotal(),
      })).data.data

      const summaryItems = [
        {
          label: 'Summus ' + this.$tr(trCartForm, 'wallet'),
          amount: this.getTotal(),
        }
      ];

      await Stripe.createApplePay({
        paymentIntentClientSecret: clientSecret,
        paymentSummaryItems: summaryItems,
        merchantIdentifier: 'merchant.shiawasedo.inc',
        countryCode: 'JP',
        currency: 'JPY'
      })

      // Present Apple Pay
      const result = await Stripe.presentApplePay();
      if (result.paymentResult === ApplePayEventsEnum.Completed) {
        const loading = await loadingController.create({});
        await loading.present();
        ApiService
            .post('walletReplenishment', {
              wallet: this.wallet,
              clientSecret: clientSecret,
              payMethod: this.payMethodMapping[this.payType]
            })
            .then((res) => {
              loading.dismiss()
              if(res.data.data && res.data.data.number) {
                window.location.href = '/order-success/' + res.data.data.number
              }
            }).catch(() => {
              this.alertError('Произошел сбой при зачислении средств')
              loading.dismiss()
            })
      }
    },
    ...mapActions("profile", ["get", "post"]),
    async loadProfile() {
      await this.get().then((res: any) => {
        this.profile = res.data.data;
      });
    },
    loadCountries() {
      ApiService.get('countries').then(res => {
        this.countries = res.data.data
      })
    },
    getFee() {
      this.wallet.fee = null
      ApiService.get('walletFee', {
        params: {
          amount: this.wallet.amount,
          payMethod: this.payMethodMapping[this.payType]
        }
      }).then(res => {
        this.wallet.fee = res.data.data
        this.converter()
      })
    }
  },
  ionViewWillEnter() {
    this.loadProfile();
    this.loadCountries();
    this.getFee()
    Stripe.isApplePayAvailable().then(() => {
      this.canPay = true
    }).catch(() => {
      this.canPay = false
    });
    Stripe.isGooglePayAvailable().then(() => {
      this.canGooglePay = true
    }).catch(() => {
      this.canGooglePay = false
    });
    if(!this.wallet.currencyId) {
      this.wallet.currencyId = 3
    }
  },
  async mounted() {
    if(this.$route.query.error) {
      await this.alertError(this.$route.query.error)
    }
    const device = await Device.getInfo();
    this.showApplePay = device.platform == 'ios';
    if(this.showApplePay) {
      this.payType = WalletService.get().methodId ? 'card' : 'apple'
    }
    this.showGooglePay = device.platform === 'android';
    if(this.showGooglePay) {
      this.payType = WalletService.get().methodId ? 'card' : 'google'
    }
  },
  computed: {
    isAnother(): boolean {
      if(!this.wallet || !this.profile) {
        return false;
      }
      return this.wallet.email !== this.profile.email
    }
  },
  watch: {
    // Для методов оплаты без выбора валюты сбрасываем конвертацию
    payType(val) {
      if(val && ['paypal', 'paygent', 'wallet'].indexOf(val) > -1) {
        this.wallet.currencyId = 3
      } else {
        this.converter()
      }
    }
  }
})
