<template>
  <ion-page>
    <toolbar :title="$tr(trCard, 'page')" :backbutton="true" />
    <ion-content fullscreen>
      <div class="ion-padding" v-if="user && email">
        <h1 class="title">{{ $td('Кошелёк пополнен успешно') }}</h1>
        <template v-if="lang === RU_LANG">
          <template v-if="isAnother">
            <p class="result lead">В Summus Кошелёк получателя <b>{{ contactName }}</b> зачислена сумма ¥{{ amount }}.</p>
            <p>На электронный адрес получателя <b>{{ email }}</b> отправлено уведомление о&nbsp;пополнении кошелька.</p>
          </template>
          <template v-else>
            <p class="result lead">В ваш Summus Кошелёк зачислена сумма ¥{{ amount }}.</p>
          </template>
          <p>На ваш электронный адрес <b>{{ user.email }}</b> отправлено письмо с подтверждением зачисления.</p>
          <p class="err">Если в&nbsp;течение пяти минут вы не&nbsp;нашли письма, проверьте папку <b>«спам»</b>.</p>
          <p>При обнаружении наших писем в&nbsp;папке спама, пожалуйста, отметьте их как <b>«не&nbsp;спам»</b> или добавьте наш электронный адрес в&nbsp;свою адресную книгу.</p>
          <hr>
          <p><a href="/wallet">Вернуться в&nbsp;кошелёк</a></p>
        </template>
        <!-- //-> TODO: придумать как это сделать в шаблонах-->
        <template v-else>
          <template v-if="isAnother">
            <p class="result lead">An amount of {{ amount }} has been added to <b>{{ contactName }}'s</b> Summus Wallet.</p>
            <p>A wallet top-up notification has been sent to the recipient’s email address <b>{{ email }}</b>.</p>
          </template>
          <template v-else>
            <p class="result lead">An amount of {{ amount }} has been added to your Summus Wallet.</p>
          </template>

          <p>&nbsp;</p>
          <p>A wallet top-up confirmation has been sent to your email address <b>{{ email }}</b>.</p>
          <p>&nbsp;</p>

          <p class="err">If you don't see the email within five minutes, check your <b>Spam</b> of <b>Junk</b> folders.</p>
          <p>If you ever find our messages in your spam or junk folder, make sure to mark them as <b>not spam</b> or add our email to your contacts.</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>

          <p><a href="/wallet">Back to wallet</a></p>
        </template>
      </div>
      <div class="spin" v-else>
        <ion-spinner name="circles" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonPage, IonContent, IonSpinner} from "@ionic/vue";
import {trCard} from "@/i18n/messages";
import Toolbar from "@/components/Toolbar";
import {ProfileService} from "@/services/profile.service";
import ApiService from "@/services/api.service";
import {LanguageService, RU_LANG} from "@/services/language.service";
export default {
  name: "WalletSuccess",
  components: { IonPage, IonContent, Toolbar, IonSpinner},
  setup() {
    return { trCard, RU_LANG }
  },
  data() {
    return {
      email: null,
      amount: null,
      contactName: null,
      user: null,
      isAnother: false,
      lang: LanguageService.getLang()
    }
  },
  mounted() {
    ProfileService.get().then(res => {
      this.user = res.data.data
      ApiService.get('/walletReplenishment', {
        params: {
          transactionId: this.$route.params.id
        }
      }).then(res => {
        const response = res.data.data
        this.email = response.isForAnotherUser ? response.recipientEmail : response.userEmail
        this.amount = response.amount.amount
        this.contactName = response.recipientName
        this.isAnother = response.isForAnotherUser
      })
    })
  }
}
</script>

<style scoped>

</style>