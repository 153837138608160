<template>
  <ion-page>
    <toolbar :title="$tr(trCard, 'orderTitle')" :backbutton="true" />
    <ion-content fullscreen>
      <ion-card>
        <ion-card-header>
          <ion-card-title color="shiawasedo" class="card-title">
            {{$tr(trCard, 'orderTitle')}}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content class="card-order-success">
          <h2>{{$tr(trCard, 'orderSuccess')}}</h2>
          <h2>{{$tr(trCard, 'orderNumber').replace('%orderNumber%', orderNumber)}}</h2>
          <span class="card-order-success-text" v-html="$tr(trCard, 'orderText')"></span>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>

import {IonCard, IonPage, IonContent, IonCardTitle, IonCardContent, IonCardHeader } from "@ionic/vue";
import {trCard} from "@/i18n/messages";
import Toolbar from "@/components/Toolbar";
export default {
  name: "OrderSuccess",
  components: {
    Toolbar,
    IonCard, IonPage, IonContent, IonCardTitle, IonCardContent, IonCardHeader
  },
  data() {
    return {
      orderNumber: this.$route.params.id
    }
  },
  setup() {
    return { trCard }
  }
}
</script>

<style scoped>

</style>