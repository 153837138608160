<template>
  <ion-page>
    <toolbar :title="'Summus ' + $tr(trCartForm, 'wallet')" :backbutton="true"/>
    <ion-content fullscreen>
      <wallet />
    </ion-content>
  </ion-page>
</template>

<script>
import Toolbar from "@/components/Toolbar";
import {trCartForm} from "@/i18n/messages";
import {
  IonContent, IonPage
} from "@ionic/vue";
import Wallet from "@/components/Wallet";
export default {
  name: "WalletPage",
  components: {
    Wallet,
    Toolbar,
    IonContent,
    IonPage
  },
  setup: () => ({trCartForm})
}
</script>

<style lang="scss" scoped>
</style>