import Order from "@/models/Order";
import Currency from "@/models/Currency";

export default class Wallet extends Order {
    email  = null
    fullName = null
    amount = 0
    fee = new Currency()
    isNoSignature = false
    message = null
    signature = null
}
